<template>
  <n-drawer
    :show="active"
    :default-height="180"
    resizable
    closable
    placement="bottom"
    :show-mask="false"
    :block-scroll="false"
  >
    <n-drawer-content style="padding: 0">
      <n-space class="header" justify="space-between">
        <div>
          Stereometry&nbsp;&nbsp;
          <n-button @click="download()">Download</n-button>
        </div>
        <n-button
          type="error"
          ghost
          @click="store.dispatch('stereometry/removeAllThumbnails')"
        >
          Clear
        </n-button>
      </n-space>
      <n-space class="content" justify="start">
        <div v-for="item in items" :key="item" class="thumbnail">
          <n-tooltip trigger="hover">
            <template #trigger>
              <img :src="item" @click="removeItem(item)" />
            </template>
            Click to remove
          </n-tooltip>
        </div>
      </n-space>
    </n-drawer-content>
  </n-drawer>
</template>

<script setup>
import { ref, watch, computed } from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';
import {
  NDrawer,
  NButton,
  NDrawerContent,
  NImage,
  NSpace,
  NTooltip,
} from 'naive-ui';
const store = useStore();
const items = computed(() => store.getters['stereometry/thumbnails']);

const active = ref(items.value.length > 0);

watch(
  items,
  (newItems, oldItems) => {
    if (newItems.length > 0) {
      active.value = true;
    } else {
      active.value = false;
    }
  },
  { deep: true }
);

const loading = ref(false);

function removeItem(item) {
  store.dispatch('stereometry/removeThumbnail', item);
}

async function download() {
  for (let i = 0; i < items.value.length; i++) {
    await downloadFile(items.value[i]);
  }
  store.dispatch('stereometry/removeAllThumbnails');
  active.value = false;
}

async function downloadFile(thumbnail) {
  loading.value = true;
  const fileUrl = thumbnail;
  await axios({
    method: 'get',
    url: fileUrl,
    responseType: 'arraybuffer',
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      const fileName = fileUrl.split('/').pop();
      link.setAttribute('download', fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
      loading.value = false;
    })
    .catch(() => {
      loading.value = false;
      console.log('error occured');
    });
}
</script>

<style lang="scss" scoped>
.thumbnail {
  display: inline-block;
  cursor: pointer;
  img {
    width: 80px;
    padding: 2px;
  }
}

.header {
  padding: 20px;
  border-bottom: 1px solid #ccc;
}

.content {
  padding: 10px;
}
</style>
