<template>
  <div id="loading">
    <div class="v-spinner" v-show="loading">
      <div
        v-for="index in 10"
        :key="index"
        v-bind:style="[spinnerStyle]"
        class="v-scale"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Loading",

  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: "rgb(187, 255, 187)",
    },
    height: {
      type: String,
      default: "20px",
    },
    width: {
      type: String,
      default: "2px",
    },
    margin: {
      type: String,
      default: "2px",
    },
    radius: {
      type: String,
      default: "2px",
    },
  },
  setup(props) {
    return {
      spinnerStyle: {
        backgroundColor: props.color,
        height: props.height,
        width: props.width,
        margin: props.margin,
        borderRadius: props.radius,
        display: "inline-block",
        animationFillMode: "both",
      },
    };
  },
});
</script>

<style lang="scss" scoped>
@import "../style/_variables.scss";
@import "../style/_mixins.scss";

#loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.v-spinner {
  text-align: center;
}

.v-scale {
  @include keyframes(scaleStretchDelay) {
    0% {
      animation-name: inherit;
      animation-duration: inherit;
      animation-iteration-count: inherit;
      animation-direction: inherit;
    }
    50% {
      transform: scaleY(0.4);
      transform: scaleY(0.4);
    }
    100% {
      transform: scaleY(1);
      transform: scaleY(1);
    }
  }
  @include animate(
    scaleStretchDelay,
    1s,
    cubic-bezier(0.1, 2, 0.7, 2),
    infinite
  );
  @include delay(animation, 100, 0.005s);
}
</style>
