<template>
  <Loading v-if="isLoading" />
  <div v-if="audio" :class="{ loading: isLoading }" id="peaks-container">
    <div id="zoom" ref="zoomviewContainerRef"></div>
    <div id="overview" ref="overviewContainerRef"></div>
    <audio v-on:ended="onEnded()" ref="audioRef"></audio>
  </div>
</template>

<script setup>
import { computed, ref, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { Duration } from 'luxon';
import Peaks from '@/3rd_party/peaks.es.js';

import Loading from '@/views/Loading.vue';
import { options as peaksOptions } from '@/components/peaks-options';

const debug = process.env.NODE_ENV !== 'production';

const props = defineProps({
  audio: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits([
  'onSegmentEnter',
  'onSegmentExit',
  'onSegmentDragend',
]);

const store = useStore();

const SPECTROGRAM_WIDTH = 4999;
const SPECTROGRAM_HEIGHT = 513;

// refs
const loading = ref(false);
const zoomviewContainerRef = ref(null);
const overviewContainerRef = ref(null);
const audioRef = ref(null);

const OFFSET = 10 * 60; // 10 minutes offset for axis label

let peaks = null;

// computed
const annotations = computed(() => store.getters['annotation/annotations']);
const isPlaying = computed(() => store.getters['player/isPlaying']);
const zoomLevel = computed(() => store.getters['player/zoomLevel']);
const doSeek = computed(() => store.getters['player/doSeek']);
const editorUuid = computed(() => {
  const editor = store.getters['annotation/editor'];
  if (editor === null) {
    return;
  } else {
    return editor.uuid;
  }
});
const isLoading = computed(() => {
  return loading.value === true;
});

// watchers
watch(isPlaying, (playing, old) => {
  if (playing === true) {
    peaks.player.play();
  } else {
    peaks.player.pause();
  }
});

watch(doSeek, (seek, old) => {
  if (seek !== null) {
    loading.value = true;
    peaks.player.seek(seek);
    loading.value = false;
    store.commit('player/doSeek', null);
  }
});

watch(zoomLevel, (level, old) => {
  const zoomView = peaks.views.getView('spectrogramZoomview');
  zoomView.setZoom({ seconds: level });
});

watch(
  editorUuid,
  (uuid, old) => {
    if (debug) {
      console.log('editor changed to:', uuid);
    }
    if (peaks === null) {
      return;
    }

    // re-initialize segments to set 'editable' segment
    initializeSegments();

    // hack to add new segment based on uuid
    if (uuid === 'NEW_ITEM') {
      peaks.segments.add({
        startTime: store.getters['player/offsetSeconds'],
        endTime: store.getters['player/offsetSeconds'] + 1,
        editable: true,
      });
    }
  },
  { deep: true }
);

// functions
function onEnded() {
  store.dispatch('player/pause');
}

function onSetSource(error) {
  if (error) {
    console.warn('Failed to set source for Peaks instance: ' + error.message);
    return;
  } else {
    if (debug) {
      console.log('Successfully set source.');
    }
  }

  // set zoom here (bug)
  const zoomView = peaks.views.getView('spectrogramZoomview');
  const level = store.getters['player/zoomLevel'];
  zoomView.setZoom({ seconds: level });

  // initialize  segments
  initializeSegments();

  // set offset
  const offset = store.getters['player/offsetSeconds'];
  if (offset !== 0) {
    if (debug) {
      console.log(`seek to ${offset}`);
    }
    peaks.player.seek(offset);
  }
  // start play?
  isPlaying.value ? peaks.player.play() : peaks.player.pause();

  // set loading to false
  loading.value = false;
}

function setPeaksData() {
  if (peaks === null) {
    console.warn('cannot update peaks, instance is null.');
    return;
  }
  loading.value = true;
  // set source
  const options = {
    mediaUrl: props.audio.file,
    dataUri: {
      json: props.audio.peaks,
    },
    spectrogramData: {
      image: props.audio.default_spectrogram,
      crop: {
        x: 58,
        y: 30,
        width: SPECTROGRAM_WIDTH,
        height: SPECTROGRAM_HEIGHT,
      },
    },
  };
  peaks.setSource(options, onSetSource);
}

function initializeSegments() {
  peaks.segments.removeAll();
  // add segments
  annotations.value.forEach((annotation) => {
    peaks.segments.add({
      startTime: annotation.start,
      endTime: annotation.end,
      editable: annotation.uuid === editorUuid.value,
      uuid: annotation.uuid,
    });
  });
}

onMounted(() => {
  let options = peaksOptions;
  // assign template refs to peaks.js containers / media elment
  options.spectrogramZoomview.container = zoomviewContainerRef.value;
  options.spectrogramOverview.container = overviewContainerRef.value;
  options.mediaElement = audioRef.value;
  // dataUri is required to render a waveform on init
  options.dataUri = {
    json: props.audio.peaks,
  };
  options.formatAxisTime = (time) => {
    return Duration.fromObject({ seconds: time + OFFSET }).toFormat('mm:ss');
  };

  options.spectrogramData = {
    image: props.audio.default_spectrogram,
    crop: {
      x: 58,
      y: 30,
      width: SPECTROGRAM_WIDTH,
      height: SPECTROGRAM_HEIGHT,
    },
  };

  Peaks.init(options, (error, instance) => {
    if (error) {
      if (debug) {
        console.warn('Failed to initialize Peaks instance: ' + error.message);
      }
    } else {
      if (debug) {
        console.log('Initialized Peaks instance: ', instance);
      }
      peaks = instance;

      peaks.on('player.timeupdate', (time) => {
        store.commit('player/setOffsetSeconds', time);
      });

      peaks.on('segments.enter', (ev) => {
        store.commit('annotation/addSegment', ev.segment);
      });
      peaks.on('segments.exit', (ev) => {
        store.commit('annotation/removeSegment', ev.segment);
      });

      peaks.on('segments.dragend', (ev) => {
        store.commit('annotation/onSegmentDragEnd', ev);
      });

      // set data
      setPeaksData();

      // this.$refs.zoomviewContainerRef.addEventListener(
      //   "wheel",
      //   this.onzoomviewContainerRefWheel
      // );

      // hack set amplitude scale to overcome low audio volume in files
      // const zoomView = this.peaks.views.getView("zoomview");
      // zoomView.setAmplitudeScale(3.0);

      // const overView = this.peaks.views.getView("overview");
      // console.log(overView);
      // console.log(overView._options.mediaUrl);
    }
  });
});
</script>
<style lang="scss" scoped>
#wrapper {
  position: relative;
}
.loading {
  filter: blur(5px);
}
#zoom {
  height: 400px;
  margin-bottom: 20px;
}
#overview {
  height: 50px;
}
</style>
