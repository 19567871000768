import { colors } from '@/style/colors.js';

let options = {

    spectrogramZoomview: {
      // spectrogramOpacity: 0.5,
      waveformColor: 'rgba(0, 0, 0, 0)',
      playedWaveformColor: 'rgba(0, 0, 0, 0)',
    },

    spectrogramOverview: {
      waveformColor: 'rgba(0, 0, 0, 0)',
      playedWaveformColor: 'rgba(0, 0, 0, 0)',
    },

    segmentOptions: {
      overlay: true,
      overlayColor: 'rgba(0,0,0,0)',
      overlayOpacity: 0.2,
      overlayBorderColor: '#ffffff',
      overlayBorderWidth: 8,
      overlayCornerRadius: 0,
      // Segment overlay offset from the top and bottom of the waveform view, in pixels
      overlayOffset: 0,
    },

    //
    // Zoomable waveform view options
    //

    zoomview: {
      // Color for the zoomable waveform
      // You can also use a 2 stop gradient here. See setWaveformColor()
    //   waveformColor: 'rgba(0, 225, 255, 1)',

      // Color for the played region of the zoomable waveform
      // You can also use a 2 stop gradient here. See setWaveformColor()
    //   playedWaveformColor: 'rgba(255, 0, 255, 1)',

      // Color of the playhead
    //   playheadColor: 'rgb(187, 255, 187)',

      // Color of the playhead text
    //   playheadTextColor: '#aaa',

      // Show current time next to the playhead
    //   showPlayheadTime: false,

      // Precision of time label of playhead and point/segment markers
    //   timeLabelPrecision: 2,

      // Color of the axis gridlines
    //   axisGridlineColor: '#ccc',

      // Color of the axis labels
    //   axisLabelColor: '#aaa',

      // Returns a string for the axis label timestamps
    //   formatAxisTime: function,

      // Show or hide the axis label timestamps
    //   showAxisTimeLabels: true,

      // Font family for axis labels, playhead, and point and segment markers
    //   fontFamily: 'sans-serif',

      // Font size for axis labels, playhead, and point and segment markers
    //   fontSize: 11,

      // Font style for axis labels, playhead, and point and segment markers
      // (either 'normal', 'bold', or 'italic')
    //   fontStyle: 'normal',

      // Mouse-wheel mode: either 'none' or 'scroll'
    //   wheelMode: 'none'
    },

    //
    // Overview waveform options
    //

    overview: {

      // Color for the overview waveform
      // You can also use a 2 stop gradient here. See setWaveformColor()
    //   waveformColor: 'rgba(255,255,255,0.4)',

      // Color for the played region of the overview waveform
      // You can also use a 2 stop gradient here. See setWaveformColor()
    //   playedWaveformColor: 'rgba(0, 225, 128, 1)',

      // Color for the overview waveform rectangle
      // that shows what the zoomable view shows
    //   highlightColor: 'grey',

      // The default number of pixels from the top and bottom of the canvas
      // that the overviewHighlight takes up
    //   highlightOffset: 11,

      // Color of the playhead
    //   playheadColor: 'rgb(187, 255, 187)',

      // Color of the playhead text
    //   playheadTextColor: '#aaa',

      // Returns a string for the playhead timestamp label
    //   formatPlayheadTime: function,

      // Show current time next to the play head
    //   showPlayheadTime: false,

      // Precision of time label of play head and point/segment markers
    //   timeLabelPrecision: 2,

      // Color of the axis gridlines
    //   axisGridlineColor: '#ccc',

      // Color of the axis labels
    //   axisLabelColor: '#aaa',

      // Returns a string for the axis label timestamps
    //   formatAxisTime: function,

      // Show or hide the axis label timestamps
    //   showAxisTimeLabels: true,

      // Font family for axis labels, playhead, and point and segment markers
    //   fontFamily: 'sans-serif',

      // Font size for axis labels, playhead, and point and segment markers
    //   fontSize: 11,

      // Font style for axis labels, playhead, and point and segment markers
      // (either 'normal', 'bold', or 'italic')
    //   fontStyle: 'normal',
    },

    // Array of zoom levels in samples per pixel (big >> small)
    zoomLevels: [512, 1024, 2048, 4096, 8192, 16384, 32768],

    // To avoid computation when changing zoom level, Peaks.js maintains a cache
    // of waveforms at different zoom levels. This is enabled by default, but
    // can be disabled by setting waveformCache to false
    waveformCache: true,

    //
    // Keyboard input options
    //

    // Bind keyboard controls
    keyboard: false,

    // Keyboard nudge increment in seconds (left arrow/right arrow)
    nudgeIncrement: 0.01,

    //
    // Default view options. Each of these can be set independently for each
    // waveform view, under the 'zoomview' and 'overview' options
    // (described above).
    //

    // Waveform color
    // You can also use a 2 stop gradient here. See setWaveformColor()
    waveformColor: colors.primaryColorDark,

    // Color for the played waveform region
    // You can also use a 2 stop gradient here. See setWaveformColor()
    // playedWaveformColor: 'rgba(255, 225, 255, 1)',

    // Color of the play head
    // playheadColor: 'rgba(0, 255, 0, 1)',
    playheadColor: colors.primaryColorDark,

    // Color of the play head text
    // playheadTextColor: '#aaa',

    // Color of the axis gridlines
    // axisGridlineColor: '#ccc',

    // Color of the axis labels
    // axisLabelColor: '#aaa',

    // Font family for axis labels, playhead, and point and segment markers
    // fontFamily: 'sans-serif',

    // Font size for axis labels, playhead, and point and segment markers
    // fontSize: 11,

    // Font style for axis labels, playhead, and point and segment markers
    // (either 'normal', 'bold', or 'italic')
    // fontStyle: 'normal',

    // Precision of time label of play head and point/segment markers
    // timeLabelPrecision: 2,

    // Show current time next to the play head (zoomview only)
    // showPlayheadTime: false,

    //
    // Point and segment options
    //

    // the color of a point marker
    // pointMarkerColor: '#FF0000',

    // Color for segment start marker handles
    // segmentStartMarkerColor: '#a0a0a0',

    // Color for segment end marker handles
    // segmentEndMarkerColor: '#a0a0a0',

    // Color for segments on the waveform
    // segmentColor: 'rgba(255, 161, 39, 1)',

    // Random color per segment (overrides segmentColor)
    // randomizeSegmentColor: true,

    // if true, emit cue events on the Peaks instance (see Cue Events)
    emitCueEvents: true,

    //
    // Customization options (see customizing.md)
    //

    // createSegmentMarker: null,
    // createSegmentLabel: null,
    // createPointMarker: null,
    // player: null,

    //
    // Point and segment initialization
    //

    //segments: [
      //{
        //startTime: 120,
        //endTime: 140,
      //},
      //{
        //startTime: 220,
        //endTime: 240,
      //}
    //],

    // points: [
    //   {
    //     time: 150,
    //     editable: true,
    //     color: "#00ff00",
    //     labelText: "A point"
    //   },
    //   {
    //     time: 160,
    //     editable: true,
    //     color: "#00ff00",
    //     labelText: "Another point"
    //   }
    // ],

    //
    // Debugging options
    //

    // Diagnostic or error information is written to this function.
    // The default is console.error
    // logger: console.error.bind(console)
  }


export { options }
