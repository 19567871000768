import Konva from "konva/lib/Core";
import { Line } from "konva/lib/shapes/Line";
import { Rect } from "konva/lib/shapes/Rect";
import { Text } from "konva/lib/shapes/Text";
import { Animation } from "konva/lib/Animation";
import "konva/lib/shapes/Image";
import WaveformData from "waveform-data";
var eventemitter3 = { exports: {} };
(function(module) {
  var has = Object.prototype.hasOwnProperty, prefix = "~";
  function Events() {
  }
  if (Object.create) {
    Events.prototype = /* @__PURE__ */ Object.create(null);
    if (!new Events().__proto__)
      prefix = false;
  }
  function EE(fn, context, once) {
    this.fn = fn;
    this.context = context;
    this.once = once || false;
  }
  function addListener(emitter, event, fn, context, once) {
    if (typeof fn !== "function") {
      throw new TypeError("The listener must be a function");
    }
    var listener = new EE(fn, context || emitter, once), evt = prefix ? prefix + event : event;
    if (!emitter._events[evt])
      emitter._events[evt] = listener, emitter._eventsCount++;
    else if (!emitter._events[evt].fn)
      emitter._events[evt].push(listener);
    else
      emitter._events[evt] = [emitter._events[evt], listener];
    return emitter;
  }
  function clearEvent(emitter, evt) {
    if (--emitter._eventsCount === 0)
      emitter._events = new Events();
    else
      delete emitter._events[evt];
  }
  function EventEmitter2() {
    this._events = new Events();
    this._eventsCount = 0;
  }
  EventEmitter2.prototype.eventNames = function eventNames2() {
    var names = [], events2, name;
    if (this._eventsCount === 0)
      return names;
    for (name in events2 = this._events) {
      if (has.call(events2, name))
        names.push(prefix ? name.slice(1) : name);
    }
    if (Object.getOwnPropertySymbols) {
      return names.concat(Object.getOwnPropertySymbols(events2));
    }
    return names;
  };
  EventEmitter2.prototype.listeners = function listeners(event) {
    var evt = prefix ? prefix + event : event, handlers = this._events[evt];
    if (!handlers)
      return [];
    if (handlers.fn)
      return [handlers.fn];
    for (var i = 0, l = handlers.length, ee = new Array(l); i < l; i++) {
      ee[i] = handlers[i].fn;
    }
    return ee;
  };
  EventEmitter2.prototype.listenerCount = function listenerCount(event) {
    var evt = prefix ? prefix + event : event, listeners = this._events[evt];
    if (!listeners)
      return 0;
    if (listeners.fn)
      return 1;
    return listeners.length;
  };
  EventEmitter2.prototype.emit = function emit(event, a1, a2, a3, a4, a5) {
    var evt = prefix ? prefix + event : event;
    if (!this._events[evt])
      return false;
    var listeners = this._events[evt], len = arguments.length, args, i;
    if (listeners.fn) {
      if (listeners.once)
        this.removeListener(event, listeners.fn, void 0, true);
      switch (len) {
        case 1:
          return listeners.fn.call(listeners.context), true;
        case 2:
          return listeners.fn.call(listeners.context, a1), true;
        case 3:
          return listeners.fn.call(listeners.context, a1, a2), true;
        case 4:
          return listeners.fn.call(listeners.context, a1, a2, a3), true;
        case 5:
          return listeners.fn.call(listeners.context, a1, a2, a3, a4), true;
        case 6:
          return listeners.fn.call(listeners.context, a1, a2, a3, a4, a5), true;
      }
      for (i = 1, args = new Array(len - 1); i < len; i++) {
        args[i - 1] = arguments[i];
      }
      listeners.fn.apply(listeners.context, args);
    } else {
      var length = listeners.length, j;
      for (i = 0; i < length; i++) {
        if (listeners[i].once)
          this.removeListener(event, listeners[i].fn, void 0, true);
        switch (len) {
          case 1:
            listeners[i].fn.call(listeners[i].context);
            break;
          case 2:
            listeners[i].fn.call(listeners[i].context, a1);
            break;
          case 3:
            listeners[i].fn.call(listeners[i].context, a1, a2);
            break;
          case 4:
            listeners[i].fn.call(listeners[i].context, a1, a2, a3);
            break;
          default:
            if (!args)
              for (j = 1, args = new Array(len - 1); j < len; j++) {
                args[j - 1] = arguments[j];
              }
            listeners[i].fn.apply(listeners[i].context, args);
        }
      }
    }
    return true;
  };
  EventEmitter2.prototype.on = function on(event, fn, context) {
    return addListener(this, event, fn, context, false);
  };
  EventEmitter2.prototype.once = function once(event, fn, context) {
    return addListener(this, event, fn, context, true);
  };
  EventEmitter2.prototype.removeListener = function removeListener(event, fn, context, once) {
    var evt = prefix ? prefix + event : event;
    if (!this._events[evt])
      return this;
    if (!fn) {
      clearEvent(this, evt);
      return this;
    }
    var listeners = this._events[evt];
    if (listeners.fn) {
      if (listeners.fn === fn && (!once || listeners.once) && (!context || listeners.context === context)) {
        clearEvent(this, evt);
      }
    } else {
      for (var i = 0, events2 = [], length = listeners.length; i < length; i++) {
        if (listeners[i].fn !== fn || once && !listeners[i].once || context && listeners[i].context !== context) {
          events2.push(listeners[i]);
        }
      }
      if (events2.length)
        this._events[evt] = events2.length === 1 ? events2[0] : events2;
      else
        clearEvent(this, evt);
    }
    return this;
  };
  EventEmitter2.prototype.removeAllListeners = function removeAllListeners(event) {
    var evt;
    if (event) {
      evt = prefix ? prefix + event : event;
      if (this._events[evt])
        clearEvent(this, evt);
    } else {
      this._events = new Events();
      this._eventsCount = 0;
    }
    return this;
  };
  EventEmitter2.prototype.off = EventEmitter2.prototype.removeListener;
  EventEmitter2.prototype.addListener = EventEmitter2.prototype.on;
  EventEmitter2.prefixed = prefix;
  EventEmitter2.EventEmitter = EventEmitter2;
  {
    module.exports = EventEmitter2;
  }
})(eventemitter3);
var EventEmitter = eventemitter3.exports;
function Cue(time, type, id) {
  this.time = time;
  this.type = type;
  this.id = id;
}
Cue.POINT = 0;
Cue.SEGMENT_START = 1;
Cue.SEGMENT_END = 2;
Cue.sorter = function(a, b) {
  return a.time - b.time;
};
function zeroPad(number, precision) {
  number = number.toString();
  while (number.length < precision) {
    number = "0" + number;
  }
  return number;
}
function formatTime(time, precision) {
  let result = [];
  const fractionSeconds = Math.floor(time % 1 * Math.pow(10, precision));
  const seconds = Math.floor(time);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  if (hours > 0) {
    result.push(hours);
  }
  result.push(minutes % 60);
  result.push(seconds % 60);
  for (let i = 0; i < result.length; i++) {
    result[i] = zeroPad(result[i], 2);
  }
  result = result.join(":");
  if (precision > 0) {
    result += "." + zeroPad(fractionSeconds, precision);
  }
  return result;
}
function roundUpToNearest(value, multiple) {
  if (multiple === 0) {
    return 0;
  }
  let multiplier = 1;
  if (value < 0) {
    multiplier = -1;
    value = -value;
  }
  const roundedUp = Math.ceil(value);
  return multiplier * ((roundedUp + multiple - 1) / multiple | 0) * multiple;
}
function clamp(value, min, max) {
  if (value < min) {
    return min;
  } else if (value > max) {
    return max;
  } else {
    return value;
  }
}
function objectHasProperty(object, field) {
  return Object.prototype.hasOwnProperty.call(object, field);
}
function extend(to, from) {
  for (const key in from) {
    if (objectHasProperty(from, key)) {
      to[key] = from[key];
    }
  }
  return to;
}
function isInAscendingOrder(array) {
  if (array.length === 0) {
    return true;
  }
  let value = array[0];
  for (let i = 1; i < array.length; i++) {
    if (value >= array[i]) {
      return false;
    }
    value = array[i];
  }
  return true;
}
function isNumber(value) {
  return typeof value === "number";
}
function isFinite(value) {
  if (typeof value !== "number") {
    return false;
  }
  if (value !== value || value === Infinity || value === -Infinity) {
    return false;
  }
  return true;
}
function isValidTime(value) {
  return typeof value === "number" && Number.isFinite(value);
}
function isObject(value) {
  return value !== null && typeof value === "object" && !Array.isArray(value);
}
function isString(value) {
  return typeof value === "string";
}
function isArrayBuffer(value) {
  return Object.prototype.toString.call(value).includes("ArrayBuffer");
}
function isNullOrUndefined(value) {
  return value === void 0 || value === null;
}
function isFunction(value) {
  return typeof value === "function";
}
function isBoolean(value) {
  return value === true || value === false;
}
function isHTMLElement(value) {
  return value instanceof HTMLElement;
}
function isArray(value) {
  return Array.isArray(value);
}
function isLinearGradientColor(value) {
  return isObject(value) && objectHasProperty(value, "linearGradientStart") && objectHasProperty(value, "linearGradientEnd") && objectHasProperty(value, "linearGradientColorStops") && isNumber(value.linearGradientStart) && isNumber(value.linearGradientEnd) && isArray(value.linearGradientColorStops) && value.linearGradientColorStops.length === 2;
}
function getMarkerObject(obj) {
  while (obj.parent !== null) {
    if (obj.parent instanceof Konva.Layer) {
      return obj;
    }
    obj = obj.parent;
  }
  return null;
}
const isHeadless = /HeadlessChrome/.test(navigator.userAgent);
function windowIsVisible() {
  if (isHeadless || navigator.webdriver) {
    return false;
  }
  return typeof document === "object" && "visibilityState" in document && document.visibilityState === "visible";
}
const requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;
const cancelAnimationFrame = window.cancelAnimationFrame || window.mozCancelAnimationFrame || window.webkitCancelAnimationFrame || window.msCancelAnimationFrame;
const eventTypes = {
  forward: {},
  reverse: {}
};
const EVENT_TYPE_POINT = 0;
const EVENT_TYPE_SEGMENT_ENTER = 1;
const EVENT_TYPE_SEGMENT_EXIT = 2;
eventTypes.forward[Cue.POINT] = EVENT_TYPE_POINT;
eventTypes.forward[Cue.SEGMENT_START] = EVENT_TYPE_SEGMENT_ENTER;
eventTypes.forward[Cue.SEGMENT_END] = EVENT_TYPE_SEGMENT_EXIT;
eventTypes.reverse[Cue.POINT] = EVENT_TYPE_POINT;
eventTypes.reverse[Cue.SEGMENT_START] = EVENT_TYPE_SEGMENT_EXIT;
eventTypes.reverse[Cue.SEGMENT_END] = EVENT_TYPE_SEGMENT_ENTER;
const eventNames = {};
eventNames[EVENT_TYPE_POINT] = "points.enter";
eventNames[EVENT_TYPE_SEGMENT_ENTER] = "segments.enter";
eventNames[EVENT_TYPE_SEGMENT_EXIT] = "segments.exit";
const eventAttributes = {};
eventAttributes[EVENT_TYPE_POINT] = "point";
eventAttributes[EVENT_TYPE_SEGMENT_ENTER] = "segment";
eventAttributes[EVENT_TYPE_SEGMENT_EXIT] = "segment";
function getPointOrSegment(peaks, cue) {
  switch (cue.type) {
    case Cue.POINT:
      return peaks.points.getPoint(cue.id);
    case Cue.SEGMENT_START:
    case Cue.SEGMENT_END:
      return peaks.segments.getSegment(cue.id);
    default:
      throw new Error("getPointOrSegment: id not found?");
  }
}
function CueEmitter(peaks) {
  this._cues = [];
  this._peaks = peaks;
  this._previousTime = -1;
  this._updateCues = this._updateCues.bind(this);
  this._onPlaying = this._onPlaying.bind(this);
  this._onSeeked = this._onSeeked.bind(this);
  this._onTimeUpdate = this._onTimeUpdate.bind(this);
  this._onAnimationFrame = this._onAnimationFrame.bind(this);
  this._rAFHandle = null;
  this._activeSegments = {};
  this._attachEventHandlers();
}
CueEmitter.prototype._updateCues = function() {
  const self = this;
  const points = self._peaks.points.getPoints();
  const segments = self._peaks.segments.getSegments();
  self._cues.length = 0;
  points.forEach(function(point) {
    self._cues.push(new Cue(point.time, Cue.POINT, point.id));
  });
  segments.forEach(function(segment) {
    self._cues.push(new Cue(segment.startTime, Cue.SEGMENT_START, segment.id));
    self._cues.push(new Cue(segment.endTime, Cue.SEGMENT_END, segment.id));
  });
  self._cues.sort(Cue.sorter);
  const time = self._peaks.player.getCurrentTime();
  self._updateActiveSegments(time);
};
CueEmitter.prototype._onUpdate = function(time, previousTime) {
  const isForward = time > previousTime;
  let start;
  let end;
  let step;
  if (isForward) {
    start = 0;
    end = this._cues.length;
    step = 1;
  } else {
    start = this._cues.length - 1;
    end = -1;
    step = -1;
  }
  for (let i = start; isForward ? i < end : i > end; i += step) {
    const cue = this._cues[i];
    if (isForward ? cue.time > previousTime : cue.time < previousTime) {
      if (isForward ? cue.time > time : cue.time < time) {
        break;
      }
      const marker = getPointOrSegment(this._peaks, cue);
      const eventType = isForward ? eventTypes.forward[cue.type] : eventTypes.reverse[cue.type];
      if (eventType === EVENT_TYPE_SEGMENT_ENTER) {
        this._activeSegments[marker.id] = marker;
      } else if (eventType === EVENT_TYPE_SEGMENT_EXIT) {
        delete this._activeSegments[marker.id];
      }
      const event = {
        time
      };
      event[eventAttributes[eventType]] = marker;
      this._peaks.emit(eventNames[eventType], event);
    }
  }
};
CueEmitter.prototype._onTimeUpdate = function(time) {
  if (windowIsVisible()) {
    return;
  }
  if (this._peaks.player.isPlaying() && !this._peaks.player.isSeeking()) {
    this._onUpdate(time, this._previousTime);
  }
  this._previousTime = time;
};
CueEmitter.prototype._onAnimationFrame = function() {
  const time = this._peaks.player.getCurrentTime();
  if (!this._peaks.player.isSeeking()) {
    this._onUpdate(time, this._previousTime);
  }
  this._previousTime = time;
  if (this._peaks.player.isPlaying()) {
    this._rAFHandle = requestAnimationFrame(this._onAnimationFrame);
  }
};
CueEmitter.prototype._onPlaying = function() {
  this._previousTime = this._peaks.player.getCurrentTime();
  this._rAFHandle = requestAnimationFrame(this._onAnimationFrame);
};
CueEmitter.prototype._onSeeked = function(time) {
  this._previousTime = time;
  this._updateActiveSegments(time);
};
function getSegmentIdComparator(id) {
  return function compareSegmentIds(segment) {
    return segment.id === id;
  };
}
CueEmitter.prototype._updateActiveSegments = function(time) {
  const self = this;
  const activeSegments = self._peaks.segments.getSegmentsAtTime(time);
  for (const id in self._activeSegments) {
    if (objectHasProperty(self._activeSegments, id)) {
      const segment = activeSegments.find(getSegmentIdComparator(id));
      if (!segment) {
        self._peaks.emit("segments.exit", {
          segment: self._activeSegments[id],
          time
        });
        delete self._activeSegments[id];
      }
    }
  }
  activeSegments.forEach(function(segment) {
    if (!(segment.id in self._activeSegments)) {
      self._activeSegments[segment.id] = segment;
      self._peaks.emit("segments.enter", {
        segment,
        time
      });
    }
  });
};
const events = [
  "points.update",
  "points.dragmove",
  "points.add",
  "points.remove",
  "points.remove_all",
  "segments.update",
  "segments.dragged",
  "segments.add",
  "segments.remove",
  "segments.remove_all"
];
CueEmitter.prototype._attachEventHandlers = function() {
  this._peaks.on("player.timeupdate", this._onTimeUpdate);
  this._peaks.on("player.playing", this._onPlaying);
  this._peaks.on("player.seeked", this._onSeeked);
  for (let i = 0; i < events.length; i++) {
    this._peaks.on(events[i], this._updateCues);
  }
  this._updateCues();
};
CueEmitter.prototype._detachEventHandlers = function() {
  this._peaks.off("player.timeupdate", this._onTimeUpdate);
  this._peaks.off("player.playing", this._onPlaying);
  this._peaks.off("player.seeked", this._onSeeked);
  for (let i = 0; i < events.length; i++) {
    this._peaks.off(events[i], this._updateCues);
  }
};
CueEmitter.prototype.destroy = function() {
  if (this._rAFHandle) {
    cancelAnimationFrame(this._rAFHandle);
    this._rAFHandle = null;
  }
  this._detachEventHandlers();
  this._previousTime = -1;
};
const pointOptions = [
  "id",
  "pid",
  "time",
  "labelText",
  "color",
  "editable"
];
const invalidOptions$1 = [
  "update",
  "isVisible",
  "peaks",
  "pid"
];
function setDefaultPointOptions(options, peaksOptions) {
  if (isNullOrUndefined(options.labelText)) {
    options.labelText = "";
  }
  if (isNullOrUndefined(options.editable)) {
    options.editable = false;
  }
  if (isNullOrUndefined(options.color)) {
    options.color = peaksOptions.pointMarkerColor;
  }
}
function validatePointOptions(options, updating) {
  const context = updating ? "update()" : "add()";
  if (!updating || updating && objectHasProperty(options, "time")) {
    if (!isValidTime(options.time)) {
      throw new TypeError("peaks.points." + context + ": time should be a numeric value");
    }
  }
  if (options.time < 0) {
    throw new RangeError("peaks.points." + context + ": time should not be negative");
  }
  if (objectHasProperty(options, "labelText") && !isString(options.labelText)) {
    throw new TypeError("peaks.points." + context + ": labelText must be a string");
  }
  if (objectHasProperty(options, "editable") && !isBoolean(options.editable)) {
    throw new TypeError("peaks.points." + context + ": editable must be true or false");
  }
  if (objectHasProperty(options, "color") && !isString(options.color) && !isLinearGradientColor(options.color)) {
    throw new TypeError("peaks.points." + context + ": color must be a string or a valid linear gradient object");
  }
  invalidOptions$1.forEach(function(name) {
    if (objectHasProperty(options, name)) {
      throw new Error("peaks.points." + context + ": invalid option name: " + name);
    }
  });
  pointOptions.forEach(function(name) {
    if (objectHasProperty(options, "_" + name)) {
      throw new Error("peaks.points." + context + ": invalid option name: _" + name);
    }
  });
}
function Point(peaks, pid, options) {
  this._peaks = peaks;
  this._pid = pid;
  this._setUserData(options);
}
Point.prototype._setUserData = function(options) {
  for (const key in options) {
    if (objectHasProperty(options, key)) {
      if (pointOptions.indexOf(key) === -1) {
        this[key] = options[key];
      } else {
        this["_" + key] = options[key];
      }
    }
  }
};
Object.defineProperties(Point.prototype, {
  id: {
    enumerable: true,
    get: function() {
      return this._id;
    }
  },
  pid: {
    enumerable: true,
    get: function() {
      return this._pid;
    }
  },
  time: {
    enumerable: true,
    get: function() {
      return this._time;
    }
  },
  labelText: {
    get: function() {
      return this._labelText;
    }
  },
  color: {
    enumerable: true,
    get: function() {
      return this._color;
    }
  },
  editable: {
    enumerable: true,
    get: function() {
      return this._editable;
    }
  }
});
Point.prototype.update = function(options) {
  validatePointOptions(options, true);
  if (objectHasProperty(options, "id")) {
    if (isNullOrUndefined(options.id)) {
      throw new TypeError("point.update(): invalid id");
    }
    this._peaks.points.updatePointId(this, options.id);
  }
  this._setUserData(options);
  this._peaks.emit("points.update", this, options);
};
Point.prototype.isVisible = function(startTime, endTime) {
  return this.time >= startTime && this.time < endTime;
};
Point.prototype._setTime = function(time) {
  this._time = time;
};
function WaveformPoints(peaks) {
  this._peaks = peaks;
  this._points = [];
  this._pointsById = {};
  this._pointsByPid = {};
  this._pointIdCounter = 0;
  this._pointPid = 0;
}
WaveformPoints.prototype._getNextPointId = function() {
  return "peaks.point." + this._pointIdCounter++;
};
WaveformPoints.prototype._getNextPid = function() {
  return this._pointPid++;
};
WaveformPoints.prototype._addPoint = function(point) {
  this._points.push(point);
  this._pointsById[point.id] = point;
  this._pointsByPid[point.pid] = point;
};
WaveformPoints.prototype._createPoint = function(options) {
  const pointOptions2 = {};
  extend(pointOptions2, options);
  if (isNullOrUndefined(pointOptions2.id)) {
    pointOptions2.id = this._getNextPointId();
  }
  const pid = this._getNextPid();
  setDefaultPointOptions(pointOptions2, this._peaks.options);
  validatePointOptions(pointOptions2, false);
  return new Point(this._peaks, pid, pointOptions2);
};
WaveformPoints.prototype.getPoints = function() {
  return this._points;
};
WaveformPoints.prototype.getPoint = function(id) {
  return this._pointsById[id];
};
WaveformPoints.prototype.find = function(startTime, endTime) {
  return this._points.filter(function(point) {
    return point.isVisible(startTime, endTime);
  });
};
WaveformPoints.prototype.add = function() {
  const self = this;
  const arrayArgs = Array.isArray(arguments[0]);
  let points = arrayArgs ? arguments[0] : Array.prototype.slice.call(arguments);
  points = points.map(function(pointOptions2) {
    const point = self._createPoint(pointOptions2);
    if (objectHasProperty(self._pointsById, point.id)) {
      throw new Error("peaks.points.add(): duplicate id");
    }
    return point;
  });
  points.forEach(function(point) {
    self._addPoint(point);
  });
  this._peaks.emit("points.add", {
    points
  });
  return arrayArgs ? points : points[0];
};
WaveformPoints.prototype.updatePointId = function(point, newPointId) {
  if (this._pointsById[point.id]) {
    if (this._pointsById[newPointId]) {
      throw new Error("point.update(): duplicate id");
    } else {
      delete this._pointsById[point.id];
      this._pointsById[newPointId] = point;
    }
  }
};
WaveformPoints.prototype._findPoint = function(predicate) {
  const indexes = [];
  for (let i = 0, length = this._points.length; i < length; i++) {
    if (predicate(this._points[i])) {
      indexes.push(i);
    }
  }
  return indexes;
};
WaveformPoints.prototype._removeIndexes = function(indexes) {
  const removed = [];
  for (let i = 0; i < indexes.length; i++) {
    const index = indexes[i] - removed.length;
    const itemRemoved = this._points.splice(index, 1)[0];
    delete this._pointsById[itemRemoved.id];
    delete this._pointsByPid[itemRemoved.pid];
    removed.push(itemRemoved);
  }
  return removed;
};
WaveformPoints.prototype._removePoints = function(predicate) {
  const indexes = this._findPoint(predicate);
  const removed = this._removeIndexes(indexes);
  this._peaks.emit("points.remove", {
    points: removed
  });
  return removed;
};
WaveformPoints.prototype.remove = function(point) {
  return this._removePoints(function(p) {
    return p === point;
  });
};
WaveformPoints.prototype.removeById = function(pointId) {
  return this._removePoints(function(point) {
    return point.id === pointId;
  });
};
WaveformPoints.prototype.removeByTime = function(time) {
  return this._removePoints(function(point) {
    return point.time === time;
  });
};
WaveformPoints.prototype.removeAll = function() {
  this._points = [];
  this._pointsById = {};
  this._pointsByPid = {};
  this._peaks.emit("points.remove_all");
};
const segmentOptions = [
  "id",
  "pid",
  "startTime",
  "endTime",
  "labelText",
  "color",
  "borderColor",
  "editable"
];
const invalidOptions = [
  "update",
  "isVisible",
  "peaks",
  "pid"
];
function setDefaultSegmentOptions(options, globalSegmentOptions) {
  if (isNullOrUndefined(options.color)) {
    if (globalSegmentOptions.overlay) {
      options.color = globalSegmentOptions.overlayColor;
    } else {
      options.color = globalSegmentOptions.waveformColor;
    }
  }
  if (isNullOrUndefined(options.borderColor)) {
    options.borderColor = globalSegmentOptions.overlayBorderColor;
  }
  if (isNullOrUndefined(options.labelText)) {
    options.labelText = "";
  }
  if (isNullOrUndefined(options.editable)) {
    options.editable = false;
  }
}
function validateSegmentOptions(options, updating) {
  const context = updating ? "update()" : "add()";
  if (objectHasProperty(options, "startTime") && !isValidTime(options.startTime)) {
    throw new TypeError("peaks.segments." + context + ": startTime should be a valid number");
  }
  if (objectHasProperty(options, "endTime") && !isValidTime(options.endTime)) {
    throw new TypeError("peaks.segments." + context + ": endTime should be a valid number");
  }
  if (!updating) {
    if (!objectHasProperty(options, "startTime") || !objectHasProperty(options, "endTime")) {
      throw new TypeError("peaks.segments." + context + ": missing startTime or endTime");
    }
  }
  if (options.startTime < 0) {
    throw new RangeError("peaks.segments." + context + ": startTime should not be negative");
  }
  if (options.endTime < 0) {
    throw new RangeError("peaks.segments." + context + ": endTime should not be negative");
  }
  if (options.endTime < options.startTime) {
    throw new RangeError("peaks.segments." + context + ": endTime should not be less than startTime");
  }
  if (objectHasProperty(options, "labelText") && !isString(options.labelText)) {
    throw new TypeError("peaks.segments." + context + ": labelText must be a string");
  }
  if (objectHasProperty(options, "editable") && !isBoolean(options.editable)) {
    throw new TypeError("peaks.segments." + context + ": editable must be true or false");
  }
  if (objectHasProperty(options, "color") && !isString(options.color) && !isLinearGradientColor(options.color)) {
    throw new TypeError("peaks.segments." + context + ": color must be a string or a valid linear gradient object");
  }
  if (objectHasProperty(options, "borderColor") && !isString(options.borderColor)) {
    throw new TypeError("peaks.segments." + context + ": borderColor must be a string");
  }
  invalidOptions.forEach(function(name) {
    if (objectHasProperty(options, name)) {
      throw new Error("peaks.segments." + context + ": invalid option name: " + name);
    }
  });
  segmentOptions.forEach(function(name) {
    if (objectHasProperty(options, "_" + name)) {
      throw new Error("peaks.segments." + context + ": invalid option name: _" + name);
    }
  });
}
function Segment(peaks, pid, options) {
  this._peaks = peaks;
  this._pid = pid;
  this._id = options.id;
  this._startTime = options.startTime;
  this._endTime = options.endTime;
  this._labelText = options.labelText;
  this._color = options.color;
  this._borderColor = options.borderColor;
  this._editable = options.editable;
  this._setUserData(options);
}
Segment.prototype._setUserData = function(options) {
  for (const key in options) {
    if (objectHasProperty(options, key)) {
      if (segmentOptions.indexOf(key) === -1) {
        this[key] = options[key];
      } else {
        this["_" + key] = options[key];
      }
    }
  }
};
Object.defineProperties(Segment.prototype, {
  id: {
    enumerable: true,
    get: function() {
      return this._id;
    }
  },
  pid: {
    enumerable: true,
    get: function() {
      return this._pid;
    }
  },
  startTime: {
    enumerable: true,
    get: function() {
      return this._startTime;
    }
  },
  endTime: {
    enumerable: true,
    get: function() {
      return this._endTime;
    }
  },
  labelText: {
    enumerable: true,
    get: function() {
      return this._labelText;
    }
  },
  color: {
    enumerable: true,
    get: function() {
      return this._color;
    }
  },
  borderColor: {
    enumerable: true,
    get: function() {
      return this._borderColor;
    }
  },
  editable: {
    enumerable: true,
    get: function() {
      return this._editable;
    }
  }
});
Segment.prototype.update = function(options) {
  validateSegmentOptions(options, true);
  if (objectHasProperty(options, "id")) {
    if (isNullOrUndefined(options.id)) {
      throw new TypeError("segment.update(): invalid id");
    }
    this._peaks.segments.updateSegmentId(this, options.id);
  }
  this._setUserData(options);
  this._peaks.emit("segments.update", this, options);
};
Segment.prototype.isVisible = function(startTime, endTime) {
  return this.startTime < endTime && startTime < this.endTime;
};
Segment.prototype._setStartTime = function(time) {
  this._startTime = time;
};
Segment.prototype._setEndTime = function(time) {
  this._endTime = time;
};
function WaveformSegments(peaks) {
  this._peaks = peaks;
  this._segments = [];
  this._segmentsById = {};
  this._segmentsByPid = {};
  this._segmentIdCounter = 0;
  this._segmentPid = 0;
  this._isInserting = false;
}
WaveformSegments.prototype._getNextSegmentId = function() {
  return "peaks.segment." + this._segmentIdCounter++;
};
WaveformSegments.prototype._getNextPid = function() {
  return this._segmentPid++;
};
WaveformSegments.prototype._addSegment = function(segment) {
  this._segments.push(segment);
  this._segmentsById[segment.id] = segment;
  this._segmentsByPid[segment.pid] = segment;
};
WaveformSegments.prototype._createSegment = function(options) {
  const segmentOptions2 = {};
  extend(segmentOptions2, options);
  if (isNullOrUndefined(segmentOptions2.id)) {
    segmentOptions2.id = this._getNextSegmentId();
  }
  const pid = this._getNextPid();
  setDefaultSegmentOptions(segmentOptions2, this._peaks.options.segmentOptions);
  validateSegmentOptions(segmentOptions2, false);
  return new Segment(this._peaks, pid, segmentOptions2);
};
WaveformSegments.prototype.getSegments = function() {
  return this._segments;
};
WaveformSegments.prototype.getSegment = function(id) {
  return this._segmentsById[id];
};
WaveformSegments.prototype.getSegmentsAtTime = function(time) {
  return this._segments.filter(function(segment) {
    return time >= segment.startTime && time < segment.endTime;
  });
};
WaveformSegments.prototype.find = function(startTime, endTime) {
  return this._segments.filter(function(segment) {
    return segment.isVisible(startTime, endTime);
  });
};
WaveformSegments.prototype._getSortedSegments = function() {
  return this._segments.slice().sort(function(a, b) {
    return a.startTime - b.startTime;
  });
};
WaveformSegments.prototype.findPreviousSegment = function(segment) {
  const sortedSegments = this._getSortedSegments();
  const index = sortedSegments.findIndex(function(s) {
    return s.id === segment.id;
  });
  if (index !== -1) {
    return sortedSegments[index - 1];
  }
  return void 0;
};
WaveformSegments.prototype.findNextSegment = function(segment) {
  const sortedSegments = this._getSortedSegments();
  const index = sortedSegments.findIndex(function(s) {
    return s.id === segment.id;
  });
  if (index !== -1) {
    return sortedSegments[index + 1];
  }
  return void 0;
};
WaveformSegments.prototype.add = function() {
  const self = this;
  const arrayArgs = Array.isArray(arguments[0]);
  let segments = arrayArgs ? arguments[0] : Array.prototype.slice.call(arguments);
  segments = segments.map(function(segmentOptions2) {
    const segment = self._createSegment(segmentOptions2);
    if (objectHasProperty(self._segmentsById, segment.id)) {
      throw new Error("peaks.segments.add(): duplicate id");
    }
    return segment;
  });
  segments.forEach(function(segment) {
    self._addSegment(segment);
  });
  this._peaks.emit("segments.add", {
    segments,
    insert: this._isInserting
  });
  return arrayArgs ? segments : segments[0];
};
WaveformSegments.prototype.updateSegmentId = function(segment, newSegmentId) {
  if (this._segmentsById[segment.id]) {
    if (this._segmentsById[newSegmentId]) {
      throw new Error("segment.update(): duplicate id");
    } else {
      delete this._segmentsById[segment.id];
      this._segmentsById[newSegmentId] = segment;
    }
  }
};
WaveformSegments.prototype._findSegment = function(predicate) {
  const indexes = [];
  for (let i = 0, length = this._segments.length; i < length; i++) {
    if (predicate(this._segments[i])) {
      indexes.push(i);
    }
  }
  return indexes;
};
WaveformSegments.prototype._removeIndexes = function(indexes) {
  const removed = [];
  for (let i = 0; i < indexes.length; i++) {
    const index = indexes[i] - removed.length;
    const itemRemoved = this._segments.splice(index, 1)[0];
    delete this._segmentsById[itemRemoved.id];
    delete this._segmentsByPid[itemRemoved.pid];
    removed.push(itemRemoved);
  }
  return removed;
};
WaveformSegments.prototype._removeSegments = function(predicate) {
  const indexes = this._findSegment(predicate);
  const removed = this._removeIndexes(indexes);
  this._peaks.emit("segments.remove", {
    segments: removed
  });
  return removed;
};
WaveformSegments.prototype.remove = function(segment) {
  return this._removeSegments(function(s) {
    return s === segment;
  });
};
WaveformSegments.prototype.removeById = function(segmentId) {
  return this._removeSegments(function(segment) {
    return segment.id === segmentId;
  });
};
WaveformSegments.prototype.removeByTime = function(startTime, endTime) {
  endTime = typeof endTime === "number" ? endTime : 0;
  let filter;
  if (endTime > 0) {
    filter = function(segment) {
      return segment.startTime === startTime && segment.endTime === endTime;
    };
  } else {
    filter = function(segment) {
      return segment.startTime === startTime;
    };
  }
  return this._removeSegments(filter);
};
WaveformSegments.prototype.removeAll = function() {
  this._segments = [];
  this._segmentsById = {};
  this._segmentsByPid = {};
  this._peaks.emit("segments.remove_all");
};
WaveformSegments.prototype.setInserting = function(value) {
  this._isInserting = value;
};
WaveformSegments.prototype.isInserting = function() {
  return this._isInserting;
};
const nodes = ["OBJECT", "TEXTAREA", "INPUT", "SELECT", "OPTION"];
const SPACE = 32, TAB = 9, LEFT_ARROW = 37, RIGHT_ARROW = 39;
const keys = [SPACE, TAB, LEFT_ARROW, RIGHT_ARROW];
function KeyboardHandler(eventEmitter) {
  this.eventEmitter = eventEmitter;
  this._handleKeyEvent = this._handleKeyEvent.bind(this);
  document.addEventListener("keydown", this._handleKeyEvent);
  document.addEventListener("keypress", this._handleKeyEvent);
  document.addEventListener("keyup", this._handleKeyEvent);
}
KeyboardHandler.prototype._handleKeyEvent = function handleKeyEvent(event) {
  if (nodes.indexOf(event.target.nodeName) === -1) {
    if (keys.indexOf(event.type) > -1) {
      event.preventDefault();
    }
    if (event.type === "keydown" || event.type === "keypress") {
      switch (event.keyCode) {
        case SPACE:
          this.eventEmitter.emit("keyboard.space");
          break;
        case TAB:
          this.eventEmitter.emit("keyboard.tab");
          break;
      }
    } else if (event.type === "keyup") {
      switch (event.keyCode) {
        case LEFT_ARROW:
          if (event.shiftKey) {
            this.eventEmitter.emit("keyboard.shift_left");
          } else {
            this.eventEmitter.emit("keyboard.left");
          }
          break;
        case RIGHT_ARROW:
          if (event.shiftKey) {
            this.eventEmitter.emit("keyboard.shift_right");
          } else {
            this.eventEmitter.emit("keyboard.right");
          }
          break;
      }
    }
  }
};
KeyboardHandler.prototype.destroy = function() {
  document.removeEventListener("keydown", this._handleKeyEvent);
  document.removeEventListener("keypress", this._handleKeyEvent);
  document.removeEventListener("keyup", this._handleKeyEvent);
};
function mediaElementHasSource(mediaElement) {
  if (mediaElement.src) {
    return true;
  }
  if (mediaElement.querySelector("source")) {
    return true;
  }
  return false;
}
function MediaElementPlayer(mediaElement) {
  this._mediaElement = mediaElement;
}
MediaElementPlayer.prototype._addMediaListener = function(type, callback) {
  this._listeners.push({ type, callback });
  this._mediaElement.addEventListener(type, callback);
};
MediaElementPlayer.prototype.init = function(eventEmitter) {
  const self = this;
  self._eventEmitter = eventEmitter;
  self._listeners = [];
  self._duration = self.getDuration();
  self._addMediaListener("timeupdate", function() {
    self._eventEmitter.emit("player.timeupdate", self.getCurrentTime());
  });
  self._addMediaListener("playing", function() {
    self._eventEmitter.emit("player.playing", self.getCurrentTime());
  });
  self._addMediaListener("pause", function() {
    self._eventEmitter.emit("player.pause", self.getCurrentTime());
  });
  self._addMediaListener("ended", function() {
    self._eventEmitter.emit("player.ended");
  });
  self._addMediaListener("seeked", function() {
    self._eventEmitter.emit("player.seeked", self.getCurrentTime());
  });
  self._addMediaListener("canplay", function() {
    self._eventEmitter.emit("player.canplay");
  });
  self._addMediaListener("error", function(event) {
    self._eventEmitter.emit("player.error", event.target.error);
  });
  self._interval = null;
  if (!mediaElementHasSource(self._mediaElement)) {
    return Promise.resolve();
  }
  return new Promise(function(resolve, reject) {
    function eventHandler(event) {
      self._mediaElement.removeEventListener("loadedmetadata", eventHandler);
      self._mediaElement.removeEventListener("error", eventHandler);
      if (event.type === "loadedmetadata") {
        resolve();
      } else {
        reject(event.target.error);
      }
    }
    if (self._mediaElement.readyState === HTMLMediaElement.HAVE_NOTHING) {
      self._mediaElement.addEventListener("loadedmetadata", eventHandler);
      self._mediaElement.addEventListener("error", eventHandler);
      self._mediaElement.load();
    } else {
      resolve();
    }
  });
};
MediaElementPlayer.prototype.destroy = function() {
  for (let i = 0; i < this._listeners.length; i++) {
    const listener = this._listeners[i];
    this._mediaElement.removeEventListener(
      listener.type,
      listener.callback
    );
  }
  this._listeners.length = 0;
  this._mediaElement = null;
};
MediaElementPlayer.prototype.play = function() {
  return this._mediaElement.play();
};
MediaElementPlayer.prototype.pause = function() {
  this._mediaElement.pause();
};
MediaElementPlayer.prototype.isPlaying = function() {
  return !this._mediaElement.paused;
};
MediaElementPlayer.prototype.isSeeking = function() {
  return this._mediaElement.seeking;
};
MediaElementPlayer.prototype.getCurrentTime = function() {
  return this._mediaElement.currentTime;
};
MediaElementPlayer.prototype.getDuration = function() {
  return this._mediaElement.duration;
};
MediaElementPlayer.prototype.seek = function(time) {
  this._mediaElement.currentTime = time;
};
function SetSourceHandler(eventEmitter, mediaElement) {
  this._eventEmitter = eventEmitter;
  this._mediaElement = mediaElement;
  this._playerCanPlayHandler = this._playerCanPlayHandler.bind(this);
  this._playerErrorHandler = this._playerErrorHandler.bind(this);
}
SetSourceHandler.prototype.setSource = function(options, callback) {
  const self = this;
  self._options = options;
  self._callback = callback;
  self._eventEmitter.on("player.canplay", self._playerCanPlayHandler);
  self._eventEmitter.on("player.error", self._playerErrorHandler);
  return new Promise(function(resolve, reject) {
    self._resolve = resolve;
    self._reject = reject;
    self._eventEmitter.on("player.canplay", self._playerCanPlayHandler);
    self._eventEmitter.on("player.error", self._playerErrorHandler);
    self._mediaElement.setAttribute("src", options.mediaUrl);
    if (self._mediaElement.readyState === HTMLMediaElement.HAVE_NOTHING) {
      self._mediaElement.load();
    }
  });
};
SetSourceHandler.prototype._reset = function() {
  this._eventEmitter.removeListener("player.canplay", this._playerCanPlayHandler);
  this._eventEmitter.removeListener("player.error", this._playerErrorHandler);
};
SetSourceHandler.prototype._playerCanPlayHandler = function() {
  this._reset();
  this._resolve();
};
SetSourceHandler.prototype._playerErrorHandler = function(err) {
  this._reset();
  this._reject(err);
};
MediaElementPlayer.prototype.setSource = function(options) {
  if (!options.mediaUrl) {
    return Promise.reject(new Error("peaks.setSource(): options must contain a mediaUrl when using mediaElement"));
  }
  const setSourceHandler = new SetSourceHandler(this._eventEmitter, this._mediaElement);
  return setSourceHandler.setSource(options);
};
function getAllPropertiesFrom(adapter) {
  const allProperties = [];
  let obj = adapter;
  while (obj) {
    Object.getOwnPropertyNames(obj).forEach(function(p) {
      allProperties.push(p);
    });
    obj = Object.getPrototypeOf(obj);
  }
  return allProperties;
}
function validateAdapter(adapter) {
  const publicAdapterMethods = [
    "init",
    "destroy",
    "play",
    "pause",
    "isPlaying",
    "isSeeking",
    "getCurrentTime",
    "getDuration",
    "seek"
  ];
  const allProperties = getAllPropertiesFrom(adapter);
  publicAdapterMethods.forEach(function(method) {
    if (!allProperties.includes(method)) {
      throw new TypeError("Peaks.init(): Player method " + method + " is undefined");
    }
    if (typeof adapter[method] !== "function") {
      throw new TypeError("Peaks.init(): Player method " + method + " is not a function");
    }
  });
}
function Player(peaks, adapter) {
  this._peaks = peaks;
  this._playingSegment = false;
  this._segment = null;
  this._loop = false;
  this._playSegmentTimerCallback = this._playSegmentTimerCallback.bind(this);
  validateAdapter(adapter);
  this._adapter = adapter;
}
Player.prototype.init = function() {
  return this._adapter.init(this._peaks);
};
Player.prototype.destroy = function() {
  this._adapter.destroy();
};
Player.prototype.play = function() {
  return this._adapter.play();
};
Player.prototype.pause = function() {
  this._adapter.pause();
};
Player.prototype.isPlaying = function() {
  return this._adapter.isPlaying();
};
Player.prototype.isSeeking = function() {
  return this._adapter.isSeeking();
};
Player.prototype.getCurrentTime = function() {
  return this._adapter.getCurrentTime();
};
Player.prototype.getDuration = function() {
  return this._adapter.getDuration();
};
Player.prototype.seek = function(time) {
  if (!isValidTime(time)) {
    this._peaks._logger("peaks.player.seek(): parameter must be a valid time, in seconds");
    return;
  }
  this._adapter.seek(time);
};
Player.prototype.playSegment = function(segment, loop) {
  const self = this;
  if (!segment || !isValidTime(segment.startTime) || !isValidTime(segment.endTime)) {
    return Promise.reject(
      new Error("peaks.player.playSegment(): parameter must be a segment object")
    );
  }
  self._segment = segment;
  self._loop = loop;
  self.seek(segment.startTime);
  self._peaks.once("player.playing", function() {
    if (!self._playingSegment) {
      self._playingSegment = true;
      window.requestAnimationFrame(self._playSegmentTimerCallback);
    }
  });
  return self.play();
};
Player.prototype._playSegmentTimerCallback = function() {
  if (!this.isPlaying()) {
    this._playingSegment = false;
    return;
  } else if (this.getCurrentTime() >= this._segment.endTime) {
    if (this._loop) {
      this.seek(this._segment.startTime);
    } else {
      this.pause();
      this._peaks.emit("player.ended");
      this._playingSegment = false;
      return;
    }
  }
  window.requestAnimationFrame(this._playSegmentTimerCallback);
};
Player.prototype._setSource = function(options) {
  return this._adapter.setSource(options);
};
function DefaultPointMarker(options) {
  this._options = options;
  this._editable = options.editable;
}
DefaultPointMarker.prototype.init = function(group) {
  const handleWidth = 10;
  const handleHeight = 20;
  const handleX = -(handleWidth / 2) + 0.5;
  if (this._options.view === "zoomview" || this._options.view === "spectrogramZoomview") {
    this._label = new Text({
      x: 2,
      y: 0,
      text: this._options.point.labelText,
      textAlign: "left",
      fontFamily: this._options.fontFamily || "sans-serif",
      fontSize: this._options.fontSize || 10,
      fontStyle: this._options.fontStyle || "normal",
      fill: "#000"
    });
  }
  this._handle = new Rect({
    x: handleX,
    y: 0,
    width: handleWidth,
    height: handleHeight,
    fill: this._options.color,
    visible: this._editable
  });
  this._line = new Line({
    x: 0,
    y: 0,
    stroke: this._options.color,
    strokeWidth: 1
  });
  this._time = new Text({
    x: -24,
    y: 0,
    text: this._options.layer.formatTime(this._options.point.time),
    fontFamily: this._options.fontFamily,
    fontSize: this._options.fontSize,
    fontStyle: this._options.fontStyle,
    fill: "#000",
    textAlign: "center"
  });
  this._time.hide();
  group.add(this._handle);
  group.add(this._line);
  if (this._label) {
    group.add(this._label);
  }
  group.add(this._time);
  this.fitToView();
  this.bindEventHandlers(group);
};
DefaultPointMarker.prototype.bindEventHandlers = function(group) {
  const self = this;
  self._handle.on("mouseover touchstart", function() {
    if (self._editable) {
      self._time.setX(-24 - self._time.getWidth());
      self._time.show();
    }
  });
  self._handle.on("mouseout touchend", function() {
    if (self._editable) {
      self._time.hide();
    }
  });
  group.on("dragstart", function() {
    self._time.setX(-24 - self._time.getWidth());
    self._time.show();
  });
  group.on("dragend", function() {
    self._time.hide();
  });
};
DefaultPointMarker.prototype.fitToView = function() {
  const height = this._options.layer.getHeight();
  this._line.points([0.5, 0, 0.5, height]);
  if (this._label) {
    this._label.y(12);
  }
  if (this._handle) {
    this._handle.y(height / 2 - 10.5);
  }
  if (this._time) {
    this._time.y(height / 2 - 5);
  }
};
DefaultPointMarker.prototype.update = function(options) {
  if (options.time !== void 0) {
    if (this._time) {
      this._time.setText(this._options.layer.formatTime(options.time));
    }
  }
  if (options.labelText !== void 0) {
    if (this._label) {
      this._label.text(options.labelText);
    }
  }
  if (options.color !== void 0) {
    if (this._handle) {
      this._handle.fill(options.color);
    }
    this._line.stroke(options.color);
  }
  if (options.editable !== void 0) {
    this._draggable = options.editable;
    this._handle.visible(this._draggable);
  }
};
function DefaultSegmentMarker(options) {
  this._options = options;
  this._editable = options.editable;
}
DefaultSegmentMarker.prototype.init = function(group) {
  const handleWidth = 10;
  const handleHeight = 20;
  const handleX = -(handleWidth / 2) + 0.5;
  const xPosition = this._options.startMarker ? -24 : 24;
  const time = this._options.startMarker ? this._options.segment.startTime : this._options.segment.endTime;
  this._label = new Text({
    x: xPosition,
    y: 0,
    text: this._options.layer.formatTime(time),
    fontFamily: this._options.fontFamily,
    fontSize: this._options.fontSize,
    fontStyle: this._options.fontStyle,
    fill: "#000",
    textAlign: "center",
    visible: this._editable
  });
  this._label.hide();
  this._handle = new Rect({
    x: handleX,
    y: 0,
    width: handleWidth,
    height: handleHeight,
    fill: this._options.color,
    stroke: this._options.color,
    strokeWidth: 1,
    visible: this._editable
  });
  this._line = new Line({
    x: 0,
    y: 0,
    stroke: this._options.color,
    strokeWidth: 1,
    visible: this._editable
  });
  group.add(this._label);
  group.add(this._line);
  group.add(this._handle);
  this.fitToView();
  this.bindEventHandlers(group);
};
DefaultSegmentMarker.prototype.bindEventHandlers = function(group) {
  const self = this;
  const xPosition = self._options.startMarker ? -24 : 24;
  group.on("dragstart", function() {
    if (self._options.startMarker) {
      self._label.setX(xPosition - self._label.getWidth());
    }
    self._label.show();
  });
  group.on("dragend", function() {
    self._label.hide();
  });
  self._handle.on("mouseover touchstart", function() {
    if (self._options.startMarker) {
      self._label.setX(xPosition - self._label.getWidth());
    }
    self._label.show();
  });
  self._handle.on("mouseout touchend", function() {
    self._label.hide();
  });
};
DefaultSegmentMarker.prototype.fitToView = function() {
  const height = this._options.layer.getHeight();
  this._label.y(height / 2 - 5);
  this._handle.y(height / 2 - 10.5);
  this._line.points([0.5, 0, 0.5, height]);
};
DefaultSegmentMarker.prototype.update = function(options) {
  if (options.startTime !== void 0 && this._options.startMarker) {
    this._label.text(this._options.layer.formatTime(options.startTime));
  }
  if (options.endTime !== void 0 && !this._options.startMarker) {
    this._label.text(this._options.layer.formatTime(options.endTime));
  }
  if (options.editable !== void 0) {
    this._editable = options.editable;
    this._label.visible(this._editable);
    this._handle.visible(this._editable);
    this._line.visible(this._editable);
  }
};
function createSegmentMarker(options) {
  if (options.view === "zoomview" || options.view === "spectrogramZoomview") {
    return new DefaultSegmentMarker(options);
  }
  return null;
}
function createSegmentLabel(options) {
  return new Text({
    x: 12,
    y: 12,
    text: options.segment.labelText,
    textAlign: "center",
    fontFamily: options.fontFamily || "sans-serif",
    fontSize: options.fontSize || 12,
    fontStyle: options.fontStyle || "normal",
    fill: "#000"
  });
}
function createPointMarker(options) {
  return new DefaultPointMarker(options);
}
function HighlightLayer(view, options) {
  this._view = view;
  this._offset = options.highlightOffset;
  this._color = options.highlightColor;
  this._layer = new Konva.Layer({ listening: false });
  this._highlightRect = null;
  this._startTime = null;
  this._endTime = null;
  this._strokeColor = options.highlightStrokeColor;
  this._opacity = options.highlightOpacity;
  this._cornerRadius = options.highlightCornerRadius;
}
HighlightLayer.prototype.addToStage = function(stage) {
  stage.add(this._layer);
};
HighlightLayer.prototype.showHighlight = function(startTime, endTime) {
  if (!this._highlightRect) {
    this._createHighlightRect(startTime, endTime);
  }
  this._update(startTime, endTime);
};
HighlightLayer.prototype._update = function(startTime, endTime) {
  this._startTime = startTime;
  this._endTime = endTime;
  const startOffset = this._view.timeToPixels(startTime);
  const endOffset = this._view.timeToPixels(endTime);
  this._highlightRect.setAttrs({
    x: startOffset,
    width: endOffset - startOffset
  });
};
HighlightLayer.prototype._createHighlightRect = function(startTime, endTime) {
  this._startTime = startTime;
  this._endTime = endTime;
  const startOffset = this._view.timeToPixels(startTime);
  const endOffset = this._view.timeToPixels(endTime);
  this._highlightRect = new Rect({
    x: startOffset,
    y: 0,
    width: endOffset - startOffset,
    height: 0,
    stroke: this._strokeColor,
    strokeWidth: 1,
    fill: this._color,
    opacity: this._opacity,
    cornerRadius: this._cornerRadius
  });
  this.fitToView();
  this._layer.add(this._highlightRect);
};
HighlightLayer.prototype.removeHighlight = function() {
  if (this._highlightRect) {
    this._highlightRect.destroy();
    this._highlightRect = null;
  }
};
HighlightLayer.prototype.updateHighlight = function() {
  if (this._highlightRect) {
    this._update(this._startTime, this._endTime);
  }
};
HighlightLayer.prototype.fitToView = function() {
  if (this._highlightRect) {
    const height = this._view.getHeight();
    const offset = clamp(this._offset, 0, Math.floor(height / 2));
    this._highlightRect.setAttrs({
      y: offset,
      height: height - offset * 2
    });
  }
};
function PlayheadLayer(options) {
  this._player = options.player;
  this._view = options.view;
  this._playheadPixel = 0;
  this._playheadLineAnimation = null;
  this._playheadVisible = false;
  this._playheadColor = options.playheadColor;
  this._playheadTextColor = options.playheadTextColor;
  this._playheadFontFamily = options.playheadFontFamily;
  this._playheadFontSize = options.playheadFontSize;
  this._playheadFontStyle = options.playheadFontStyle;
  this._playheadLayer = new Konva.Layer();
  this._createPlayhead(this._playheadColor);
  if (options.showPlayheadTime) {
    this._createPlayheadText(this._playheadTextColor);
  }
  this.fitToView();
  this.zoomLevelChanged();
}
PlayheadLayer.prototype.addToStage = function(stage) {
  stage.add(this._playheadLayer);
};
PlayheadLayer.prototype.zoomLevelChanged = function() {
  const pixelsPerSecond = this._view.timeToPixels(1);
  this._useAnimation = pixelsPerSecond >= 5;
  if (this._useAnimation) {
    if (this._player.isPlaying() && !this._playheadLineAnimation) {
      this._start();
    }
  } else {
    if (this._playheadLineAnimation) {
      const time = this._player.getCurrentTime();
      this.stop(time);
    }
  }
};
PlayheadLayer.prototype.fitToView = function() {
  const height = this._view.getHeight();
  this._playheadLine.points([0.5, 0, 0.5, height]);
  if (this._playheadText) {
    this._playheadText.y(12);
  }
};
PlayheadLayer.prototype._createPlayhead = function(color) {
  this._playheadLine = new Line({
    stroke: color,
    strokeWidth: 1
  });
  this._playheadGroup = new Konva.Group({
    x: 0,
    y: 0
  });
  this._playheadGroup.add(this._playheadLine);
  this._playheadLayer.add(this._playheadGroup);
};
PlayheadLayer.prototype._createPlayheadText = function(color) {
  const time = this._player.getCurrentTime();
  const text = this._view.formatTime(time);
  this._playheadText = new Text({
    x: 2,
    y: 0,
    text,
    fontSize: this._playheadFontSize,
    fontFamily: this._playheadFontFamily,
    fontStyle: this._playheadFontStyle,
    fill: color,
    align: "right"
  });
  this._playheadGroup.add(this._playheadText);
};
PlayheadLayer.prototype.updatePlayheadTime = function(time) {
  this._syncPlayhead(time);
  if (this._player.isPlaying()) {
    this._start();
  }
};
PlayheadLayer.prototype._syncPlayhead = function(time) {
  const pixelIndex = this._view.timeToPixels(time);
  const frameOffset = this._view.getFrameOffset();
  const width = this._view.getWidth();
  const isVisible = pixelIndex >= frameOffset && pixelIndex <= frameOffset + width;
  this._playheadPixel = pixelIndex;
  if (isVisible) {
    const playheadX = this._playheadPixel - frameOffset;
    if (!this._playheadVisible) {
      this._playheadVisible = true;
      this._playheadGroup.show();
    }
    this._playheadGroup.setX(playheadX);
    if (this._playheadText) {
      const text = this._view.formatTime(time);
      const playheadTextWidth = this._playheadText.getTextWidth();
      this._playheadText.setText(text);
      if (playheadTextWidth + playheadX > width - 2) {
        this._playheadText.setX(-playheadTextWidth - 2);
      } else if (playheadTextWidth + playheadX < width) {
        this._playheadText.setX(2);
      }
    }
  } else {
    if (this._playheadVisible) {
      this._playheadVisible = false;
      this._playheadGroup.hide();
    }
  }
  if (this._view.playheadPosChanged) {
    this._view.playheadPosChanged(time);
  }
};
PlayheadLayer.prototype._start = function() {
  const self = this;
  if (self._playheadLineAnimation) {
    self._playheadLineAnimation.stop();
    self._playheadLineAnimation = null;
  }
  if (!self._useAnimation) {
    return;
  }
  let lastPlayheadPosition = null;
  self._playheadLineAnimation = new Animation(function() {
    const time = self._player.getCurrentTime();
    const playheadPosition = self._view.timeToPixels(time);
    if (playheadPosition !== lastPlayheadPosition) {
      self._syncPlayhead(time);
      lastPlayheadPosition = playheadPosition;
    }
  }, self._playheadLayer);
  self._playheadLineAnimation.start();
};
PlayheadLayer.prototype.stop = function(time) {
  if (this._playheadLineAnimation) {
    this._playheadLineAnimation.stop();
    this._playheadLineAnimation = null;
  }
  this._syncPlayhead(time);
};
PlayheadLayer.prototype.getPlayheadPixel = function() {
  return this._playheadPixel;
};
PlayheadLayer.prototype.showPlayheadTime = function(show) {
  if (show) {
    if (!this._playheadText) {
      this._createPlayheadText(this._playheadTextColor);
      this.fitToView();
    }
  } else {
    if (this._playheadText) {
      this._playheadText.remove();
      this._playheadText.destroy();
      this._playheadText = null;
    }
  }
};
PlayheadLayer.prototype.updatePlayheadText = function() {
  if (this._playheadText) {
    const time = this._player.getCurrentTime();
    const text = this._view.formatTime(time);
    this._playheadText.setText(text);
  }
};
PlayheadLayer.prototype.destroy = function() {
  if (this._playheadLineAnimation) {
    this._playheadLineAnimation.stop();
    this._playheadLineAnimation = null;
  }
};
function PointMarker(options) {
  this._point = options.point;
  this._marker = options.marker;
  this._draggable = options.draggable;
  this._onDragStart = options.onDragStart;
  this._onDragMove = options.onDragMove;
  this._onDragEnd = options.onDragEnd;
  this._dragBoundFunc = options.dragBoundFunc;
  this._onMouseEnter = options.onMouseEnter;
  this._onMouseLeave = options.onMouseLeave;
  this._group = new Konva.Group({
    name: "point-marker",
    point: this._point,
    draggable: this._draggable,
    dragBoundFunc: options.dragBoundFunc
  });
  this._bindDefaultEventHandlers();
  this._marker.init(this._group);
}
PointMarker.prototype._bindDefaultEventHandlers = function() {
  const self = this;
  self._group.on("dragstart", function(event) {
    self._onDragStart(event, self._point);
  });
  self._group.on("dragmove", function(event) {
    self._onDragMove(event, self._point);
  });
  self._group.on("dragend", function(event) {
    self._onDragEnd(event, self._point);
  });
  self._group.on("mouseenter", function(event) {
    self._onMouseEnter(event, self._point);
  });
  self._group.on("mouseleave", function(event) {
    self._onMouseLeave(event, self._point);
  });
};
PointMarker.prototype.addToLayer = function(layer) {
  layer.add(this._group);
};
PointMarker.prototype.fitToView = function() {
  this._marker.fitToView();
};
PointMarker.prototype.getPoint = function() {
  return this._point;
};
PointMarker.prototype.getX = function() {
  return this._group.getX();
};
PointMarker.prototype.setX = function(x) {
  this._group.setX(x);
};
PointMarker.prototype.getWidth = function() {
  return this._group.getWidth();
};
PointMarker.prototype.getAbsolutePosition = function() {
  return this._group.getAbsolutePosition();
};
PointMarker.prototype.update = function(options) {
  if (options.editable !== void 0) {
    this._group.draggable(options.editable);
  }
  if (this._marker.update) {
    this._marker.update(options);
  }
};
PointMarker.prototype.destroy = function() {
  if (this._marker.destroy) {
    this._marker.destroy();
  }
  this._group.destroyChildren();
  this._group.destroy();
};
const defaultFontFamily$1 = "sans-serif";
const defaultFontSize$1 = 10;
const defaultFontShape$1 = "normal";
function PointsLayer(peaks, view, allowEditing) {
  this._peaks = peaks;
  this._view = view;
  this._allowEditing = allowEditing;
  this._pointMarkers = {};
  this._layer = new Konva.Layer();
  this._onPointsDrag = this._onPointsDrag.bind(this);
  this._onPointMarkerDragStart = this._onPointMarkerDragStart.bind(this);
  this._onPointMarkerDragMove = this._onPointMarkerDragMove.bind(this);
  this._onPointMarkerDragEnd = this._onPointMarkerDragEnd.bind(this);
  this._pointMarkerDragBoundFunc = this._pointMarkerDragBoundFunc.bind(this);
  this._onPointMarkerMouseEnter = this._onPointMarkerMouseEnter.bind(this);
  this._onPointMarkerMouseLeave = this._onPointMarkerMouseLeave.bind(this);
  this._onPointsUpdate = this._onPointsUpdate.bind(this);
  this._onPointsAdd = this._onPointsAdd.bind(this);
  this._onPointsRemove = this._onPointsRemove.bind(this);
  this._onPointsRemoveAll = this._onPointsRemoveAll.bind(this);
  this._peaks.on("points.update", this._onPointsUpdate);
  this._peaks.on("points.add", this._onPointsAdd);
  this._peaks.on("points.remove", this._onPointsRemove);
  this._peaks.on("points.remove_all", this._onPointsRemoveAll);
  this._peaks.on("points.dragstart", this._onPointsDrag);
  this._peaks.on("points.dragmove", this._onPointsDrag);
  this._peaks.on("points.dragend", this._onPointsDrag);
}
PointsLayer.prototype.addToStage = function(stage) {
  stage.add(this._layer);
};
PointsLayer.prototype.enableEditing = function(enable) {
  this._allowEditing = enable;
};
PointsLayer.prototype.getPointMarker = function(point) {
  return this._pointMarkers[point.pid];
};
PointsLayer.prototype.formatTime = function(time) {
  return this._view.formatTime(time);
};
PointsLayer.prototype._onPointsUpdate = function(point, options) {
  const frameStartTime = this._view.getStartTime();
  const frameEndTime = this._view.getEndTime();
  const pointMarker = this.getPointMarker(point);
  const isVisible = point.isVisible(frameStartTime, frameEndTime);
  if (pointMarker && !isVisible) {
    this._removePoint(point);
  } else if (!pointMarker && isVisible) {
    this._updatePoint(point);
  } else if (pointMarker && isVisible) {
    if (objectHasProperty(options, "time")) {
      const pointMarkerOffset = this._view.timeToPixels(point.time);
      const pointMarkerX = pointMarkerOffset - this._view.getFrameOffset();
      pointMarker.setX(pointMarkerX);
    }
    pointMarker.update(options);
  }
};
PointsLayer.prototype._onPointsAdd = function(event) {
  const self = this;
  const frameStartTime = self._view.getStartTime();
  const frameEndTime = self._view.getEndTime();
  event.points.forEach(function(point) {
    if (point.isVisible(frameStartTime, frameEndTime)) {
      self._updatePoint(point);
    }
  });
};
PointsLayer.prototype._onPointsRemove = function(event) {
  const self = this;
  event.points.forEach(function(point) {
    self._removePoint(point);
  });
};
PointsLayer.prototype._onPointsRemoveAll = function() {
  this._layer.removeChildren();
  this._pointMarkers = {};
};
PointsLayer.prototype._createPointMarker = function(point) {
  const editable = this._allowEditing && point.editable;
  const marker = this._peaks.options.createPointMarker({
    point,
    draggable: editable,
    color: point.color,
    fontFamily: this._peaks.options.fontFamily || defaultFontFamily$1,
    fontSize: this._peaks.options.fontSize || defaultFontSize$1,
    fontStyle: this._peaks.options.fontStyle || defaultFontShape$1,
    layer: this,
    view: this._view.getName()
  });
  return new PointMarker({
    point,
    draggable: editable,
    marker,
    onDragStart: this._onPointMarkerDragStart,
    onDragMove: this._onPointMarkerDragMove,
    onDragEnd: this._onPointMarkerDragEnd,
    dragBoundFunc: this._pointMarkerDragBoundFunc,
    onMouseEnter: this._onPointMarkerMouseEnter,
    onMouseLeave: this._onPointMarkerMouseLeave
  });
};
PointsLayer.prototype.getHeight = function() {
  return this._view.getHeight();
};
PointsLayer.prototype._addPointMarker = function(point) {
  const pointMarker = this._createPointMarker(point);
  this._pointMarkers[point.pid] = pointMarker;
  pointMarker.addToLayer(this._layer);
  return pointMarker;
};
PointsLayer.prototype._onPointsDrag = function(event) {
  const pointMarker = this._updatePoint(event.point);
  pointMarker.update({ time: event.point.time });
};
PointsLayer.prototype._onPointMarkerDragMove = function(event, point) {
  const pointMarker = this._pointMarkers[point.pid];
  const markerX = pointMarker.getX();
  const offset = markerX + pointMarker.getWidth();
  point._setTime(this._view.pixelOffsetToTime(offset));
  this._peaks.emit("points.dragmove", {
    point,
    evt: event.evt
  });
};
PointsLayer.prototype._onPointMarkerMouseEnter = function(event, point) {
  this._peaks.emit("points.mouseenter", {
    point,
    evt: event.evt
  });
};
PointsLayer.prototype._onPointMarkerMouseLeave = function(event, point) {
  this._peaks.emit("points.mouseleave", {
    point,
    evt: event.evt
  });
};
PointsLayer.prototype._onPointMarkerDragStart = function(event, point) {
  this._dragPointMarker = this.getPointMarker(point);
  this._peaks.emit("points.dragstart", {
    point,
    evt: event.evt
  });
};
PointsLayer.prototype._onPointMarkerDragEnd = function(event, point) {
  this._dragPointMarker = null;
  this._peaks.emit("points.dragend", {
    point,
    evt: event.evt
  });
};
PointsLayer.prototype._pointMarkerDragBoundFunc = function(pos) {
  return {
    x: clamp(pos.x, 0, this._view.getWidth()),
    y: this._dragPointMarker.getAbsolutePosition().y
  };
};
PointsLayer.prototype.updatePoints = function(startTime, endTime) {
  const points = this._peaks.points.find(startTime, endTime);
  points.forEach(this._updatePoint.bind(this));
  this._removeInvisiblePoints(startTime, endTime);
};
PointsLayer.prototype._updatePoint = function(point) {
  let pointMarker = this.getPointMarker(point);
  if (!pointMarker) {
    pointMarker = this._addPointMarker(point);
  }
  const pointMarkerOffset = this._view.timeToPixels(point.time);
  const pointMarkerX = pointMarkerOffset - this._view.getFrameOffset();
  pointMarker.setX(pointMarkerX);
  return pointMarker;
};
PointsLayer.prototype._removeInvisiblePoints = function(startTime, endTime) {
  for (const pointPid in this._pointMarkers) {
    if (objectHasProperty(this._pointMarkers, pointPid)) {
      const point = this._pointMarkers[pointPid].getPoint();
      if (!point.isVisible(startTime, endTime)) {
        this._removePoint(point);
      }
    }
  }
};
PointsLayer.prototype._removePoint = function(point) {
  const pointMarker = this.getPointMarker(point);
  if (pointMarker) {
    pointMarker.destroy();
    delete this._pointMarkers[point.pid];
  }
};
PointsLayer.prototype.setVisible = function(visible) {
  this._layer.setVisible(visible);
};
PointsLayer.prototype.destroy = function() {
  this._peaks.off("points.update", this._onPointsUpdate);
  this._peaks.off("points.add", this._onPointsAdd);
  this._peaks.off("points.remove", this._onPointsRemove);
  this._peaks.off("points.remove_all", this._onPointsRemoveAll);
  this._peaks.off("points.dragstart", this._onPointsDrag);
  this._peaks.off("points.dragmove", this._onPointsDrag);
  this._peaks.off("points.dragend", this._onPointsDrag);
};
PointsLayer.prototype.fitToView = function() {
  for (const pointPid in this._pointMarkers) {
    if (objectHasProperty(this._pointMarkers, pointPid)) {
      const pointMarker = this._pointMarkers[pointPid];
      pointMarker.fitToView();
    }
  }
};
PointsLayer.prototype.draw = function() {
  this._layer.draw();
};
function OverlaySegmentMarker(options) {
  this._options = options;
}
OverlaySegmentMarker.prototype.init = function(group) {
  const handleWidth = 10;
  const handleHeight = 20;
  const handleX = -(handleWidth / 2) + 0.5;
  const xPosition = this._options.startMarker ? -24 : 24;
  const time = this._options.startMarker ? this._options.segment.startTime : this._options.segment.endTime;
  this._label = new Text({
    x: xPosition,
    y: 0,
    text: this._options.layer.formatTime(time),
    fontFamily: this._options.fontFamily,
    fontSize: this._options.fontSize,
    fontStyle: this._options.fontStyle,
    fill: "#000",
    textAlign: "center",
    visible: false
  });
  this._handle = new Rect({
    x: handleX,
    y: 0,
    width: handleWidth,
    height: handleHeight
  });
  group.add(this._label);
  group.add(this._handle);
  this.fitToView();
  this.bindEventHandlers(group);
};
OverlaySegmentMarker.prototype.bindEventHandlers = function(group) {
  const self = this;
  const xPosition = self._options.startMarker ? -24 : 24;
  group.on("dragstart", function() {
    if (self._options.startMarker) {
      self._label.setX(xPosition - self._label.getWidth());
    }
    self._label.show();
  });
  group.on("dragend", function() {
    self._label.hide();
  });
  self._handle.on("mouseover touchstart", function() {
    if (self._options.startMarker) {
      self._label.setX(xPosition - self._label.getWidth());
    }
    self._label.show();
    document.body.style.cursor = "ew-resize";
  });
  self._handle.on("mouseout touchend", function() {
    self._label.hide();
    document.body.style.cursor = "default";
  });
};
OverlaySegmentMarker.prototype.fitToView = function() {
  const viewHeight = this._options.layer.getHeight();
  const overlayOffset = this._options.segmentOptions.overlayOffset;
  const overlayRectHeight = clamp(0, viewHeight - 2 * overlayOffset);
  this._label.y(viewHeight / 2 - 5);
  this._handle.y(overlayOffset);
  this._handle.height(overlayRectHeight);
};
OverlaySegmentMarker.prototype.update = function(options) {
  if (options.startTime !== void 0 && this._options.startMarker) {
    this._label.text(this._options.layer.formatTime(options.startTime));
  }
  if (options.endTime !== void 0 && !this._options.startMarker) {
    this._label.text(this._options.layer.formatTime(options.endTime));
  }
};
function SegmentMarker(options) {
  const self = this;
  self._segment = options.segment;
  self._marker = options.marker;
  self._segmentShape = options.segmentShape;
  self._editable = options.editable;
  self._startMarker = options.startMarker;
  self._onDragStart = options.onDragStart;
  self._onDragMove = options.onDragMove;
  self._onDragEnd = options.onDragEnd;
  self._group = new Konva.Group({
    name: "segment-marker",
    segment: self._segment,
    draggable: self._editable,
    visible: self._editable,
    dragBoundFunc: function(pos) {
      return options.dragBoundFunc(self, pos);
    }
  });
  self._bindDefaultEventHandlers();
  self._marker.init(self._group);
}
SegmentMarker.prototype._bindDefaultEventHandlers = function() {
  const self = this;
  self._group.on("dragstart", function(event) {
    self._onDragStart(self, event);
  });
  self._group.on("dragmove", function(event) {
    self._onDragMove(self, event);
  });
  self._group.on("dragend", function(event) {
    self._onDragEnd(self, event);
  });
};
SegmentMarker.prototype.addToLayer = function(layer) {
  layer.add(this._group);
};
SegmentMarker.prototype.moveToTop = function() {
  this._group.moveToTop();
};
SegmentMarker.prototype.fitToView = function() {
  this._marker.fitToView();
};
SegmentMarker.prototype.getSegment = function() {
  return this._segment;
};
SegmentMarker.prototype.getX = function() {
  return this._group.getX();
};
SegmentMarker.prototype.setX = function(x) {
  this._group.setX(x);
};
SegmentMarker.prototype.getWidth = function() {
  return this._group.getWidth();
};
SegmentMarker.prototype.getAbsolutePosition = function() {
  return this._group.getAbsolutePosition();
};
SegmentMarker.prototype.isStartMarker = function() {
  return this._startMarker;
};
SegmentMarker.prototype.update = function(options) {
  if (options.editable !== void 0) {
    this._group.visible(options.editable);
    this._group.draggable(options.editable);
  }
  if (this._marker.update) {
    this._marker.update(options);
  }
};
SegmentMarker.prototype.destroy = function() {
  if (this._marker.destroy) {
    this._marker.destroy();
  }
  this._group.destroyChildren();
  this._group.destroy();
};
SegmentMarker.prototype.startDrag = function() {
  this._group.startDrag();
};
SegmentMarker.prototype.stopDrag = function() {
  this._group.stopDrag();
};
function WaveformShape(options) {
  this._color = options.color;
  const shapeOptions = {};
  if (isString(options.color)) {
    shapeOptions.fill = options.color;
  } else if (isLinearGradientColor(options.color)) {
    const startY = options.view._height * (options.color.linearGradientStart / 100);
    const endY = options.view._height * (options.color.linearGradientEnd / 100);
    shapeOptions.fillLinearGradientStartPointY = startY;
    shapeOptions.fillLinearGradientEndPointY = endY;
    shapeOptions.fillLinearGradientColorStops = [
      0,
      options.color.linearGradientColorStops[0],
      1,
      options.color.linearGradientColorStops[1]
    ];
  } else {
    throw new TypeError("Unknown type for color property");
  }
  this._shape = new Konva.Shape(shapeOptions);
  this._view = options.view;
  this._segment = options.segment;
  this._shape.sceneFunc(this._sceneFunc.bind(this));
}
WaveformShape.prototype.getX = function() {
  return this._shape.getX();
};
WaveformShape.prototype.setX = function(x) {
  return this._shape.setX(x);
};
WaveformShape.prototype.setSegment = function(segment) {
  this._segment = segment;
};
WaveformShape.prototype.setWaveformColor = function(color) {
  if (isString(color)) {
    this._shape.fill(color);
    this._shape.fillLinearGradientStartPointY(null);
    this._shape.fillLinearGradientEndPointY(null);
    this._shape.fillLinearGradientColorStops(null);
  } else if (isLinearGradientColor(color)) {
    this._shape.fill(null);
    const startY = this._view._height * (color.linearGradientStart / 100);
    const endY = this._view._height * (color.linearGradientEnd / 100);
    this._shape.fillLinearGradientStartPointY(startY);
    this._shape.fillLinearGradientEndPointY(endY);
    this._shape.fillLinearGradientColorStops([
      0,
      color.linearGradientColorStops[0],
      1,
      color.linearGradientColorStops[1]
    ]);
  } else {
    throw new TypeError("Unknown type for color property");
  }
};
WaveformShape.prototype.fitToView = function() {
  this.setWaveformColor(this._color);
};
WaveformShape.prototype._sceneFunc = function(context) {
  const frameOffset = this._view.getFrameOffset();
  const width = this._view.getWidth();
  const height = this._view.getHeight();
  this._drawWaveform(
    context,
    this._view.getWaveformData(),
    frameOffset,
    this._segment ? this._view.timeToPixels(this._segment.startTime) : frameOffset,
    this._segment ? this._view.timeToPixels(this._segment.endTime) : frameOffset + width,
    width,
    height
  );
};
WaveformShape.prototype._drawWaveform = function(context, waveformData, frameOffset, startPixels, endPixels, width, height) {
  if (startPixels < frameOffset) {
    startPixels = frameOffset;
  }
  const limit = frameOffset + width;
  if (endPixels > limit) {
    endPixels = limit;
  }
  if (endPixels > waveformData.length - 1) {
    endPixels = waveformData.length - 1;
  }
  const channels = waveformData.channels;
  let waveformTop = 0;
  let waveformHeight = Math.floor(height / channels);
  for (let i = 0; i < channels; i++) {
    if (i === channels - 1) {
      waveformHeight = height - (channels - 1) * waveformHeight;
    }
    this._drawChannel(
      context,
      waveformData.channel(i),
      frameOffset,
      startPixels,
      endPixels,
      waveformTop,
      waveformHeight
    );
    waveformTop += waveformHeight;
  }
};
WaveformShape.prototype._drawChannel = function(context, channel, frameOffset, startPixels, endPixels, top, height) {
  let x, amplitude;
  const amplitudeScale = this._view.getAmplitudeScale();
  let lineX, lineY;
  context.beginPath();
  for (x = startPixels; x <= endPixels; x++) {
    amplitude = channel.min_sample(x);
    lineX = x - frameOffset + 0.5;
    lineY = top + WaveformShape.scaleY(amplitude, height, amplitudeScale) + 0.5;
    context.lineTo(lineX, lineY);
  }
  for (x = endPixels; x >= startPixels; x--) {
    amplitude = channel.max_sample(x);
    lineX = x - frameOffset + 0.5;
    lineY = top + WaveformShape.scaleY(amplitude, height, amplitudeScale) + 1;
    context.lineTo(lineX, lineY);
  }
  context.closePath();
  context.fillShape(this._shape);
};
WaveformShape.prototype.addToLayer = function(layer) {
  layer.add(this._shape);
};
WaveformShape.prototype.destroy = function() {
  this._shape.destroy();
  this._shape = null;
};
WaveformShape.prototype.on = function(event, handler) {
  this._shape.on(event, handler);
};
WaveformShape.prototype.off = function(event, handler) {
  this._shape.off(event, handler);
};
WaveformShape.scaleY = function(amplitude, height, scale) {
  const y = -(height - 1) * (amplitude * scale + 128) / 255 + (height - 1);
  return clamp(Math.floor(y), 0, height - 1);
};
const defaultFontFamily = "sans-serif";
const defaultFontSize = 10;
const defaultFontShape = "normal";
function SegmentShape(segment, peaks, layer, view) {
  this._segment = segment;
  this._peaks = peaks;
  this._layer = layer;
  this._view = view;
  this._label = null;
  this._startMarker = null;
  this._endMarker = null;
  this._color = segment.color;
  this._borderColor = segment.borderColor;
  this._draggable = this._segment.editable && this._view.isSegmentDraggingEnabled();
  this._dragging = false;
  const segmentOptions2 = view.getViewOptions().segmentOptions;
  this._overlayOffset = segmentOptions2.overlayOffset;
  if (!segmentOptions2.overlay) {
    this._waveformShape = new WaveformShape({
      color: segment.color,
      view,
      segment
    });
  }
  this._onMouseEnter = this._onMouseEnter.bind(this);
  this._onMouseLeave = this._onMouseLeave.bind(this);
  this._onMouseDown = this._onMouseDown.bind(this);
  this._onMouseUp = this._onMouseUp.bind(this);
  this._dragBoundFunc = this._dragBoundFunc.bind(this);
  this._onSegmentDragStart = this._onSegmentDragStart.bind(this);
  this._onSegmentDragMove = this._onSegmentDragMove.bind(this);
  this._onSegmentDragEnd = this._onSegmentDragEnd.bind(this);
  this._onSegmentMarkerDragStart = this._onSegmentMarkerDragStart.bind(this);
  this._onSegmentMarkerDragMove = this._onSegmentMarkerDragMove.bind(this);
  this._onSegmentMarkerDragEnd = this._onSegmentMarkerDragEnd.bind(this);
  this._segmentMarkerDragBoundFunc = this._segmentMarkerDragBoundFunc.bind(this);
  this._label = this._peaks.options.createSegmentLabel({
    segment,
    view: this._view.getName(),
    layer: this._layer,
    fontFamily: this._peaks.options.fontFamily,
    fontSize: this._peaks.options.fontSize,
    fontStyle: this._peaks.options.fontStyle
  });
  if (this._label) {
    this._label.hide();
  }
  const segmentStartOffset = this._view.timeToPixelOffset(this._segment.startTime);
  const segmentEndOffset = this._view.timeToPixelOffset(this._segment.endTime);
  const overlayRectHeight = clamp(0, this._view.getHeight() - 2 * this._overlayOffset);
  this._overlay = new Konva.Group({
    name: "segment-overlay",
    segment: this._segment,
    x: segmentStartOffset,
    y: 0,
    width: segmentEndOffset - segmentStartOffset,
    height: this._view.getHeight(),
    clipX: 0,
    clipY: this._overlayOffset,
    clipWidth: segmentEndOffset - segmentStartOffset,
    clipHeight: overlayRectHeight,
    draggable: this._draggable,
    dragBoundFunc: this._dragBoundFunc
  });
  let overlayBorderColor, overlayBorderWidth, overlayColor, overlayOpacity, overlayCornerRadius;
  if (segmentOptions2.overlay) {
    overlayBorderColor = this._borderColor || segmentOptions2.overlayBorderColor;
    overlayBorderWidth = segmentOptions2.overlayBorderWidth;
    overlayColor = this._color || segmentOptions2.overlayColor;
    overlayOpacity = segmentOptions2.overlayOpacity;
    overlayCornerRadius = segmentOptions2.overlayCornerRadius;
  }
  this._overlayRect = new Konva.Rect({
    x: 0,
    y: this._overlayOffset,
    width: segmentEndOffset - segmentStartOffset,
    stroke: overlayBorderColor,
    strokeWidth: overlayBorderWidth,
    height: overlayRectHeight,
    fill: overlayColor,
    opacity: overlayOpacity,
    cornerRadius: overlayCornerRadius
  });
  this._overlay.add(this._overlayRect);
  if (segmentOptions2.overlay) {
    this._overlayText = new Konva.Text({
      x: 0,
      y: this._overlayOffset,
      text: this._segment.labelText,
      fontFamily: segmentOptions2.overlayFontFamily,
      fontSize: segmentOptions2.overlayFontSize,
      fontStyle: segmentOptions2.overlayFontStyle,
      fill: segmentOptions2.overlayLabelColor,
      listening: false,
      align: segmentOptions2.overlayLabelAlign,
      width: segmentEndOffset - segmentStartOffset,
      verticalAlign: segmentOptions2.overlayLabelVerticalAlign,
      height: overlayRectHeight,
      padding: segmentOptions2.overlayLabelPadding
    });
    this._overlay.add(this._overlayText);
  }
  this._overlay.on("mouseenter", this._onMouseEnter);
  this._overlay.on("mouseleave", this._onMouseLeave);
  this._overlay.on("mousedown", this._onMouseDown);
  this._overlay.on("mouseup", this._onMouseUp);
  if (this._draggable) {
    this._overlay.on("dragstart", this._onSegmentDragStart);
    this._overlay.on("dragmove", this._onSegmentDragMove);
    this._overlay.on("dragend", this._onSegmentDragEnd);
  }
  this._createMarkers();
}
function createOverlayMarker(options) {
  return new OverlaySegmentMarker(options);
}
SegmentShape.prototype._createMarkers = function() {
  const editable = this._layer.isEditingEnabled() && this._segment.editable;
  const segmentOptions2 = this._view.getViewOptions().segmentOptions;
  const createSegmentMarker2 = segmentOptions2.markers ? this._peaks.options.createSegmentMarker : createOverlayMarker;
  const startMarker = createSegmentMarker2({
    segment: this._segment,
    editable,
    startMarker: true,
    color: segmentOptions2.startMarkerColor,
    fontFamily: this._peaks.options.fontFamily || defaultFontFamily,
    fontSize: this._peaks.options.fontSize || defaultFontSize,
    fontStyle: this._peaks.options.fontStyle || defaultFontShape,
    layer: this._layer,
    view: this._view.getName(),
    segmentOptions: this._view.getViewOptions().segmentOptions
  });
  if (startMarker) {
    this._startMarker = new SegmentMarker({
      segment: this._segment,
      segmentShape: this,
      editable,
      startMarker: true,
      marker: startMarker,
      onDragStart: this._onSegmentMarkerDragStart,
      onDragMove: this._onSegmentMarkerDragMove,
      onDragEnd: this._onSegmentMarkerDragEnd,
      dragBoundFunc: this._segmentMarkerDragBoundFunc
    });
  }
  const endMarker = createSegmentMarker2({
    segment: this._segment,
    editable,
    startMarker: false,
    color: segmentOptions2.endMarkerColor,
    fontFamily: this._peaks.options.fontFamily || defaultFontFamily,
    fontSize: this._peaks.options.fontSize || defaultFontSize,
    fontStyle: this._peaks.options.fontStyle || defaultFontShape,
    layer: this._layer,
    view: this._view.getName(),
    segmentOptions: this._view.getViewOptions().segmentOptions
  });
  if (endMarker) {
    this._endMarker = new SegmentMarker({
      segment: this._segment,
      segmentShape: this,
      editable,
      startMarker: false,
      marker: endMarker,
      onDragStart: this._onSegmentMarkerDragStart,
      onDragMove: this._onSegmentMarkerDragMove,
      onDragEnd: this._onSegmentMarkerDragEnd,
      dragBoundFunc: this._segmentMarkerDragBoundFunc
    });
  }
};
SegmentShape.prototype._dragBoundFunc = function(pos) {
  return {
    x: pos.x,
    y: 0
  };
};
SegmentShape.prototype.update = function(options) {
  const segmentStartOffset = this._view.timeToPixelOffset(this._segment.startTime);
  const segmentEndOffset = this._view.timeToPixelOffset(this._segment.endTime);
  const width = segmentEndOffset - segmentStartOffset;
  let marker;
  if (marker = this.getStartMarker()) {
    marker.setX(segmentStartOffset - marker.getWidth());
    if (options) {
      marker.update(options);
    }
  }
  if (marker = this.getEndMarker()) {
    marker.setX(segmentEndOffset);
    if (options) {
      marker.update(options);
    }
  }
  this._color = this._segment.color;
  this._borderColor = this._segment.bordercolor;
  if (this._overlayText) {
    this._overlayText.text(this._segment.labelText);
  }
  const segmentOptions2 = this._view.getViewOptions().segmentOptions;
  if (segmentOptions2.overlay) {
    if (this._color) {
      this._overlayRect.fill(this._color);
    }
    if (this._borderColor) {
      this._overlayRect.stroke(this._borderColor);
    }
  } else {
    this._waveformShape.setWaveformColor(this._segment.color);
  }
  if (!this._dragging) {
    if (this._overlay) {
      this._overlay.setAttrs({
        x: segmentStartOffset,
        width,
        clipWidth: width < 1 ? 1 : width
      });
      this._overlayRect.setAttrs({
        x: 0,
        width
      });
      if (this._overlayText) {
        this._overlayText.setAttrs({
          width
        });
      }
    }
  }
};
SegmentShape.prototype.getSegment = function() {
  return this._segment;
};
SegmentShape.prototype.getStartMarker = function() {
  return this._startMarker;
};
SegmentShape.prototype.getEndMarker = function() {
  return this._endMarker;
};
SegmentShape.prototype.addToLayer = function(layer) {
  if (this._waveformShape) {
    this._waveformShape.addToLayer(layer);
  }
  if (this._label) {
    layer.add(this._label);
  }
  if (this._overlay) {
    layer.add(this._overlay);
  }
  if (this._startMarker) {
    this._startMarker.addToLayer(layer);
  }
  if (this._endMarker) {
    this._endMarker.addToLayer(layer);
  }
};
SegmentShape.prototype.isDragging = function() {
  return this._dragging;
};
SegmentShape.prototype._onMouseEnter = function(event) {
  if (this._label) {
    this._label.moveToTop();
    this._label.show();
  }
  this._peaks.emit("segments.mouseenter", {
    segment: this._segment,
    evt: event.evt
  });
};
SegmentShape.prototype._onMouseLeave = function(event) {
  if (this._label) {
    this._label.hide();
  }
  this._peaks.emit("segments.mouseleave", {
    segment: this._segment,
    evt: event.evt
  });
};
SegmentShape.prototype._onMouseDown = function(event) {
  this._peaks.emit("segments.mousedown", {
    segment: this._segment,
    evt: event.evt
  });
};
SegmentShape.prototype._onMouseUp = function(event) {
  this._peaks.emit("segments.mouseup", {
    segment: this._segment,
    evt: event.evt
  });
};
SegmentShape.prototype.segmentClicked = function(eventName, event) {
  this._moveToTop();
  this._peaks.emit("segments." + eventName, event);
};
SegmentShape.prototype._moveToTop = function() {
  this._overlay.moveToTop();
  this._layer.moveSegmentMarkersToTop();
};
SegmentShape.prototype.enableSegmentDragging = function(enable) {
  if (!this._segment.editable) {
    return;
  }
  if (!this._draggable && enable) {
    this._overlay.on("dragstart", this._onSegmentDragStart);
    this._overlay.on("dragmove", this._onSegmentDragMove);
    this._overlay.on("dragend", this._onSegmentDragEnd);
  } else if (this._draggable && !enable) {
    this._overlay.off("dragstart", this._onSegmentDragStart);
    this._overlay.off("dragmove", this._onSegmentDragMove);
    this._overlay.off("dragend", this._onSegmentDragEnd);
  }
  this._overlay.draggable(enable);
  this._draggable = enable;
};
SegmentShape.prototype._setPreviousAndNextSegments = function() {
  if (this._view.getSegmentDragMode() !== "overlap") {
    this._nextSegment = this._peaks.segments.findNextSegment(this._segment);
    this._previousSegment = this._peaks.segments.findPreviousSegment(this._segment);
  } else {
    this._nextSegment = null;
    this._previousSegment = null;
  }
};
SegmentShape.prototype._onSegmentDragStart = function(event) {
  this._setPreviousAndNextSegments();
  this._dragging = true;
  this._dragStartX = this._overlay.getX();
  this._dragStartTime = this._segment.startTime;
  this._dragEndTime = this._segment.endTime;
  this._peaks.emit("segments.dragstart", {
    segment: this._segment,
    marker: false,
    startMarker: false,
    evt: event.evt
  });
};
SegmentShape.prototype._onSegmentDragMove = function(event) {
  const x = this._overlay.getX();
  const offsetX = x - this._dragStartX;
  const timeOffset = this._view.pixelsToTime(offsetX);
  let startTime = this._dragStartTime + timeOffset;
  let endTime = this._dragEndTime + timeOffset;
  const segmentDuration = this._segment.endTime - this._segment.startTime;
  let dragMode;
  const minSegmentWidth = this._view.getMinSegmentDragWidth();
  const minSegmentDuration = this._view.pixelsToTime(minSegmentWidth);
  let previousSegmentUpdated = false;
  let nextSegmentUpdated = false;
  if (startTime < 0) {
    startTime = 0;
    endTime = segmentDuration;
    this._overlay.setX(this._view.timeToPixelOffset(startTime));
  }
  if (this._previousSegment) {
    let previousSegmentEndX = this._view.timeToPixelOffset(this._previousSegment.endTime);
    if (startTime < this._previousSegment.endTime) {
      dragMode = this._view.getSegmentDragMode();
      if (dragMode === "no-overlap" || dragMode === "compress" && !this._previousSegment.editable) {
        startTime = this._previousSegment.endTime;
        endTime = startTime + segmentDuration;
        this._overlay.setX(previousSegmentEndX);
      } else if (dragMode === "compress") {
        let previousSegmentEndTime = startTime;
        const minPreviousSegmentEndTime = this._previousSegment.startTime + minSegmentDuration;
        if (previousSegmentEndTime < minPreviousSegmentEndTime) {
          previousSegmentEndTime = minPreviousSegmentEndTime;
          previousSegmentEndX = this._view.timeToPixelOffset(previousSegmentEndTime);
          this._overlay.setX(previousSegmentEndX);
          startTime = previousSegmentEndTime;
          endTime = startTime + segmentDuration;
        }
        this._previousSegment.update({ endTime: previousSegmentEndTime });
        previousSegmentUpdated = true;
      }
    }
  }
  if (this._nextSegment) {
    let nextSegmentStartX = this._view.timeToPixelOffset(this._nextSegment.startTime);
    if (endTime > this._nextSegment.startTime) {
      dragMode = this._view.getSegmentDragMode();
      if (dragMode === "no-overlap" || dragMode === "compress" && !this._nextSegment.editable) {
        endTime = this._nextSegment.startTime;
        startTime = endTime - segmentDuration;
        this._overlay.setX(nextSegmentStartX - this._overlay.getWidth());
      } else if (dragMode === "compress") {
        let nextSegmentStartTime = endTime;
        const maxNextSegmentStartTime = this._nextSegment.endTime - minSegmentDuration;
        if (nextSegmentStartTime > maxNextSegmentStartTime) {
          nextSegmentStartTime = maxNextSegmentStartTime;
          nextSegmentStartX = this._view.timeToPixelOffset(nextSegmentStartTime);
          this._overlay.setX(nextSegmentStartX - this._overlay.getWidth());
          endTime = nextSegmentStartTime;
          startTime = endTime - segmentDuration;
        }
        this._nextSegment.update({ startTime: nextSegmentStartTime });
        nextSegmentUpdated = true;
      }
    }
  }
  this._segment._setStartTime(startTime);
  this._segment._setEndTime(endTime);
  this._peaks.emit("segments.dragged", {
    segment: this._segment,
    marker: false,
    startMarker: false,
    evt: event.evt
  });
  if (previousSegmentUpdated) {
    this._peaks.emit("segments.dragged", {
      segment: this._previousSegment,
      marker: false,
      startMarker: false,
      evt: event.evt
    });
  } else if (nextSegmentUpdated) {
    this._peaks.emit("segments.dragged", {
      segment: this._nextSegment,
      marker: false,
      startMarker: false,
      evt: event.evt
    });
  }
};
SegmentShape.prototype._onSegmentDragEnd = function(event) {
  this._dragging = false;
  this._peaks.emit("segments.dragend", {
    segment: this._segment,
    marker: false,
    startMarker: false,
    evt: event.evt
  });
};
SegmentShape.prototype.moveMarkersToTop = function() {
  if (this._startMarker) {
    this._startMarker.moveToTop();
  }
  if (this._endMarker) {
    this._endMarker.moveToTop();
  }
};
SegmentShape.prototype.startDrag = function() {
  if (this._endMarker) {
    this._endMarker.startDrag();
  }
};
SegmentShape.prototype.stopDrag = function() {
  if (this._endMarker) {
    this._endMarker.stopDrag();
  }
};
SegmentShape.prototype._onSegmentMarkerDragStart = function(segmentMarker, event) {
  this._setPreviousAndNextSegments();
  this._moveToTop();
  this._startMarkerX = this._startMarker.getX();
  this._endMarkerX = this._endMarker.getX();
  this._peaks.emit("segments.dragstart", {
    segment: this._segment,
    marker: true,
    startMarker: segmentMarker.isStartMarker(),
    evt: event.evt
  });
};
SegmentShape.prototype._onSegmentMarkerDragMove = function(segmentMarker, event) {
  if (segmentMarker.isStartMarker()) {
    this._segmentStartMarkerDragMove(segmentMarker, event);
    segmentMarker.update({ startTime: this._segment.startTime });
  } else {
    this._segmentEndMarkerDragMove(segmentMarker, event);
    segmentMarker.update({ endTime: this._segment.endTime });
  }
};
function getDuration(segment) {
  return segment.endTime - segment.startTime;
}
SegmentShape.prototype._segmentStartMarkerDragMove = function(segmentMarker, event) {
  const width = this._view.getWidth();
  let startMarkerX = this._startMarker.getX();
  const endMarkerX = this._endMarker.getX();
  let minSegmentDuration = this._view.pixelsToTime(50);
  const minSegmentWidth = this._view.getMinSegmentDragWidth();
  let upperLimit = this._endMarker.getX() - minSegmentWidth;
  if (upperLimit > width) {
    upperLimit = width;
  }
  let previousSegmentVisible = false;
  let previousSegmentUpdated = false;
  let previousSegmentEndX;
  if (this._previousSegment) {
    previousSegmentEndX = this._view.timeToPixelOffset(this._previousSegment.endTime);
    previousSegmentVisible = previousSegmentEndX >= 0;
  }
  if (startMarkerX > upperLimit) {
    segmentMarker.setX(upperLimit);
    this._overlay.clipWidth(upperLimit - endMarkerX);
    if (minSegmentWidth === 0 && upperLimit < width) {
      this._segment._setStartTime(this._segment.endTime);
    } else {
      this._segment._setStartTime(this._view.pixelOffsetToTime(upperLimit));
    }
  } else if (this._previousSegment && previousSegmentVisible) {
    const dragMode = this._view.getSegmentDragMode();
    const fixedPreviousSegment = dragMode === "no-overlap" || dragMode === "compress" && !this._previousSegment.editable;
    const compressPreviousSegment = dragMode === "compress" && this._previousSegment.editable;
    if (startMarkerX <= previousSegmentEndX) {
      if (fixedPreviousSegment) {
        segmentMarker.setX(previousSegmentEndX);
        this._overlay.clipWidth(previousSegmentEndX - endMarkerX);
        this._segment._setStartTime(this._previousSegment.endTime);
      } else if (compressPreviousSegment) {
        const previousSegmentDuration = getDuration(this._previousSegment);
        if (previousSegmentDuration < minSegmentDuration) {
          minSegmentDuration = previousSegmentDuration;
        }
        const lowerLimit = this._view.timeToPixelOffset(
          this._previousSegment.startTime + minSegmentDuration
        );
        if (startMarkerX < lowerLimit) {
          startMarkerX = lowerLimit;
        }
        segmentMarker.setX(startMarkerX);
        this._overlay.clipWidth(endMarkerX - startMarkerX);
        this._segment._setStartTime(this._view.pixelOffsetToTime(startMarkerX));
        this._previousSegment.update({
          endTime: this._view.pixelOffsetToTime(startMarkerX)
        });
        previousSegmentUpdated = true;
      }
    } else {
      if (startMarkerX < 0) {
        startMarkerX = 0;
      }
      segmentMarker.setX(startMarkerX);
      this._overlay.clipWidth(endMarkerX - startMarkerX);
      this._segment._setStartTime(this._view.pixelOffsetToTime(startMarkerX));
    }
  } else {
    if (startMarkerX < 0) {
      startMarkerX = 0;
    }
    segmentMarker.setX(startMarkerX);
    this._overlay.clipWidth(endMarkerX - startMarkerX);
    this._segment._setStartTime(this._view.pixelOffsetToTime(startMarkerX));
  }
  this._peaks.emit("segments.dragged", {
    segment: this._segment,
    marker: true,
    startMarker: true,
    evt: event.evt
  });
  if (previousSegmentUpdated) {
    this._peaks.emit("segments.dragged", {
      segment: this._previousSegment,
      marker: true,
      startMarker: false,
      evt: event.evt
    });
  }
};
SegmentShape.prototype._segmentEndMarkerDragMove = function(segmentMarker, event) {
  const width = this._view.getWidth();
  const startMarkerX = this._startMarker.getX();
  let endMarkerX = this._endMarker.getX();
  let minSegmentDuration = this._view.pixelsToTime(50);
  const minSegmentWidth = this._view.getMinSegmentDragWidth();
  let lowerLimit = this._startMarker.getX() + minSegmentWidth;
  if (lowerLimit < 0) {
    lowerLimit = 0;
  }
  let nextSegmentVisible = false;
  let nextSegmentUpdated = false;
  let nextSegmentStartX;
  if (this._nextSegment) {
    nextSegmentStartX = this._view.timeToPixelOffset(this._nextSegment.startTime);
    nextSegmentVisible = nextSegmentStartX < width;
  }
  if (endMarkerX < lowerLimit) {
    segmentMarker.setX(lowerLimit);
    this._overlay.clipWidth(lowerLimit - startMarkerX);
    if (minSegmentWidth === 0 && lowerLimit > 0) {
      this._segment._setEndTime(this._segment.startTime);
    } else {
      this._segment._setEndTime(this._view.pixelOffsetToTime(lowerLimit));
    }
  } else if (this._nextSegment && nextSegmentVisible) {
    const dragMode = this._view.getSegmentDragMode();
    const fixedNextSegment = dragMode === "no-overlap" || dragMode === "compress" && !this._nextSegment.editable;
    const compressNextSegment = dragMode === "compress" && this._nextSegment.editable;
    if (endMarkerX >= nextSegmentStartX) {
      if (fixedNextSegment) {
        segmentMarker.setX(nextSegmentStartX);
        this._overlay.clipWidth(nextSegmentStartX - startMarkerX);
        this._segment._setEndTime(this._nextSegment.startTime);
      } else if (compressNextSegment) {
        const nextSegmentDuration = getDuration(this._nextSegment);
        if (nextSegmentDuration < minSegmentDuration) {
          minSegmentDuration = nextSegmentDuration;
        }
        const upperLimit = this._view.timeToPixelOffset(
          this._nextSegment.endTime - minSegmentDuration
        );
        if (endMarkerX > upperLimit) {
          endMarkerX = upperLimit;
        }
        segmentMarker.setX(endMarkerX);
        this._overlay.clipWidth(endMarkerX - startMarkerX);
        this._segment._setEndTime(this._view.pixelOffsetToTime(endMarkerX));
        this._nextSegment.update({
          startTime: this._view.pixelOffsetToTime(endMarkerX)
        });
        nextSegmentUpdated = true;
      }
    } else {
      if (endMarkerX > width) {
        endMarkerX = width;
      }
      segmentMarker.setX(endMarkerX);
      this._overlay.clipWidth(endMarkerX - startMarkerX);
      this._segment._setEndTime(this._view.pixelOffsetToTime(endMarkerX));
    }
  } else {
    if (endMarkerX > width) {
      endMarkerX = width;
    }
    segmentMarker.setX(endMarkerX);
    this._overlay.clipWidth(endMarkerX - startMarkerX);
    this._segment._setEndTime(this._view.pixelOffsetToTime(endMarkerX));
  }
  this._peaks.emit("segments.dragged", {
    segment: this._segment,
    marker: true,
    startMarker: false,
    evt: event.evt
  });
  if (nextSegmentUpdated) {
    this._peaks.emit("segments.dragged", {
      segment: this._nextSegment,
      marker: true,
      startMarker: true,
      evt: event.evt
    });
  }
};
SegmentShape.prototype._onSegmentMarkerDragEnd = function(segmentMarker, event) {
  this._nextSegment = null;
  this._previousSegment = null;
  const startMarker = segmentMarker.isStartMarker();
  this._peaks.emit("segments.dragend", {
    segment: this._segment,
    marker: true,
    startMarker,
    evt: event.evt
  });
};
SegmentShape.prototype._segmentMarkerDragBoundFunc = function(segmentMarker, pos) {
  return {
    x: pos.x,
    y: segmentMarker.getAbsolutePosition().y
  };
};
SegmentShape.prototype.fitToView = function() {
  if (this._startMarker) {
    this._startMarker.fitToView();
  }
  if (this._endMarker) {
    this._endMarker.fitToView();
  }
  if (this._overlay) {
    const height = this._view.getHeight();
    const overlayRectHeight = clamp(0, height - this._overlayOffset * 2);
    this._overlay.setAttrs({
      y: 0,
      height,
      clipY: this._overlayOffset,
      clipHeight: overlayRectHeight
    });
    this._overlayRect.setAttrs({
      y: this._overlayOffset,
      height: overlayRectHeight
    });
    if (this._overlayText) {
      this._overlayText.setAttrs({
        y: this._overlayOffset,
        height: overlayRectHeight
      });
    }
  }
};
SegmentShape.prototype.destroy = function() {
  if (this._waveformShape) {
    this._waveformShape.destroy();
  }
  if (this._label) {
    this._label.destroy();
  }
  if (this._startMarker) {
    this._startMarker.destroy();
  }
  if (this._endMarker) {
    this._endMarker.destroy();
  }
  if (this._overlay) {
    this._overlay.destroy();
  }
};
function SegmentsLayer(peaks, view, allowEditing) {
  this._peaks = peaks;
  this._view = view;
  this._allowEditing = allowEditing;
  this._segmentShapes = {};
  this._layer = new Konva.Layer();
  this._onSegmentsUpdate = this._onSegmentsUpdate.bind(this);
  this._onSegmentsAdd = this._onSegmentsAdd.bind(this);
  this._onSegmentsRemove = this._onSegmentsRemove.bind(this);
  this._onSegmentsRemoveAll = this._onSegmentsRemoveAll.bind(this);
  this._onSegmentsDragged = this._onSegmentsDragged.bind(this);
  this._peaks.on("segments.update", this._onSegmentsUpdate);
  this._peaks.on("segments.add", this._onSegmentsAdd);
  this._peaks.on("segments.remove", this._onSegmentsRemove);
  this._peaks.on("segments.remove_all", this._onSegmentsRemoveAll);
  this._peaks.on("segments.dragged", this._onSegmentsDragged);
}
SegmentsLayer.prototype.addToStage = function(stage) {
  stage.add(this._layer);
};
SegmentsLayer.prototype.enableEditing = function(enable) {
  this._allowEditing = enable;
};
SegmentsLayer.prototype.isEditingEnabled = function() {
  return this._allowEditing;
};
SegmentsLayer.prototype.enableSegmentDragging = function(enable) {
  for (const segmentPid in this._segmentShapes) {
    if (objectHasProperty(this._segmentShapes, segmentPid)) {
      this._segmentShapes[segmentPid].enableSegmentDragging(enable);
    }
  }
};
SegmentsLayer.prototype.getSegmentShape = function(segment) {
  return this._segmentShapes[segment.pid];
};
SegmentsLayer.prototype.formatTime = function(time) {
  return this._view.formatTime(time);
};
SegmentsLayer.prototype._onSegmentsUpdate = function(segment, options) {
  const frameStartTime = this._view.getStartTime();
  const frameEndTime = this._view.getEndTime();
  let segmentShape = this.getSegmentShape(segment);
  const isVisible = segment.isVisible(frameStartTime, frameEndTime);
  if (segmentShape && !isVisible) {
    if (!segmentShape.isDragging()) {
      this._removeSegment(segment);
    }
  } else if (!segmentShape && isVisible) {
    segmentShape = this._updateSegment(segment);
  } else if (segmentShape && isVisible) {
    segmentShape.update(options);
  }
};
SegmentsLayer.prototype._onSegmentsAdd = function(event) {
  const self = this;
  const frameStartTime = self._view.getStartTime();
  const frameEndTime = self._view.getEndTime();
  event.segments.forEach(function(segment) {
    if (segment.isVisible(frameStartTime, frameEndTime)) {
      const segmentShape = self._addSegmentShape(segment);
      segmentShape.update();
    }
  });
  this.moveSegmentMarkersToTop();
};
SegmentsLayer.prototype._onSegmentsRemove = function(event) {
  const self = this;
  event.segments.forEach(function(segment) {
    self._removeSegment(segment);
  });
};
SegmentsLayer.prototype._onSegmentsRemoveAll = function() {
  this._layer.removeChildren();
  this._segmentShapes = {};
};
SegmentsLayer.prototype._onSegmentsDragged = function(event) {
  this._updateSegment(event.segment);
};
SegmentsLayer.prototype._createSegmentShape = function(segment) {
  return new SegmentShape(segment, this._peaks, this, this._view);
};
SegmentsLayer.prototype._addSegmentShape = function(segment) {
  const segmentShape = this._createSegmentShape(segment);
  segmentShape.addToLayer(this._layer);
  this._segmentShapes[segment.pid] = segmentShape;
  return segmentShape;
};
SegmentsLayer.prototype.updateSegments = function(startTime, endTime) {
  const segments = this._peaks.segments.find(startTime, endTime);
  segments.forEach(this._updateSegment.bind(this));
  this._removeInvisibleSegments(startTime, endTime);
};
SegmentsLayer.prototype._updateSegment = function(segment) {
  let segmentShape = this.getSegmentShape(segment);
  if (!segmentShape) {
    segmentShape = this._addSegmentShape(segment);
  }
  segmentShape.update();
};
SegmentsLayer.prototype._removeInvisibleSegments = function(startTime, endTime) {
  for (const segmentPid in this._segmentShapes) {
    if (objectHasProperty(this._segmentShapes, segmentPid)) {
      const segment = this._segmentShapes[segmentPid].getSegment();
      if (!segment.isVisible(startTime, endTime)) {
        this._removeSegment(segment);
      }
    }
  }
};
SegmentsLayer.prototype._removeSegment = function(segment) {
  const segmentShape = this._segmentShapes[segment.pid];
  if (segmentShape) {
    segmentShape.destroy();
    delete this._segmentShapes[segment.pid];
  }
};
SegmentsLayer.prototype.moveSegmentMarkersToTop = function() {
  for (const segmentPid in this._segmentShapes) {
    if (objectHasProperty(this._segmentShapes, segmentPid)) {
      this._segmentShapes[segmentPid].moveMarkersToTop();
    }
  }
};
SegmentsLayer.prototype.setVisible = function(visible) {
  this._layer.setVisible(visible);
};
SegmentsLayer.prototype.segmentClicked = function(eventName, event) {
  const segmentShape = this._segmentShapes[event.segment.pid];
  if (segmentShape) {
    segmentShape.segmentClicked(eventName, event);
  }
};
SegmentsLayer.prototype.destroy = function() {
  this._peaks.off("segments.update", this._onSegmentsUpdate);
  this._peaks.off("segments.add", this._onSegmentsAdd);
  this._peaks.off("segments.remove", this._onSegmentsRemove);
  this._peaks.off("segments.remove_all", this._onSegmentsRemoveAll);
  this._peaks.off("segments.dragged", this._onSegmentsDragged);
};
SegmentsLayer.prototype.fitToView = function() {
  for (const segmentPid in this._segmentShapes) {
    if (objectHasProperty(this._segmentShapes, segmentPid)) {
      const segmentShape = this._segmentShapes[segmentPid];
      segmentShape.fitToView();
    }
  }
};
SegmentsLayer.prototype.draw = function() {
  this._layer.draw();
};
SegmentsLayer.prototype.getHeight = function() {
  return this._layer.getHeight();
};
function WaveformAxis(view, options) {
  const self = this;
  self._axisGridlineColor = options.axisGridlineColor;
  self._axisLabelColor = options.axisLabelColor;
  self._showAxisLabels = options.showAxisLabels;
  if (options.formatAxisTime) {
    self._formatAxisTime = options.formatAxisTime;
  } else {
    self._formatAxisTime = function(time) {
      return formatTime(time, 0);
    };
  }
  self._axisLabelFont = WaveformAxis._buildFontString(
    options.fontFamily,
    options.fontSize,
    options.fontStyle
  );
  self._axisShape = new Konva.Shape({
    sceneFunc: function(context) {
      self._drawAxis(context, view);
    }
  });
}
WaveformAxis._buildFontString = function(fontFamily, fontSize, fontStyle) {
  if (!fontSize) {
    fontSize = 11;
  }
  if (!fontFamily) {
    fontFamily = "sans-serif";
  }
  if (!fontStyle) {
    fontStyle = "normal";
  }
  return fontStyle + " " + fontSize + "px " + fontFamily;
};
WaveformAxis.prototype.addToLayer = function(layer) {
  layer.add(this._axisShape);
};
WaveformAxis.prototype.showAxisLabels = function(show) {
  this._showAxisLabels = show;
};
WaveformAxis.prototype._getAxisLabelScale = function(view) {
  let baseSecs = 1;
  const steps = [1, 2, 5, 10, 20, 30];
  const minSpacing = 60;
  let index = 0;
  let secs;
  for (; ; ) {
    secs = baseSecs * steps[index];
    const pixels = view.timeToPixels(secs);
    if (pixels < minSpacing) {
      if (++index === steps.length) {
        baseSecs *= 60;
        index = 0;
      }
    } else {
      break;
    }
  }
  return secs;
};
WaveformAxis.prototype._drawAxis = function(context, view) {
  const currentFrameStartTime = view.getStartTime();
  const markerHeight = 10;
  const axisLabelIntervalSecs = this._getAxisLabelScale(view);
  const firstAxisLabelSecs = roundUpToNearest(currentFrameStartTime, axisLabelIntervalSecs);
  const axisLabelOffsetSecs = firstAxisLabelSecs - currentFrameStartTime;
  const axisLabelOffsetPixels = view.timeToPixels(axisLabelOffsetSecs);
  context.setAttr("strokeStyle", this._axisGridlineColor);
  context.setAttr("lineWidth", 1);
  context.setAttr("font", this._axisLabelFont);
  context.setAttr("fillStyle", this._axisLabelColor);
  context.setAttr("textAlign", "left");
  context.setAttr("textBaseline", "bottom");
  const width = view.getWidth();
  const height = view.getHeight();
  let secs = firstAxisLabelSecs;
  for (; ; ) {
    const x = axisLabelOffsetPixels + view.timeToPixels(secs - firstAxisLabelSecs);
    if (x >= width) {
      break;
    }
    context.beginPath();
    context.moveTo(x + 0.5, 0);
    context.lineTo(x + 0.5, 0 + markerHeight);
    context.moveTo(x + 0.5, height);
    context.lineTo(x + 0.5, height - markerHeight);
    context.stroke();
    if (this._showAxisLabels) {
      const label = this._formatAxisTime(secs);
      const labelWidth = context.measureText(label).width;
      const labelX = x - labelWidth / 2;
      const labelY = height - 1 - markerHeight;
      if (labelX >= 0) {
        context.fillText(label, labelX, labelY);
      }
    }
    secs += axisLabelIntervalSecs;
  }
};
function MouseDragHandler(stage, handlers) {
  this._stage = stage;
  this._handlers = handlers;
  this._dragging = false;
  this._mouseDown = this._mouseDown.bind(this);
  this._mouseUp = this._mouseUp.bind(this);
  this._mouseMove = this._mouseMove.bind(this);
  this._stage.on("mousedown", this._mouseDown);
  this._stage.on("touchstart", this._mouseDown);
  this._lastMouseClientX = null;
}
MouseDragHandler.prototype._mouseDown = function(event) {
  let segment = null;
  if (event.type === "mousedown" && event.evt.button !== 0) {
    return;
  }
  const marker = getMarkerObject(event.target);
  if (marker && marker.attrs.draggable) {
    if (marker.attrs.name === "point-marker" || marker.attrs.name === "segment-marker") {
      return;
    }
    if (marker.attrs.name === "segment-overlay") {
      segment = marker;
    }
  }
  this._lastMouseClientX = Math.floor(
    event.type === "touchstart" ? event.evt.touches[0].clientX : event.evt.clientX
  );
  if (this._handlers.onMouseDown) {
    const mouseDownPosX = this._getMousePosX(this._lastMouseClientX);
    this._handlers.onMouseDown(mouseDownPosX, segment);
  }
  window.addEventListener("mousemove", this._mouseMove, { capture: false, passive: true });
  window.addEventListener("touchmove", this._mouseMove, { capture: false, passive: true });
  window.addEventListener("mouseup", this._mouseUp, { capture: false, passive: true });
  window.addEventListener("touchend", this._mouseUp, { capture: false, passive: true });
  window.addEventListener("blur", this._mouseUp, { capture: false, passive: true });
};
MouseDragHandler.prototype._mouseMove = function(event) {
  const clientX = Math.floor(
    event.type === "touchmove" ? event.changedTouches[0].clientX : event.clientX
  );
  if (clientX === this._lastMouseClientX) {
    return;
  }
  this._lastMouseClientX = clientX;
  this._dragging = true;
  if (this._handlers.onMouseMove) {
    const mousePosX = this._getMousePosX(clientX);
    this._handlers.onMouseMove(mousePosX);
  }
};
MouseDragHandler.prototype._mouseUp = function(event) {
  let clientX;
  if (event.type === "touchend") {
    clientX = Math.floor(event.changedTouches[0].clientX);
    if (event.cancelable) {
      event.preventDefault();
    }
  } else {
    clientX = Math.floor(event.clientX);
  }
  if (this._handlers.onMouseUp) {
    const mousePosX = this._getMousePosX(clientX);
    this._handlers.onMouseUp(mousePosX);
  }
  window.removeEventListener("mousemove", this._mouseMove, { capture: false });
  window.removeEventListener("touchmove", this._mouseMove, { capture: false });
  window.removeEventListener("mouseup", this._mouseUp, { capture: false });
  window.removeEventListener("touchend", this._mouseUp, { capture: false });
  window.removeEventListener("blur", this._mouseUp, { capture: false });
  this._dragging = false;
};
MouseDragHandler.prototype._getMousePosX = function(clientX) {
  const containerPos = this._stage.getContainer().getBoundingClientRect();
  return clientX - containerPos.left;
};
MouseDragHandler.prototype.isDragging = function() {
  return this._dragging;
};
MouseDragHandler.prototype.destroy = function() {
  this._stage.off("mousedown", this._mouseDown);
  this._stage.off("touchstart", this._mouseDown);
};
function SeekMouseDragHandler(peaks, view) {
  this._peaks = peaks;
  this._view = view;
  this._onMouseDown = this._onMouseDown.bind(this);
  this._onMouseMove = this._onMouseMove.bind(this);
  this._mouseDragHandler = new MouseDragHandler(view._stage, {
    onMouseDown: this._onMouseDown,
    onMouseMove: this._onMouseMove
  });
}
SeekMouseDragHandler.prototype._onMouseDown = function(mousePosX) {
  this._seek(mousePosX);
};
SeekMouseDragHandler.prototype._onMouseMove = function(mousePosX) {
  this._seek(mousePosX);
};
SeekMouseDragHandler.prototype._seek = function(mousePosX) {
  if (!this._view.isSeekEnabled()) {
    return;
  }
  mousePosX = clamp(mousePosX, 0, this._width);
  let time = this._view.pixelsToTime(mousePosX);
  const duration = this._peaks.player.getDuration();
  if (time > duration) {
    time = duration;
  }
  this._view.updatePlayheadTime(time);
  this._peaks.player.seek(time);
};
SeekMouseDragHandler.prototype.destroy = function() {
  this._mouseDragHandler.destroy();
};
function WaveformOverview(waveformData, container, peaks) {
  const self = this;
  self._originalWaveformData = waveformData;
  self._container = container;
  self._peaks = peaks;
  self._options = peaks.options;
  self._viewOptions = peaks.options.overview;
  self._onTimeUpdate = self._onTimeUpdate.bind(this);
  self._onPlaying = self._onPlaying.bind(this);
  self._onPause = self._onPause.bind(this);
  self._onZoomviewDisplaying = self._onZoomviewDisplaying.bind(this);
  self._onWindowResize = self._onWindowResize.bind(this);
  peaks.on("player.timeupdate", self._onTimeUpdate);
  peaks.on("player.playing", self._onPlaying);
  peaks.on("player.pause", self._onPause);
  peaks.on("zoomview.displaying", self._onZoomviewDisplaying);
  peaks.on("window_resize", self._onWindowResize);
  self._amplitudeScale = 1;
  self._timeLabelPrecision = self._viewOptions.timeLabelPrecision;
  self._enableSeek = true;
  if (self._viewOptions.formatPlayheadTime) {
    self._formatPlayheadTime = self._viewOptions.formatPlayheadTime;
  } else {
    self._formatPlayheadTime = function(time2) {
      return formatTime(time2, self._timeLabelPrecision);
    };
  }
  self._width = container.clientWidth;
  self._height = container.clientHeight;
  self._data = waveformData;
  if (self._width !== 0) {
    self._resampleAndSetWaveformData(waveformData, self._width);
  }
  Konva.showWarnings = false;
  self._resizeTimeoutId = null;
  self._stage = new Konva.Stage({
    container,
    width: self._width,
    height: self._height
  });
  self._waveformColor = self._viewOptions.waveformColor;
  self._playedWaveformColor = self._viewOptions.playedWaveformColor;
  self._createWaveform();
  if (self._viewOptions.enableSegments) {
    self._segmentsLayer = new SegmentsLayer(peaks, self, false);
    self._segmentsLayer.addToStage(self._stage);
  }
  if (self._viewOptions.enablePoints) {
    self._pointsLayer = new PointsLayer(peaks, self, false);
    self._pointsLayer.addToStage(self._stage);
  }
  self._highlightLayer = new HighlightLayer(
    self,
    self._viewOptions
  );
  self._highlightLayer.addToStage(self._stage);
  self._createAxisLabels();
  self._playheadLayer = new PlayheadLayer({
    player: self._peaks.player,
    view: self,
    showPlayheadTime: self._viewOptions.showPlayheadTime,
    playheadColor: self._viewOptions.playheadColor,
    playheadTextColor: self._viewOptions.playheadTextColor,
    playheadFontFamily: self._viewOptions.fontFamily,
    playheadFontSize: self._viewOptions.fontSize,
    playheadFontStyle: self._viewOptions.fontStyle
  });
  self._playheadLayer.addToStage(self._stage);
  const time = self._peaks.player.getCurrentTime();
  self._playheadLayer.updatePlayheadTime(time);
  self._mouseDragHandler = new SeekMouseDragHandler(peaks, self);
  self._onClick = self._onClick.bind(self);
  self._onDblClick = self._onDblClick.bind(self);
  self._onContextMenu = self._onContextMenu.bind(self);
  self._stage.on("click", self._onClick);
  self._stage.on("dblclick", self._onDblClick);
  self._stage.on("contextmenu", self._onContextMenu);
}
WaveformOverview.prototype.enableSeek = function(enable) {
  this._enableSeek = enable;
};
WaveformOverview.prototype.isSeekEnabled = function() {
  return this._enableSeek;
};
WaveformOverview.prototype._onClick = function(event) {
  this._clickHandler(event, "click");
};
WaveformOverview.prototype._onDblClick = function(event) {
  this._clickHandler(event, "dblclick");
};
WaveformOverview.prototype._onContextMenu = function(event) {
  this._clickHandler(event, "contextmenu");
};
WaveformOverview.prototype._clickHandler = function(event, eventName) {
  let emitViewEvent = true;
  if (event.target !== this._stage) {
    const marker = getMarkerObject(event.target);
    if (marker) {
      if (marker.attrs.name === "point-marker") {
        const point = marker.getAttr("point");
        if (point) {
          this._peaks.emit("points." + eventName, {
            point,
            evt: event.evt,
            preventViewEvent: function() {
              emitViewEvent = false;
            }
          });
        }
      } else if (marker.attrs.name === "segment-overlay") {
        const segment = marker.getAttr("segment");
        if (segment) {
          const clickEvent = {
            segment,
            evt: event.evt,
            preventViewEvent: function() {
              emitViewEvent = false;
            }
          };
          if (this._segmentsLayer) {
            this._segmentsLayer.segmentClicked(eventName, clickEvent);
          }
        }
      }
    }
  }
  if (emitViewEvent) {
    const pixelIndex = event.evt.layerX;
    const time = this.pixelsToTime(pixelIndex);
    this._peaks.emit("overview." + eventName, {
      time,
      evt: event.evt
    });
  }
};
WaveformOverview.prototype.isSegmentDraggingEnabled = function() {
  return false;
};
WaveformOverview.prototype.getName = function() {
  return "overview";
};
WaveformOverview.prototype._onTimeUpdate = function(time) {
  this._playheadLayer.updatePlayheadTime(time);
};
WaveformOverview.prototype._onPlaying = function(time) {
  this._playheadLayer.updatePlayheadTime(time);
};
WaveformOverview.prototype._onPause = function(time) {
  this._playheadLayer.stop(time);
};
WaveformOverview.prototype._onZoomviewDisplaying = function(startTime, endTime) {
  this.showHighlight(startTime, endTime);
};
WaveformOverview.prototype.updatePlayheadTime = function(time) {
  this._playheadLayer.updatePlayheadTime(time);
};
WaveformOverview.prototype.showHighlight = function(startTime, endTime) {
  this._highlightLayer.showHighlight(startTime, endTime);
};
WaveformOverview.prototype._onWindowResize = function() {
  const self = this;
  if (self._resizeTimeoutId) {
    clearTimeout(self._resizeTimeoutId);
    self._resizeTimeoutId = null;
  }
  if (self._container.clientWidth !== 0) {
    self._width = self._container.clientWidth;
    self._stage.setWidth(self._width);
    self._resizeTimeoutId = setTimeout(function() {
      self._width = self._container.clientWidth;
      self._resampleAndSetWaveformData(self._originalWaveformData, self._width);
      self._stage.setWidth(self._width);
      self._updateWaveform();
    }, 500);
  }
};
WaveformOverview.prototype.setWaveformData = function(waveformData) {
  this._originalWaveformData = waveformData;
  if (this._width !== 0) {
    this._resampleAndSetWaveformData(waveformData, this._width);
  } else {
    this._data = waveformData;
  }
  this._updateWaveform();
};
WaveformOverview.prototype._resampleAndSetWaveformData = function(waveformData, width) {
  try {
    this._data = waveformData.resample({ width });
    return true;
  } catch (error) {
    this._data = waveformData;
    return false;
  }
};
WaveformOverview.prototype.playheadPosChanged = function(time) {
  if (this._playedWaveformShape) {
    this._playedSegment.endTime = time;
    this._unplayedSegment.startTime = time;
    this._waveformLayer.draw();
  }
};
WaveformOverview.prototype.timeToPixels = function(time) {
  return Math.floor(time * this._data.sample_rate / this._data.scale);
};
WaveformOverview.prototype.timeToPixelOffset = WaveformOverview.prototype.timeToPixels;
WaveformOverview.prototype.pixelsToTime = function(pixels) {
  return pixels * this._data.scale / this._data.sample_rate;
};
WaveformOverview.prototype.pixelOffsetToTime = WaveformOverview.prototype.pixelsToTime;
WaveformOverview.prototype.getFrameOffset = function() {
  return 0;
};
WaveformOverview.prototype.getStartTime = function() {
  return 0;
};
WaveformOverview.prototype.getEndTime = function() {
  return this._getDuration();
};
WaveformOverview.prototype.getWidth = function() {
  return this._width;
};
WaveformOverview.prototype.getHeight = function() {
  return this._height;
};
WaveformOverview.prototype._getDuration = function() {
  return this._peaks.player.getDuration();
};
WaveformOverview.prototype.setAmplitudeScale = function(scale) {
  if (!isNumber(scale) || !isFinite(scale)) {
    throw new Error("view.setAmplitudeScale(): Scale must be a valid number");
  }
  this._amplitudeScale = scale;
  this._waveformLayer.draw();
  if (this._segmentsLayer) {
    this._segmentsLayer.draw();
  }
};
WaveformOverview.prototype.getAmplitudeScale = function() {
  return this._amplitudeScale;
};
WaveformOverview.prototype.getWaveformData = function() {
  return this._data;
};
WaveformOverview.prototype._createWaveformShapes = function() {
  if (!this._waveformShape) {
    this._waveformShape = new WaveformShape({
      color: this._waveformColor,
      view: this
    });
    this._waveformShape.addToLayer(this._waveformLayer);
  }
  if (this._playedWaveformColor && !this._playedWaveformShape) {
    const time = this._peaks.player.getCurrentTime();
    this._playedSegment = {
      startTime: 0,
      endTime: time
    };
    this._unplayedSegment = {
      startTime: time,
      endTime: this._getDuration()
    };
    this._waveformShape.setSegment(this._unplayedSegment);
    this._playedWaveformShape = new WaveformShape({
      color: this._playedWaveformColor,
      view: this,
      segment: this._playedSegment
    });
    this._playedWaveformShape.addToLayer(this._waveformLayer);
  }
};
WaveformOverview.prototype._destroyPlayedWaveformShape = function() {
  this._waveformShape.setSegment(null);
  this._playedWaveformShape.destroy();
  this._playedWaveformShape = null;
  this._playedSegment = null;
  this._unplayedSegment = null;
};
WaveformOverview.prototype._createWaveform = function() {
  this._waveformLayer = new Konva.Layer({ listening: false });
  this._createWaveformShapes();
  this._stage.add(this._waveformLayer);
};
WaveformOverview.prototype._createAxisLabels = function() {
  this._axisLayer = new Konva.Layer({ listening: false });
  this._axis = new WaveformAxis(this, this._viewOptions);
  this._axis.addToLayer(this._axisLayer);
  this._stage.add(this._axisLayer);
};
WaveformOverview.prototype.removeHighlightRect = function() {
  this._highlightLayer.removeHighlight();
};
WaveformOverview.prototype._updateWaveform = function() {
  this._waveformLayer.draw();
  this._axisLayer.draw();
  const playheadTime = this._peaks.player.getCurrentTime();
  this._playheadLayer.updatePlayheadTime(playheadTime);
  this._highlightLayer.updateHighlight();
  const frameStartTime = 0;
  const frameEndTime = this.pixelsToTime(this._width);
  if (this._pointsLayer) {
    this._pointsLayer.updatePoints(frameStartTime, frameEndTime);
  }
  if (this._segmentsLayer) {
    this._segmentsLayer.updateSegments(frameStartTime, frameEndTime);
  }
};
WaveformOverview.prototype.setWaveformColor = function(color) {
  this._waveformColor = color;
  this._waveformShape.setWaveformColor(color);
};
WaveformOverview.prototype.setPlayedWaveformColor = function(color) {
  this._playedWaveformColor = color;
  if (color) {
    if (!this._playedWaveformShape) {
      this._createWaveformShapes();
    }
    this._playedWaveformShape.setWaveformColor(color);
  } else {
    if (this._playedWaveformShape) {
      this._destroyPlayedWaveformShape();
    }
  }
};
WaveformOverview.prototype.showPlayheadTime = function(show) {
  this._playheadLayer.showPlayheadTime(show);
};
WaveformOverview.prototype.setTimeLabelPrecision = function(precision) {
  this._timeLabelPrecision = precision;
  this._playheadLayer.updatePlayheadText();
};
WaveformOverview.prototype.formatTime = function(time) {
  return this._formatPlayheadTime(time);
};
WaveformOverview.prototype.showAxisLabels = function(show) {
  this._axis.showAxisLabels(show);
  this._axisLayer.draw();
};
WaveformOverview.prototype.enableMarkerEditing = function(enable) {
  if (this._segmentsLayer) {
    this._segmentsLayer.enableEditing(enable);
  }
  if (this._pointsLayer) {
    this._pointsLayer.enableEditing(enable);
  }
};
WaveformOverview.prototype.fitToContainer = function() {
  if (this._container.clientWidth === 0 && this._container.clientHeight === 0) {
    return;
  }
  let updateWaveform = false;
  if (this._container.clientWidth !== this._width) {
    this._width = this._container.clientWidth;
    this._stage.setWidth(this._width);
    if (this._resampleAndSetWaveformData(this._originalWaveformData, this._width)) {
      updateWaveform = true;
    }
  }
  this._height = this._container.clientHeight;
  this._stage.setHeight(this._height);
  this._waveformShape.fitToView();
  this._playheadLayer.fitToView();
  if (this._segmentsLayer) {
    this._segmentsLayer.fitToView();
  }
  if (this._pointsLayer) {
    this._pointsLayer.fitToView();
  }
  this._highlightLayer.fitToView();
  if (updateWaveform) {
    this._updateWaveform();
  }
  this._stage.draw();
};
WaveformOverview.prototype.getViewOptions = function() {
  return this._viewOptions;
};
WaveformOverview.prototype.destroy = function() {
  if (this._resizeTimeoutId) {
    clearTimeout(this._resizeTimeoutId);
    this._resizeTimeoutId = null;
  }
  this._peaks.off("player.playing", this._onPlaying);
  this._peaks.off("player.pause", this._onPause);
  this._peaks.off("player.timeupdate", this._onTimeUpdate);
  this._peaks.off("zoomview.displaying", this._onZoomviewDisplaying);
  this._peaks.off("window_resize", this._onWindowResize);
  this._mouseDragHandler.destroy();
  this._playheadLayer.destroy();
  if (this._segmentsLayer) {
    this._segmentsLayer.destroy();
  }
  if (this._pointsLayer) {
    this._pointsLayer.destroy();
  }
  if (this._stage) {
    this._stage.destroy();
    this._stage = null;
  }
};
function SpectrogramImage(options) {
  this._view = options.view;
  this._opacity = options.opacity;
  this.setImage();
}
SpectrogramImage.prototype.setImage = function() {
  this._data = this._view.getSpectrogramData();
  if (!this._data) {
    return;
  }
  this._image = new Konva.Image();
  this._view._spectrogramLayer.add(this._image);
  const data = this._data;
  let img = new window.Image();
  img.onload = (ev) => {
    const { width, height } = img;
    const desiredWidth = this._view.getWidth();
    const desiredHeight = this._view.getHeight();
    const scaleX = desiredWidth / width;
    const scaleY = desiredHeight / height;
    const totalDuration = this._view._peaks.player.getDuration();
    let duration = Math.abs(this._view.getEndTime() - this._view.getStartTime());
    duration = Math.min(totalDuration, duration);
    const cropWidth = this._calculateWidthForDuration(duration);
    const cropX = data.crop.x + this._calculateWidthForDuration(this._view.getStartTime());
    let crop = {
      x: cropX,
      y: data.crop.y,
      width: cropWidth,
      height: data.crop.height
    };
    this._image.setAttrs({
      x: 0,
      y: 0,
      image: img,
      width,
      height,
      scaleX,
      scaleY,
      crop,
      opacity: 0,
      draggable: false
    });
    this._image.to({ opacity: this._opacity });
  };
  img.src = data.image;
};
SpectrogramImage.prototype.update = function(frameOffset) {
  if (!this._data) {
    return;
  }
  const totalDuration = this._view._peaks.player.getDuration();
  let duration = Math.abs(this._view.getEndTime() - this._view.getStartTime());
  duration = Math.min(totalDuration, duration);
  let cropWidth = this._calculateWidthForDuration(duration);
  let cropX = this._data.crop.x + this._calculateWidthForDuration(this._view.getStartTime());
  let crop = {
    x: cropX,
    y: this._data.crop.y,
    width: cropWidth,
    height: this._data.crop.height
  };
  const desiredWidth = this._view.getWidth();
  const desiredHeight = this._view.getHeight();
  const scaleX = desiredWidth / this._image.width();
  const scaleY = desiredHeight / this._image.height();
  this._image.scale({ x: scaleX, y: scaleY });
  this._image.crop(crop);
};
SpectrogramImage.prototype._calculateWidthForDuration = function(duration) {
  const totalDuration = this._view._peaks.player.getDuration();
  const totalWidth = this._data.crop.width;
  return Math.floor(totalWidth / totalDuration * duration);
};
SpectrogramImage.prototype.fitToView = function() {
};
SpectrogramImage.prototype.setOpacity = function(opacity) {
  this._opacity = opacity;
  this._image.to({ opacity });
};
SpectrogramImage.prototype.destroy = function() {
  this._image.destroy();
  this._image = null;
};
SpectrogramImage.prototype.on = function(event, handler) {
  this._image.on(event, handler);
};
SpectrogramImage.prototype.off = function(event, handler) {
  this._image.off(event, handler);
};
function SpectrogramOverview(waveformData, spectrogramData, container, peaks) {
  const self = this;
  self._originalWaveformData = waveformData;
  self._spectrogramData = spectrogramData;
  self._container = container;
  self._peaks = peaks;
  self._options = peaks.options;
  self._viewOptions = peaks.options.spectrogramOverview;
  self._onTimeUpdate = self._onTimeUpdate.bind(this);
  self._onPlaying = self._onPlaying.bind(this);
  self._onPause = self._onPause.bind(this);
  self._onZoomviewDisplaying = self._onZoomviewDisplaying.bind(this);
  self._onWindowResize = self._onWindowResize.bind(this);
  peaks.on("player.timeupdate", self._onTimeUpdate);
  peaks.on("player.playing", self._onPlaying);
  peaks.on("player.pause", self._onPause);
  peaks.on("zoomview.displaying", self._onZoomviewDisplaying);
  peaks.on("window_resize", self._onWindowResize);
  self._amplitudeScale = 1;
  self._timeLabelPrecision = self._viewOptions.timeLabelPrecision;
  self._enableSeek = true;
  if (self._viewOptions.formatPlayheadTime) {
    self._formatPlayheadTime = self._viewOptions.formatPlayheadTime;
  } else {
    self._formatPlayheadTime = function(time2) {
      return formatTime(time2, self._timeLabelPrecision);
    };
  }
  self._width = container.clientWidth;
  self._height = container.clientHeight;
  self._data = waveformData;
  if (self._width !== 0) {
    self._resampleAndSetWaveformData(waveformData, self._width);
  }
  Konva.showWarnings = false;
  self._resizeTimeoutId = null;
  self._stage = new Konva.Stage({
    container,
    width: self._width,
    height: self._height
  });
  self._spectrogramOpacity = self._viewOptions.spectrogramOpacity;
  self._createSpectrogram();
  self._waveformColor = self._viewOptions.waveformColor;
  self._playedWaveformColor = self._viewOptions.playedWaveformColor;
  self._createWaveform();
  if (self._viewOptions.enableSegments) {
    self._segmentsLayer = new SegmentsLayer(peaks, self, false);
    self._segmentsLayer.addToStage(self._stage);
  }
  if (self._viewOptions.enablePoints) {
    self._pointsLayer = new PointsLayer(peaks, self, false);
    self._pointsLayer.addToStage(self._stage);
  }
  self._highlightLayer = new HighlightLayer(
    self,
    self._viewOptions
  );
  self._highlightLayer.addToStage(self._stage);
  self._createAxisLabels();
  self._playheadLayer = new PlayheadLayer({
    player: self._peaks.player,
    view: self,
    showPlayheadTime: self._viewOptions.showPlayheadTime,
    playheadColor: self._viewOptions.playheadColor,
    playheadTextColor: self._viewOptions.playheadTextColor,
    playheadFontFamily: self._viewOptions.fontFamily,
    playheadFontSize: self._viewOptions.fontSize,
    playheadFontStyle: self._viewOptions.fontStyle
  });
  self._playheadLayer.addToStage(self._stage);
  const time = self._peaks.player.getCurrentTime();
  self._playheadLayer.updatePlayheadTime(time);
  self._mouseDragHandler = new SeekMouseDragHandler(peaks, self);
  self._onClick = self._onClick.bind(self);
  self._onDblClick = self._onDblClick.bind(self);
  self._onContextMenu = self._onContextMenu.bind(self);
  self._stage.on("click", self._onClick);
  self._stage.on("dblclick", self._onDblClick);
  self._stage.on("contextmenu", self._onContextMenu);
}
SpectrogramOverview.prototype.enableSeek = function(enable) {
  this._enableSeek = enable;
};
SpectrogramOverview.prototype.isSeekEnabled = function() {
  return this._enableSeek;
};
SpectrogramOverview.prototype._onClick = function(event) {
  this._clickHandler(event, "click");
};
SpectrogramOverview.prototype._onDblClick = function(event) {
  this._clickHandler(event, "dblclick");
};
SpectrogramOverview.prototype._onContextMenu = function(event) {
  this._clickHandler(event, "contextmenu");
};
SpectrogramOverview.prototype._clickHandler = function(event, eventName) {
  let emitViewEvent = true;
  if (event.target !== this._stage) {
    const marker = getMarkerObject(event.target);
    if (marker) {
      if (marker.attrs.name === "point-marker") {
        const point = marker.getAttr("point");
        if (point) {
          this._peaks.emit("points." + eventName, {
            point,
            evt: event.evt,
            preventViewEvent: function() {
              emitViewEvent = false;
            }
          });
        }
      } else if (marker.attrs.name === "segment-overlay") {
        const segment = marker.getAttr("segment");
        if (segment) {
          const clickEvent = {
            segment,
            evt: event.evt,
            preventViewEvent: function() {
              emitViewEvent = false;
            }
          };
          if (this._segmentsLayer) {
            this._segmentsLayer.segmentClicked(eventName, clickEvent);
          }
        }
      }
    }
  }
  if (emitViewEvent) {
    const pixelIndex = event.evt.layerX;
    const time = this.pixelsToTime(pixelIndex);
    this._peaks.emit("overview." + eventName, {
      time,
      evt: event.evt
    });
  }
};
SpectrogramOverview.prototype.isSegmentDraggingEnabled = function() {
  return false;
};
SpectrogramOverview.prototype.getName = function() {
  return "spectrogramOverview";
};
SpectrogramOverview.prototype._onTimeUpdate = function(time) {
  this._playheadLayer.updatePlayheadTime(time);
};
SpectrogramOverview.prototype._onPlaying = function(time) {
  this._playheadLayer.updatePlayheadTime(time);
};
SpectrogramOverview.prototype._onPause = function(time) {
  this._playheadLayer.stop(time);
};
SpectrogramOverview.prototype._onZoomviewDisplaying = function(startTime, endTime) {
  this.showHighlight(startTime, endTime);
};
SpectrogramOverview.prototype.updatePlayheadTime = function(time) {
  this._playheadLayer.updatePlayheadTime(time);
};
SpectrogramOverview.prototype.showHighlight = function(startTime, endTime) {
  this._highlightLayer.showHighlight(startTime, endTime);
};
SpectrogramOverview.prototype._onWindowResize = function() {
  const self = this;
  if (self._resizeTimeoutId) {
    clearTimeout(self._resizeTimeoutId);
    self._resizeTimeoutId = null;
  }
  if (self._container.clientWidth !== 0) {
    self._width = self._container.clientWidth;
    self._stage.setWidth(self._width);
    self._resizeTimeoutId = setTimeout(function() {
      self._width = self._container.clientWidth;
      self._resampleAndSetWaveformData(self._originalWaveformData, self._width);
      self._stage.setWidth(self._width);
      self._updateWaveform();
    }, 500);
  }
};
SpectrogramOverview.prototype.setWaveformData = function(waveformData) {
  this._originalWaveformData = waveformData;
  if (this._width !== 0) {
    this._resampleAndSetWaveformData(waveformData, this._width);
  } else {
    this._data = waveformData;
  }
  this._updateWaveform();
};
SpectrogramOverview.prototype.setSpectrogramData = function(spectrogramData) {
  this._spectrogramData = spectrogramData;
  this._spectrogramImage.setImage();
};
SpectrogramOverview.prototype._resampleAndSetWaveformData = function(waveformData, width) {
  try {
    this._data = waveformData.resample({ width });
    return true;
  } catch (error) {
    this._data = waveformData;
    return false;
  }
};
SpectrogramOverview.prototype.playheadPosChanged = function(time) {
  if (this._playedWaveformShape) {
    this._playedSegment.endTime = time;
    this._unplayedSegment.startTime = time;
    this._waveformLayer.draw();
  }
};
SpectrogramOverview.prototype.timeToPixels = function(time) {
  return Math.floor(time * this._data.sample_rate / this._data.scale);
};
SpectrogramOverview.prototype.timeToPixelOffset = SpectrogramOverview.prototype.timeToPixels;
SpectrogramOverview.prototype.pixelsToTime = function(pixels) {
  return pixels * this._data.scale / this._data.sample_rate;
};
SpectrogramOverview.prototype.pixelOffsetToTime = SpectrogramOverview.prototype.pixelsToTime;
SpectrogramOverview.prototype.getFrameOffset = function() {
  return 0;
};
SpectrogramOverview.prototype.getStartTime = function() {
  return 0;
};
SpectrogramOverview.prototype.getEndTime = function() {
  return this._getDuration();
};
SpectrogramOverview.prototype.getWidth = function() {
  return this._width;
};
SpectrogramOverview.prototype.getHeight = function() {
  return this._height;
};
SpectrogramOverview.prototype._getDuration = function() {
  return this._peaks.player.getDuration();
};
SpectrogramOverview.prototype.setAmplitudeScale = function(scale) {
  if (!isNumber(scale) || !isFinite(scale)) {
    throw new Error("view.setAmplitudeScale(): Scale must be a valid number");
  }
  this._amplitudeScale = scale;
  this._waveformLayer.draw();
  if (this._segmentsLayer) {
    this._segmentsLayer.draw();
  }
};
SpectrogramOverview.prototype.getAmplitudeScale = function() {
  return this._amplitudeScale;
};
SpectrogramOverview.prototype.getWaveformData = function() {
  return this._data;
};
SpectrogramOverview.prototype.getSpectrogramData = function() {
  return this._spectrogramData;
};
SpectrogramOverview.prototype._createSpectrogram = function() {
  this._spectrogramLayer = new Konva.Layer({ listening: false });
  if (!this._spectrogramImage) {
    this._spectrogramImage = new SpectrogramImage({
      opacity: this._spectrogramOpacity,
      view: this
    });
  }
  this._stage.add(this._spectrogramLayer);
};
SpectrogramOverview.prototype._createWaveformShapes = function() {
  if (!this._waveformShape) {
    this._waveformShape = new WaveformShape({
      color: this._waveformColor,
      view: this
    });
    this._waveformShape.addToLayer(this._waveformLayer);
  }
  if (this._playedWaveformColor && !this._playedWaveformShape) {
    const time = this._peaks.player.getCurrentTime();
    this._playedSegment = {
      startTime: 0,
      endTime: time
    };
    this._unplayedSegment = {
      startTime: time,
      endTime: this._getDuration()
    };
    this._waveformShape.setSegment(this._unplayedSegment);
    this._playedWaveformShape = new WaveformShape({
      color: this._playedWaveformColor,
      view: this,
      segment: this._playedSegment
    });
    this._playedWaveformShape.addToLayer(this._waveformLayer);
  }
};
SpectrogramOverview.prototype._destroyPlayedWaveformShape = function() {
  this._waveformShape.setSegment(null);
  this._playedWaveformShape.destroy();
  this._playedWaveformShape = null;
  this._playedSegment = null;
  this._unplayedSegment = null;
};
SpectrogramOverview.prototype._createWaveform = function() {
  this._waveformLayer = new Konva.Layer({ listening: false });
  this._createWaveformShapes();
  this._stage.add(this._waveformLayer);
};
SpectrogramOverview.prototype._createAxisLabels = function() {
  this._axisLayer = new Konva.Layer({ listening: false });
  this._axis = new WaveformAxis(this, this._viewOptions);
  this._axis.addToLayer(this._axisLayer);
  this._stage.add(this._axisLayer);
};
SpectrogramOverview.prototype.removeHighlightRect = function() {
  this._highlightLayer.removeHighlight();
};
SpectrogramOverview.prototype._updateWaveform = function() {
  this._spectrogramImage.update();
  this._spectrogramLayer.draw();
  this._waveformLayer.draw();
  this._axisLayer.draw();
  const playheadTime = this._peaks.player.getCurrentTime();
  this._playheadLayer.updatePlayheadTime(playheadTime);
  this._highlightLayer.updateHighlight();
  const frameStartTime = 0;
  const frameEndTime = this.pixelsToTime(this._width);
  if (this._pointsLayer) {
    this._pointsLayer.updatePoints(frameStartTime, frameEndTime);
  }
  if (this._segmentsLayer) {
    this._segmentsLayer.updateSegments(frameStartTime, frameEndTime);
  }
};
SpectrogramOverview.prototype.setWaveformColor = function(color) {
  this._waveformColor = color;
  this._waveformShape.setWaveformColor(color);
};
SpectrogramOverview.prototype.setSpectrogramOpacity = function(opacity) {
  this._spectrogramOpacity = opacity;
  this._spectrogramImage.setOpacity(opacity);
};
SpectrogramOverview.prototype.setPlayedWaveformColor = function(color) {
  this._playedWaveformColor = color;
  if (color) {
    if (!this._playedWaveformShape) {
      this._createWaveformShapes();
    }
    this._playedWaveformShape.setWaveformColor(color);
  } else {
    if (this._playedWaveformShape) {
      this._destroyPlayedWaveformShape();
    }
  }
};
SpectrogramOverview.prototype.showPlayheadTime = function(show) {
  this._playheadLayer.showPlayheadTime(show);
};
SpectrogramOverview.prototype.setTimeLabelPrecision = function(precision) {
  this._timeLabelPrecision = precision;
  this._playheadLayer.updatePlayheadText();
};
SpectrogramOverview.prototype.formatTime = function(time) {
  return this._formatPlayheadTime(time);
};
SpectrogramOverview.prototype.showAxisLabels = function(show) {
  this._axis.showAxisLabels(show);
  this._axisLayer.draw();
};
SpectrogramOverview.prototype.enableMarkerEditing = function(enable) {
  if (this._segmentsLayer) {
    this._segmentsLayer.enableEditing(enable);
  }
  if (this._pointsLayer) {
    this._pointsLayer.enableEditing(enable);
  }
};
SpectrogramOverview.prototype.fitToContainer = function() {
  if (this._container.clientWidth === 0 && this._container.clientHeight === 0) {
    return;
  }
  let updateWaveform = false;
  if (this._container.clientWidth !== this._width) {
    this._width = this._container.clientWidth;
    this._stage.setWidth(this._width);
    if (this._resampleAndSetWaveformData(this._originalWaveformData, this._width)) {
      updateWaveform = true;
    }
  }
  this._height = this._container.clientHeight;
  this._stage.setHeight(this._height);
  this._waveformShape.fitToView();
  this._playheadLayer.fitToView();
  if (this._segmentsLayer) {
    this._segmentsLayer.fitToView();
  }
  if (this._pointsLayer) {
    this._pointsLayer.fitToView();
  }
  this._highlightLayer.fitToView();
  if (updateWaveform) {
    this._updateWaveform();
  }
  this._stage.draw();
};
SpectrogramOverview.prototype.getViewOptions = function() {
  return this._viewOptions;
};
SpectrogramOverview.prototype.destroy = function() {
  if (this._resizeTimeoutId) {
    clearTimeout(this._resizeTimeoutId);
    this._resizeTimeoutId = null;
  }
  this._peaks.off("player.playing", this._onPlaying);
  this._peaks.off("player.pause", this._onPause);
  this._peaks.off("player.timeupdate", this._onTimeUpdate);
  this._peaks.off("zoomview.displaying", this._onZoomviewDisplaying);
  this._peaks.off("window_resize", this._onWindowResize);
  this._mouseDragHandler.destroy();
  this._playheadLayer.destroy();
  if (this._segmentsLayer) {
    this._segmentsLayer.destroy();
  }
  if (this._pointsLayer) {
    this._pointsLayer.destroy();
  }
  if (this._stage) {
    this._stage.destroy();
    this._stage = null;
  }
};
function InsertSegmentMouseDragHandler(peaks, view) {
  this._peaks = peaks;
  this._view = view;
  this._onMouseDown = this._onMouseDown.bind(this);
  this._onMouseMove = this._onMouseMove.bind(this);
  this._onMouseUp = this._onMouseUp.bind(this);
  this._mouseDragHandler = new MouseDragHandler(view._stage, {
    onMouseDown: this._onMouseDown,
    onMouseMove: this._onMouseMove,
    onMouseUp: this._onMouseUp
  });
}
InsertSegmentMouseDragHandler.prototype.isDragging = function() {
  return this._mouseDragHandler.isDragging();
};
InsertSegmentMouseDragHandler.prototype._onMouseDown = function(mousePosX, segment) {
  this._segment = segment;
  if (this._segment) {
    this._segmentIsDraggable = this._segment.draggable();
    this._segment.draggable(false);
  }
  const time = this._view.pixelsToTime(mousePosX + this._view.getFrameOffset());
  this._peaks.segments.setInserting(true);
  this._insertSegment = this._peaks.segments.add({
    startTime: time,
    endTime: time,
    editable: true
  });
  this._insertSegmentShape = this._view._segmentsLayer.getSegmentShape(this._insertSegment);
  if (this._insertSegmentShape) {
    this._insertSegmentShape.moveMarkersToTop();
    this._insertSegmentShape.startDrag();
  }
};
InsertSegmentMouseDragHandler.prototype._onMouseMove = function() {
};
InsertSegmentMouseDragHandler.prototype._onMouseUp = function() {
  if (this._insertSegmentShape) {
    this._insertSegmentShape.stopDrag();
    this._insertSegmentShape = null;
  }
  if (this._segment && this._segmentIsDraggable) {
    this._segment.draggable(true);
  }
  this._peaks.emit("segments.insert", {
    segment: this._insertSegment
  });
  this._peaks.segments.setInserting(false);
};
InsertSegmentMouseDragHandler.prototype.destroy = function() {
  this._mouseDragHandler.destroy();
};
function ScrollMouseDragHandler(peaks, view) {
  this._peaks = peaks;
  this._view = view;
  this._onMouseDown = this._onMouseDown.bind(this);
  this._onMouseMove = this._onMouseMove.bind(this);
  this._onMouseUp = this._onMouseUp.bind(this);
  this._mouseDragHandler = new MouseDragHandler(view._stage, {
    onMouseDown: this._onMouseDown,
    onMouseMove: this._onMouseMove,
    onMouseUp: this._onMouseUp
  });
}
ScrollMouseDragHandler.prototype.isDragging = function() {
  return this._mouseDragHandler.isDragging();
};
ScrollMouseDragHandler.prototype._onMouseDown = function(mousePosX, segment) {
  this._segment = segment;
  this._seeking = false;
  const playheadOffset = this._view.getPlayheadOffset();
  if (this._view.isSeekEnabled() && Math.abs(mousePosX - playheadOffset) <= this._view.getPlayheadClickTolerance()) {
    this._seeking = true;
    if (this._segment) {
      this._segmentIsDraggable = this._segment.draggable();
      this._segment.draggable(false);
    }
  }
  if (this._seeking) {
    this._seek(mousePosX);
  } else {
    this._initialFrameOffset = this._view.getFrameOffset();
    this._mouseDownX = mousePosX;
  }
};
ScrollMouseDragHandler.prototype._onMouseMove = function(mousePosX) {
  if (this._segment && !this._seeking) {
    return;
  }
  if (this._seeking) {
    mousePosX = clamp(mousePosX, 0, this._view.getWidth());
    const time = this._view.pixelsToTime(mousePosX + this._view.getFrameOffset());
    this._seek(time);
  } else {
    const diff = this._mouseDownX - mousePosX;
    const newFrameOffset = this._initialFrameOffset + diff;
    if (newFrameOffset !== this._initialFrameOffset) {
      this._view.updateWaveform(newFrameOffset);
    }
  }
};
ScrollMouseDragHandler.prototype._onMouseUp = function() {
  if (!this._seeking) {
    if (this._view._enableSeek && !this._mouseDragHandler.isDragging()) {
      const time = this._view.pixelOffsetToTime(this._mouseDownX);
      this._seek(time);
    }
  }
  if (this._segment && this._seeking) {
    if (this._segmentIsDraggable) {
      this._segment.draggable(true);
    }
  }
};
ScrollMouseDragHandler.prototype._seek = function(time) {
  const duration = this._peaks.player.getDuration();
  if (time > duration) {
    time = duration;
  }
  this._view.updatePlayheadTime(time);
  this._peaks.player.seek(time);
};
ScrollMouseDragHandler.prototype.destroy = function() {
  this._mouseDragHandler.destroy();
};
function WaveformZoomView(waveformData, container, peaks) {
  const self = this;
  self._container = container;
  self._peaks = peaks;
  self._options = peaks.options;
  self._viewOptions = self._options.zoomview;
  self._enableWaveformCache = self._options.waveformCache;
  self._originalWaveformData = waveformData;
  self._initWaveformCache();
  self._onTimeUpdate = self._onTimeUpdate.bind(self);
  self._onPlaying = self._onPlaying.bind(self);
  self._onPause = self._onPause.bind(self);
  self._onWindowResize = self._onWindowResize.bind(self);
  self._onKeyboardLeft = self._onKeyboardLeft.bind(self);
  self._onKeyboardRight = self._onKeyboardRight.bind(self);
  self._onKeyboardShiftLeft = self._onKeyboardShiftLeft.bind(self);
  self._onKeyboardShiftRight = self._onKeyboardShiftRight.bind(self);
  self._peaks.on("player.timeupdate", self._onTimeUpdate);
  self._peaks.on("player.playing", self._onPlaying);
  self._peaks.on("player.pause", self._onPause);
  self._peaks.on("window_resize", self._onWindowResize);
  self._peaks.on("keyboard.left", self._onKeyboardLeft);
  self._peaks.on("keyboard.right", self._onKeyboardRight);
  self._peaks.on("keyboard.shift_left", self._onKeyboardShiftLeft);
  self._peaks.on("keyboard.shift_right", self._onKeyboardShiftRight);
  self._autoScroll = self._viewOptions.autoScroll;
  self._autoScrollOffset = self._viewOptions.autoScrollOffset;
  self._amplitudeScale = 1;
  self._timeLabelPrecision = self._viewOptions.timeLabelPrecision;
  self._enableSeek = true;
  self._enableSegmentDragging = false;
  self._segmentDragMode = "overlap";
  self._minSegmentDragWidth = 0;
  self._insertSegmentShape = null;
  if (self._viewOptions.formatPlayheadTime) {
    self._formatPlayheadTime = self._viewOptions.formatPlayheadTime;
  } else {
    self._formatPlayheadTime = function(time2) {
      return formatTime(time2, self._timeLabelPrecision);
    };
  }
  self._playheadClickTolerance = self._viewOptions.playheadClickTolerance;
  self._data = null;
  self._pixelLength = 0;
  const initialZoomLevel = peaks.zoom.getZoomLevel();
  self._zoomLevelAuto = false;
  self._zoomLevelSeconds = null;
  self._resizeTimeoutId = null;
  self._resampleData({ scale: initialZoomLevel });
  self._width = container.clientWidth;
  self._height = container.clientHeight;
  self._frameOffset = 0;
  self._stage = new Konva.Stage({
    container,
    width: self._width,
    height: self._height
  });
  self._waveformColor = self._viewOptions.waveformColor;
  self._playedWaveformColor = self._viewOptions.playedWaveformColor;
  self._createWaveform();
  if (self._viewOptions.enableSegments) {
    self._segmentsLayer = new SegmentsLayer(peaks, self, true);
    self._segmentsLayer.addToStage(self._stage);
  }
  if (self._viewOptions.enablePoints) {
    self._pointsLayer = new PointsLayer(peaks, self, true);
    self._pointsLayer.addToStage(self._stage);
  }
  self._createAxisLabels();
  self._playheadLayer = new PlayheadLayer({
    player: self._peaks.player,
    view: self,
    showPlayheadTime: self._viewOptions.showPlayheadTime,
    playheadColor: self._viewOptions.playheadColor,
    playheadTextColor: self._viewOptions.playheadTextColor,
    playheadFontFamily: self._viewOptions.fontFamily,
    playheadFontSize: self._viewOptions.fontSize,
    playheadFontStyle: self._viewOptions.fontStyle
  });
  self._playheadLayer.addToStage(self._stage);
  const time = self._peaks.player.getCurrentTime();
  self._syncPlayhead(time);
  self._mouseDragHandler = new ScrollMouseDragHandler(self._peaks, self);
  self._onWheel = self._onWheel.bind(self);
  self._onWheelCaptureVerticalScroll = self._onWheelCaptureVerticalScroll.bind(self);
  self.setWheelMode(self._viewOptions.wheelMode);
  self._onClick = self._onClick.bind(self);
  self._onDblClick = self._onDblClick.bind(self);
  self._onContextMenu = self._onContextMenu.bind(self);
  self._stage.on("click", self._onClick);
  self._stage.on("dblclick", self._onDblClick);
  self._stage.on("contextmenu", self._onContextMenu);
}
WaveformZoomView.prototype._initWaveformCache = function() {
  if (this._enableWaveformCache) {
    this._waveformData = /* @__PURE__ */ new Map();
    this._waveformData.set(this._originalWaveformData.scale, this._originalWaveformData);
    this._waveformScales = [this._originalWaveformData.scale];
  }
};
WaveformZoomView.prototype.enableSeek = function(enable) {
  this._enableSeek = enable;
};
WaveformZoomView.prototype.isSeekEnabled = function() {
  return this._enableSeek;
};
WaveformZoomView.prototype._onClick = function(event) {
  this._clickHandler(event, "click");
};
WaveformZoomView.prototype._onDblClick = function(event) {
  this._clickHandler(event, "dblclick");
};
WaveformZoomView.prototype._onContextMenu = function(event) {
  this._clickHandler(event, "contextmenu");
};
WaveformZoomView.prototype._clickHandler = function(event, eventName) {
  let emitViewEvent = true;
  if (event.target !== this._stage) {
    const marker = getMarkerObject(event.target);
    if (marker) {
      if (marker.attrs.name === "point-marker") {
        const point = marker.getAttr("point");
        if (point) {
          this._peaks.emit("points." + eventName, {
            point,
            evt: event.evt,
            preventViewEvent: function() {
              emitViewEvent = false;
            }
          });
        }
      } else if (marker.attrs.name === "segment-overlay") {
        const segment = marker.getAttr("segment");
        if (segment) {
          const clickEvent = {
            segment,
            evt: event.evt,
            preventViewEvent: function() {
              emitViewEvent = false;
            }
          };
          if (this._segmentsLayer) {
            this._segmentsLayer.segmentClicked(eventName, clickEvent);
          }
        }
      }
    }
  }
  if (emitViewEvent) {
    const mousePosX = event.evt.layerX;
    const time = this.pixelOffsetToTime(mousePosX);
    this._peaks.emit("zoomview." + eventName, {
      time,
      evt: event.evt
    });
  }
};
WaveformZoomView.prototype.setWheelMode = function(mode, options) {
  if (!options) {
    options = {};
  }
  if (mode !== this._wheelMode || options.captureVerticalScroll !== this._captureVerticalScroll) {
    this._stage.off("wheel");
    this._wheelMode = mode;
    this._captureVerticalScroll = options.captureVerticalScroll;
    switch (mode) {
      case "scroll":
        if (options.captureVerticalScroll) {
          this._stage.on("wheel", this._onWheelCaptureVerticalScroll);
        } else {
          this._stage.on("wheel", this._onWheel);
        }
        break;
    }
  }
};
WaveformZoomView.prototype._onWheel = function(event) {
  const wheelEvent = event.evt;
  let delta;
  if (wheelEvent.shiftKey) {
    if (wheelEvent.deltaY !== 0) {
      delta = wheelEvent.deltaY;
    } else if (wheelEvent.deltaX !== 0) {
      delta = wheelEvent.deltaX;
    } else {
      return;
    }
  } else {
    if (Math.abs(wheelEvent.deltaX) < Math.abs(wheelEvent.deltaY)) {
      return;
    }
    delta = wheelEvent.deltaX;
  }
  if (wheelEvent.deltaMode === WheelEvent.DOM_DELTA_PAGE) {
    delta *= this._width;
  }
  wheelEvent.preventDefault();
  const newFrameOffset = clamp(
    this._frameOffset + Math.floor(delta),
    0,
    this._pixelLength - this._width
  );
  this.updateWaveform(newFrameOffset);
};
WaveformZoomView.prototype._onWheelCaptureVerticalScroll = function(event) {
  const wheelEvent = event.evt;
  const delta = Math.abs(wheelEvent.deltaX) < Math.abs(wheelEvent.deltaY) ? wheelEvent.deltaY : wheelEvent.deltaX;
  wheelEvent.preventDefault();
  const newFrameOffset = clamp(
    this._frameOffset + Math.floor(delta),
    0,
    this._pixelLength - this._width
  );
  this.updateWaveform(newFrameOffset);
};
WaveformZoomView.prototype.setWaveformDragMode = function(mode) {
  if (this._viewOptions.enableSegments) {
    this._mouseDragHandler.destroy();
    if (mode === "insert-segment") {
      this._mouseDragHandler = new InsertSegmentMouseDragHandler(this._peaks, this);
    } else {
      this._mouseDragHandler = new ScrollMouseDragHandler(this._peaks, this);
    }
  }
};
WaveformZoomView.prototype.enableSegmentDragging = function(enable) {
  this._enableSegmentDragging = enable;
  if (this._segmentsLayer) {
    this._segmentsLayer.enableSegmentDragging(enable);
  }
};
WaveformZoomView.prototype.isSegmentDraggingEnabled = function() {
  return this._enableSegmentDragging;
};
WaveformZoomView.prototype.setSegmentDragMode = function(mode) {
  this._segmentDragMode = mode;
};
WaveformZoomView.prototype.getSegmentDragMode = function() {
  return this._segmentDragMode;
};
WaveformZoomView.prototype.getName = function() {
  return "zoomview";
};
WaveformZoomView.prototype._onTimeUpdate = function(time) {
  if (this._mouseDragHandler.isDragging()) {
    return;
  }
  this._syncPlayhead(time);
};
WaveformZoomView.prototype._onPlaying = function(time) {
  this._playheadLayer.updatePlayheadTime(time);
};
WaveformZoomView.prototype._onPause = function(time) {
  this._playheadLayer.stop(time);
};
WaveformZoomView.prototype._onWindowResize = function() {
  const self = this;
  const width = self._container.clientWidth;
  if (!self._zoomLevelAuto) {
    if (width !== 0) {
      self._width = width;
      self._stage.width(width);
      self.updateWaveform(self._frameOffset);
    }
  } else {
    if (self._resizeTimeoutId) {
      clearTimeout(self._resizeTimeoutId);
      self._resizeTimeoutId = null;
    }
    if (width !== 0) {
      self._width = width;
      self._stage.width(width);
      self._resizeTimeoutId = setTimeout(function() {
        self._width = width;
        self._data = self._originalWaveformData.resample(width);
        self._stage.width(width);
        self.updateWaveform(self._frameOffset);
      }, 500);
    }
  }
};
WaveformZoomView.prototype._onKeyboardLeft = function() {
  this._keyboardScroll(-1, false);
};
WaveformZoomView.prototype._onKeyboardRight = function() {
  this._keyboardScroll(1, false);
};
WaveformZoomView.prototype._onKeyboardShiftLeft = function() {
  this._keyboardScroll(-1, true);
};
WaveformZoomView.prototype._onKeyboardShiftRight = function() {
  this._keyboardScroll(1, true);
};
WaveformZoomView.prototype._keyboardScroll = function(direction, large) {
  let increment;
  if (large) {
    increment = direction * this._width;
  } else {
    increment = direction * this.timeToPixels(this._options.nudgeIncrement);
  }
  this.scrollWaveform({ pixels: increment });
};
WaveformZoomView.prototype.setWaveformData = function(waveformData) {
  this._originalWaveformData = waveformData;
  this._initWaveformCache();
};
WaveformZoomView.prototype.getPlayheadOffset = function() {
  return this._playheadLayer.getPlayheadPixel() - this._frameOffset;
};
WaveformZoomView.prototype.getPlayheadClickTolerance = function() {
  return this._playheadClickTolerance;
};
WaveformZoomView.prototype.playheadPosChanged = function(time) {
  if (this._playedWaveformShape) {
    this._playedSegment.endTime = time;
    this._unplayedSegment.startTime = time;
    this._drawWaveformLayer();
  }
};
WaveformZoomView.prototype.updatePlayheadTime = function(time) {
  this._playheadLayer.updatePlayheadTime(time);
};
WaveformZoomView.prototype._syncPlayhead = function(time) {
  this._playheadLayer.updatePlayheadTime(time);
  if (this._autoScroll) {
    const pixelIndex = this.timeToPixels(time);
    const endThreshold = this._frameOffset + this._width - this._autoScrollOffset;
    if (pixelIndex >= endThreshold || pixelIndex < this._frameOffset) {
      this._frameOffset = pixelIndex - this._autoScrollOffset;
      if (this._frameOffset < 0) {
        this._frameOffset = 0;
      }
      this.updateWaveform(this._frameOffset);
    }
  }
};
WaveformZoomView.prototype._getScale = function(duration) {
  return duration * this._data.sample_rate / this._width;
};
function isAutoScale$1(options) {
  return objectHasProperty(options, "scale") && options.scale === "auto" || objectHasProperty(options, "seconds") && options.seconds === "auto";
}
WaveformZoomView.prototype.setZoom = function(options) {
  let scale;
  if (isAutoScale$1(options)) {
    const seconds = this._peaks.player.getDuration();
    if (!isValidTime(seconds)) {
      return false;
    }
    this._zoomLevelAuto = true;
    this._zoomLevelSeconds = null;
    scale = this._getScale(seconds);
  } else {
    if (objectHasProperty(options, "scale")) {
      this._zoomLevelSeconds = null;
      scale = options.scale;
    } else if (objectHasProperty(options, "seconds")) {
      if (!isValidTime(options.seconds)) {
        return false;
      }
      this._zoomLevelSeconds = options.seconds;
      scale = this._getScale(options.seconds);
    }
    this._zoomLevelAuto = false;
  }
  if (scale < this._originalWaveformData.scale) {
    this._peaks._logger("peaks.zoomview.setZoom(): zoom level must be at least " + this._originalWaveformData.scale);
    scale = this._originalWaveformData.scale;
  }
  const currentTime = this._peaks.player.getCurrentTime();
  let apexTime;
  let playheadOffsetPixels = this.getPlayheadOffset();
  if (playheadOffsetPixels >= 0 && playheadOffsetPixels < this._width) {
    apexTime = currentTime;
  } else {
    playheadOffsetPixels = Math.floor(this._width / 2);
    apexTime = this.pixelOffsetToTime(playheadOffsetPixels);
  }
  const prevScale = this._scale;
  this._resampleData({ scale });
  const apexPixel = this.timeToPixels(apexTime);
  this._frameOffset = apexPixel - playheadOffsetPixels;
  this.updateWaveform(this._frameOffset);
  this._playheadLayer.zoomLevelChanged();
  this._playheadLayer.updatePlayheadTime(currentTime);
  this._peaks.emit("zoom.update", {
    currentZoom: scale,
    previousZoom: prevScale
  });
  return true;
};
WaveformZoomView.prototype._resampleData = function(options) {
  const scale = options.scale;
  if (this._enableWaveformCache) {
    if (!this._waveformData.has(scale)) {
      let sourceWaveform = this._originalWaveformData;
      for (let i = 0; i < this._waveformScales.length; i++) {
        if (this._waveformScales[i] < scale) {
          sourceWaveform = this._waveformData.get(this._waveformScales[i]);
        } else {
          break;
        }
      }
      this._waveformData.set(scale, sourceWaveform.resample(options));
      this._waveformScales.push(scale);
      this._waveformScales.sort(function(a, b) {
        return a - b;
      });
    }
    this._data = this._waveformData.get(scale);
  } else {
    this._data = this._originalWaveformData.resample(options);
  }
  this._scale = this._data.scale;
  this._pixelLength = this._data.length;
};
WaveformZoomView.prototype.getStartTime = function() {
  return this.pixelOffsetToTime(0);
};
WaveformZoomView.prototype.getEndTime = function() {
  return this.pixelOffsetToTime(this._width);
};
WaveformZoomView.prototype.setStartTime = function(time) {
  if (time < 0) {
    time = 0;
  }
  if (this._zoomLevelAuto) {
    time = 0;
  }
  this.updateWaveform(this.timeToPixels(time));
};
WaveformZoomView.prototype.timeToPixels = function(time) {
  return Math.floor(time * this._data.sample_rate / this._data.scale);
};
WaveformZoomView.prototype.pixelsToTime = function(pixels) {
  return pixels * this._data.scale / this._data.sample_rate;
};
WaveformZoomView.prototype.pixelOffsetToTime = function(offset) {
  const pixels = this._frameOffset + offset;
  return pixels * this._data.scale / this._data.sample_rate;
};
WaveformZoomView.prototype.timeToPixelOffset = function(time) {
  return Math.floor(time * this._data.sample_rate / this._data.scale) - this._frameOffset;
};
WaveformZoomView.prototype.getFrameOffset = function() {
  return this._frameOffset;
};
WaveformZoomView.prototype.getWidth = function() {
  return this._width;
};
WaveformZoomView.prototype.getHeight = function() {
  return this._height;
};
WaveformZoomView.prototype.getPixelLength = function() {
  return this._pixelLength;
};
WaveformZoomView.prototype._getDuration = function() {
  return this._peaks.player.getDuration();
};
WaveformZoomView.prototype.setAmplitudeScale = function(scale) {
  if (!isNumber(scale) || !isFinite(scale)) {
    throw new Error("view.setAmplitudeScale(): Scale must be a valid number");
  }
  this._amplitudeScale = scale;
  this._drawWaveformLayer();
  if (this._segmentsLayer) {
    this._segmentsLayer.draw();
  }
};
WaveformZoomView.prototype.getAmplitudeScale = function() {
  return this._amplitudeScale;
};
WaveformZoomView.prototype.getWaveformData = function() {
  return this._data;
};
WaveformZoomView.prototype._createWaveformShapes = function() {
  if (!this._waveformShape) {
    this._waveformShape = new WaveformShape({
      color: this._waveformColor,
      view: this
    });
    this._waveformShape.addToLayer(this._waveformLayer);
  }
  if (this._playedWaveformColor && !this._playedWaveformShape) {
    const time = this._peaks.player.getCurrentTime();
    this._playedSegment = {
      startTime: 0,
      endTime: time
    };
    this._unplayedSegment = {
      startTime: time,
      endTime: this._getDuration()
    };
    this._waveformShape.setSegment(this._unplayedSegment);
    this._playedWaveformShape = new WaveformShape({
      color: this._playedWaveformColor,
      view: this,
      segment: this._playedSegment
    });
    this._playedWaveformShape.addToLayer(this._waveformLayer);
  }
};
WaveformZoomView.prototype._destroyPlayedWaveformShape = function() {
  this._waveformShape.setSegment(null);
  this._playedWaveformShape.destroy();
  this._playedWaveformShape = null;
  this._playedSegment = null;
  this._unplayedSegment = null;
};
WaveformZoomView.prototype._createWaveform = function() {
  this._waveformLayer = new Konva.Layer({ listening: false });
  this._createWaveformShapes();
  this._stage.add(this._waveformLayer);
  this._peaks.emit("zoomview.displaying", 0, this.getEndTime());
};
WaveformZoomView.prototype._createAxisLabels = function() {
  this._axisLayer = new Konva.Layer({ listening: false });
  this._axis = new WaveformAxis(this, this._viewOptions);
  this._axis.addToLayer(this._axisLayer);
  this._stage.add(this._axisLayer);
};
WaveformZoomView.prototype.scrollWaveform = function(options) {
  let scrollAmount;
  if (objectHasProperty(options, "pixels")) {
    scrollAmount = Math.floor(options.pixels);
  } else if (objectHasProperty(options, "seconds")) {
    scrollAmount = this.timeToPixels(options.seconds);
  } else {
    throw new TypeError("view.scrollWaveform(): Missing umber of pixels or seconds");
  }
  this.updateWaveform(this._frameOffset + scrollAmount);
};
WaveformZoomView.prototype.updateWaveform = function(frameOffset) {
  let upperLimit;
  if (this._pixelLength < this._width) {
    frameOffset = 0;
    upperLimit = this._width;
  } else {
    upperLimit = this._pixelLength - this._width;
  }
  frameOffset = clamp(frameOffset, 0, upperLimit);
  this._frameOffset = frameOffset;
  const playheadPixel = this._playheadLayer.getPlayheadPixel();
  this._playheadLayer.updatePlayheadTime(this.pixelsToTime(playheadPixel));
  this._drawWaveformLayer();
  this._axisLayer.draw();
  const frameStartTime = this.getStartTime();
  const frameEndTime = this.getEndTime();
  if (this._pointsLayer) {
    this._pointsLayer.updatePoints(frameStartTime, frameEndTime);
  }
  if (this._segmentsLayer) {
    this._segmentsLayer.updateSegments(frameStartTime, frameEndTime);
  }
  this._peaks.emit("zoomview.displaying", frameStartTime, frameEndTime);
};
WaveformZoomView.prototype._drawWaveformLayer = function() {
  this._waveformLayer.draw();
};
WaveformZoomView.prototype.setWaveformColor = function(color) {
  this._waveformColor = color;
  this._waveformShape.setWaveformColor(color);
};
WaveformZoomView.prototype.setPlayedWaveformColor = function(color) {
  this._playedWaveformColor = color;
  if (color) {
    if (!this._playedWaveformShape) {
      this._createWaveformShapes();
    }
    this._playedWaveformShape.setWaveformColor(color);
  } else {
    if (this._playedWaveformShape) {
      this._destroyPlayedWaveformShape();
    }
  }
};
WaveformZoomView.prototype.showPlayheadTime = function(show) {
  this._playheadLayer.showPlayheadTime(show);
};
WaveformZoomView.prototype.setTimeLabelPrecision = function(precision) {
  this._timeLabelPrecision = precision;
  this._playheadLayer.updatePlayheadText();
};
WaveformZoomView.prototype.formatTime = function(time) {
  return this._formatPlayheadTime(time);
};
WaveformZoomView.prototype.showAxisLabels = function(show) {
  this._axis.showAxisLabels(show);
  this._axisLayer.draw();
};
WaveformZoomView.prototype.enableAutoScroll = function(enable, options) {
  this._autoScroll = enable;
  if (objectHasProperty(options, "offset")) {
    this._autoScrollOffset = options.offset;
  }
};
WaveformZoomView.prototype.enableMarkerEditing = function(enable) {
  if (this._segmentsLayer) {
    this._segmentsLayer.enableEditing(enable);
  }
  if (this._pointsLayer) {
    this._pointsLayer.enableEditing(enable);
  }
};
WaveformZoomView.prototype.getMinSegmentDragWidth = function() {
  return this._insertSegmentShape ? 0 : this._minSegmentDragWidth;
};
WaveformZoomView.prototype.setMinSegmentDragWidth = function(width) {
  this._minSegmentDragWidth = width;
};
WaveformZoomView.prototype.fitToContainer = function() {
  if (this._container.clientWidth === 0 && this._container.clientHeight === 0) {
    return;
  }
  let updateWaveform = false;
  if (this._container.clientWidth !== this._width) {
    this._width = this._container.clientWidth;
    this._stage.width(this._width);
    let resample = false;
    let resampleOptions;
    if (this._zoomLevelAuto) {
      resample = true;
      resampleOptions = { width: this._width };
    } else if (this._zoomLevelSeconds !== null) {
      resample = true;
      resampleOptions = { scale: this._getScale(this._zoomLevelSeconds) };
    }
    if (resample) {
      try {
        this._resampleData(resampleOptions);
        updateWaveform = true;
      } catch (error) {
      }
    }
  }
  this._height = this._container.clientHeight;
  this._stage.height(this._height);
  this._waveformShape.fitToView();
  this._playheadLayer.fitToView();
  if (this._segmentsLayer) {
    this._segmentsLayer.fitToView();
  }
  if (this._pointsLayer) {
    this._pointsLayer.fitToView();
  }
  if (updateWaveform) {
    this.updateWaveform(this._frameOffset);
  }
  this._stage.draw();
};
WaveformZoomView.prototype.getStage = function() {
  return this._stage;
};
WaveformZoomView.prototype.getSegmentsLayer = function() {
  return this._segmentsLayer;
};
WaveformZoomView.prototype.getViewOptions = function() {
  return this._viewOptions;
};
WaveformZoomView.prototype.destroy = function() {
  if (this._resizeTimeoutId) {
    clearTimeout(this._resizeTimeoutId);
    this._resizeTimeoutId = null;
  }
  this._peaks.off("player.timeupdate", this._onTimeUpdate);
  this._peaks.off("player.playing", this._onPlaying);
  this._peaks.off("player.pause", this._onPause);
  this._peaks.off("window_resize", this._onWindowResize);
  this._peaks.off("keyboard.left", this._onKeyboardLeft);
  this._peaks.off("keyboard.right", this._onKeyboardRight);
  this._peaks.off("keyboard.shift_left", this._onKeyboardShiftLeft);
  this._peaks.off("keyboard.shift_right", this._onKeyboardShiftRight);
  this._mouseDragHandler.destroy();
  this._playheadLayer.destroy();
  if (this._segmentsLayer) {
    this._segmentsLayer.destroy();
  }
  if (this._pointsLayer) {
    this._pointsLayer.destroy();
  }
  if (this._stage) {
    this._stage.destroy();
    this._stage = null;
  }
};
function SpectrogramZoomView(waveformData, spectrogramData, container, peaks) {
  const self = this;
  self._container = container;
  self._peaks = peaks;
  self._options = peaks.options;
  self._viewOptions = self._options.spectrogramZoomview;
  self._enableWaveformCache = self._options.waveformCache;
  self._originalWaveformData = waveformData;
  self._spectrogramData = spectrogramData;
  self._initWaveformCache();
  self._onTimeUpdate = self._onTimeUpdate.bind(self);
  self._onPlaying = self._onPlaying.bind(self);
  self._onPause = self._onPause.bind(self);
  self._onWindowResize = self._onWindowResize.bind(self);
  self._onKeyboardLeft = self._onKeyboardLeft.bind(self);
  self._onKeyboardRight = self._onKeyboardRight.bind(self);
  self._onKeyboardShiftLeft = self._onKeyboardShiftLeft.bind(self);
  self._onKeyboardShiftRight = self._onKeyboardShiftRight.bind(self);
  self._peaks.on("player.timeupdate", self._onTimeUpdate);
  self._peaks.on("player.playing", self._onPlaying);
  self._peaks.on("player.pause", self._onPause);
  self._peaks.on("window_resize", self._onWindowResize);
  self._peaks.on("keyboard.left", self._onKeyboardLeft);
  self._peaks.on("keyboard.right", self._onKeyboardRight);
  self._peaks.on("keyboard.shift_left", self._onKeyboardShiftLeft);
  self._peaks.on("keyboard.shift_right", self._onKeyboardShiftRight);
  self._autoScroll = self._viewOptions.autoScroll;
  self._autoScrollOffset = self._viewOptions.autoScrollOffset;
  self._amplitudeScale = 1;
  self._timeLabelPrecision = self._viewOptions.timeLabelPrecision;
  self._enableSeek = true;
  self._enableSegmentDragging = false;
  self._segmentDragMode = "overlap";
  self._minSegmentDragWidth = 0;
  self._insertSegmentShape = null;
  if (self._viewOptions.formatPlayheadTime) {
    self._formatPlayheadTime = self._viewOptions.formatPlayheadTime;
  } else {
    self._formatPlayheadTime = function(time2) {
      return formatTime(time2, self._timeLabelPrecision);
    };
  }
  self._playheadClickTolerance = self._viewOptions.playheadClickTolerance;
  self._data = null;
  self._pixelLength = 0;
  const initialZoomLevel = peaks.zoom.getZoomLevel();
  self._zoomLevelAuto = false;
  self._zoomLevelSeconds = null;
  self._resizeTimeoutId = null;
  self._resampleData({ scale: initialZoomLevel });
  self._width = container.clientWidth;
  self._height = container.clientHeight;
  self._frameOffset = 0;
  self._stage = new Konva.Stage({
    container,
    width: self._width,
    height: self._height
  });
  self._spectrogramOpacity = self._viewOptions.spectrogramOpacity;
  self._createSpectrogram();
  self._waveformColor = self._viewOptions.waveformColor;
  self._playedWaveformColor = self._viewOptions.playedWaveformColor;
  self._createWaveform();
  if (self._viewOptions.enableSegments) {
    self._segmentsLayer = new SegmentsLayer(peaks, self, true);
    self._segmentsLayer.addToStage(self._stage);
  }
  if (self._viewOptions.enablePoints) {
    self._pointsLayer = new PointsLayer(peaks, self, true);
    self._pointsLayer.addToStage(self._stage);
  }
  self._createAxisLabels();
  self._playheadLayer = new PlayheadLayer({
    player: self._peaks.player,
    view: self,
    showPlayheadTime: self._viewOptions.showPlayheadTime,
    playheadColor: self._viewOptions.playheadColor,
    playheadTextColor: self._viewOptions.playheadTextColor,
    playheadFontFamily: self._viewOptions.fontFamily,
    playheadFontSize: self._viewOptions.fontSize,
    playheadFontStyle: self._viewOptions.fontStyle
  });
  self._playheadLayer.addToStage(self._stage);
  const time = self._peaks.player.getCurrentTime();
  self._syncPlayhead(time);
  self._mouseDragHandler = new ScrollMouseDragHandler(self._peaks, self);
  self._onWheel = self._onWheel.bind(self);
  self._onWheelCaptureVerticalScroll = self._onWheelCaptureVerticalScroll.bind(self);
  self.setWheelMode(self._viewOptions.wheelMode);
  self._onClick = self._onClick.bind(self);
  self._onDblClick = self._onDblClick.bind(self);
  self._onContextMenu = self._onContextMenu.bind(self);
  self._stage.on("click", self._onClick);
  self._stage.on("dblclick", self._onDblClick);
  self._stage.on("contextmenu", self._onContextMenu);
}
SpectrogramZoomView.prototype._initWaveformCache = function() {
  if (this._enableWaveformCache) {
    this._waveformData = /* @__PURE__ */ new Map();
    this._waveformData.set(this._originalWaveformData.scale, this._originalWaveformData);
    this._waveformScales = [this._originalWaveformData.scale];
  }
};
SpectrogramZoomView.prototype.enableSeek = function(enable) {
  this._enableSeek = enable;
};
SpectrogramZoomView.prototype.isSeekEnabled = function() {
  return this._enableSeek;
};
SpectrogramZoomView.prototype._onClick = function(event) {
  this._clickHandler(event, "click");
};
SpectrogramZoomView.prototype._onDblClick = function(event) {
  this._clickHandler(event, "dblclick");
};
SpectrogramZoomView.prototype._onContextMenu = function(event) {
  this._clickHandler(event, "contextmenu");
};
SpectrogramZoomView.prototype._clickHandler = function(event, eventName) {
  let emitViewEvent = true;
  if (event.target !== this._stage) {
    const marker = getMarkerObject(event.target);
    if (marker) {
      if (marker.attrs.name === "point-marker") {
        const point = marker.getAttr("point");
        if (point) {
          this._peaks.emit("points." + eventName, {
            point,
            evt: event.evt,
            preventViewEvent: function() {
              emitViewEvent = false;
            }
          });
        }
      } else if (marker.attrs.name === "segment-overlay") {
        const segment = marker.getAttr("segment");
        if (segment) {
          const clickEvent = {
            segment,
            evt: event.evt,
            preventViewEvent: function() {
              emitViewEvent = false;
            }
          };
          if (this._segmentsLayer) {
            this._segmentsLayer.segmentClicked(eventName, clickEvent);
          }
        }
      }
    }
  }
  if (emitViewEvent) {
    const mousePosX = event.evt.layerX;
    const time = this.pixelOffsetToTime(mousePosX);
    this._peaks.emit("spectrogramZoomview." + eventName, {
      time,
      evt: event.evt
    });
  }
};
SpectrogramZoomView.prototype.setWheelMode = function(mode, options) {
  if (!options) {
    options = {};
  }
  if (mode !== this._wheelMode || options.captureVerticalScroll !== this._captureVerticalScroll) {
    this._stage.off("wheel");
    this._wheelMode = mode;
    this._captureVerticalScroll = options.captureVerticalScroll;
    switch (mode) {
      case "scroll":
        if (options.captureVerticalScroll) {
          this._stage.on("wheel", this._onWheelCaptureVerticalScroll);
        } else {
          this._stage.on("wheel", this._onWheel);
        }
        break;
    }
  }
};
SpectrogramZoomView.prototype._onWheel = function(event) {
  const wheelEvent = event.evt;
  let delta;
  if (wheelEvent.shiftKey) {
    if (wheelEvent.deltaY !== 0) {
      delta = wheelEvent.deltaY;
    } else if (wheelEvent.deltaX !== 0) {
      delta = wheelEvent.deltaX;
    } else {
      return;
    }
  } else {
    if (Math.abs(wheelEvent.deltaX) < Math.abs(wheelEvent.deltaY)) {
      return;
    }
    delta = wheelEvent.deltaX;
  }
  if (wheelEvent.deltaMode === WheelEvent.DOM_DELTA_PAGE) {
    delta *= this._width;
  }
  wheelEvent.preventDefault();
  const newFrameOffset = clamp(
    this._frameOffset + Math.floor(delta),
    0,
    this._pixelLength - this._width
  );
  this.updateWaveform(newFrameOffset);
};
SpectrogramZoomView.prototype._onWheelCaptureVerticalScroll = function(event) {
  const wheelEvent = event.evt;
  const delta = Math.abs(wheelEvent.deltaX) < Math.abs(wheelEvent.deltaY) ? wheelEvent.deltaY : wheelEvent.deltaX;
  wheelEvent.preventDefault();
  const newFrameOffset = clamp(
    this._frameOffset + Math.floor(delta),
    0,
    this._pixelLength - this._width
  );
  this.updateWaveform(newFrameOffset);
};
SpectrogramZoomView.prototype.setWaveformDragMode = function(mode) {
  if (this._viewOptions.enableSegments) {
    this._mouseDragHandler.destroy();
    if (mode === "insert-segment") {
      this._mouseDragHandler = new InsertSegmentMouseDragHandler(this._peaks, this);
    } else {
      this._mouseDragHandler = new ScrollMouseDragHandler(this._peaks, this);
    }
  }
};
SpectrogramZoomView.prototype.enableSegmentDragging = function(enable) {
  this._enableSegmentDragging = enable;
  if (this._segmentsLayer) {
    this._segmentsLayer.enableSegmentDragging(enable);
  }
};
SpectrogramZoomView.prototype.isSegmentDraggingEnabled = function() {
  return this._enableSegmentDragging;
};
SpectrogramZoomView.prototype.setSegmentDragMode = function(mode) {
  this._segmentDragMode = mode;
};
SpectrogramZoomView.prototype.getSegmentDragMode = function() {
  return this._segmentDragMode;
};
SpectrogramZoomView.prototype.getName = function() {
  return "spectrogramZoomview";
};
SpectrogramZoomView.prototype._onTimeUpdate = function(time) {
  if (this._mouseDragHandler.isDragging()) {
    return;
  }
  this._syncPlayhead(time);
};
SpectrogramZoomView.prototype._onPlaying = function(time) {
  this._playheadLayer.updatePlayheadTime(time);
};
SpectrogramZoomView.prototype._onPause = function(time) {
  this._playheadLayer.stop(time);
};
SpectrogramZoomView.prototype._onWindowResize = function() {
  const self = this;
  const width = self._container.clientWidth;
  if (!self._zoomLevelAuto) {
    if (width !== 0) {
      self._width = width;
      self._stage.width(width);
      self.updateWaveform(self._frameOffset);
    }
  } else {
    if (self._resizeTimeoutId) {
      clearTimeout(self._resizeTimeoutId);
      self._resizeTimeoutId = null;
    }
    if (width !== 0) {
      self._width = width;
      self._stage.width(width);
      self._resizeTimeoutId = setTimeout(function() {
        self._width = width;
        self._data = self._originalWaveformData.resample(width);
        self._stage.width(width);
        self.updateWaveform(self._frameOffset);
      }, 500);
    }
  }
};
SpectrogramZoomView.prototype._onKeyboardLeft = function() {
  this._keyboardScroll(-1, false);
};
SpectrogramZoomView.prototype._onKeyboardRight = function() {
  this._keyboardScroll(1, false);
};
SpectrogramZoomView.prototype._onKeyboardShiftLeft = function() {
  this._keyboardScroll(-1, true);
};
SpectrogramZoomView.prototype._onKeyboardShiftRight = function() {
  this._keyboardScroll(1, true);
};
SpectrogramZoomView.prototype._keyboardScroll = function(direction, large) {
  let increment;
  if (large) {
    increment = direction * this._width;
  } else {
    increment = direction * this.timeToPixels(this._options.nudgeIncrement);
  }
  this.scrollWaveform({ pixels: increment });
};
SpectrogramZoomView.prototype.setWaveformData = function(waveformData) {
  this._originalWaveformData = waveformData;
  this._initWaveformCache();
};
SpectrogramZoomView.prototype.setSpectrogramData = function(spectrogramData) {
  this._spectrogramData = spectrogramData;
  this._spectrogramImage.setImage();
};
SpectrogramZoomView.prototype.getPlayheadOffset = function() {
  return this._playheadLayer.getPlayheadPixel() - this._frameOffset;
};
SpectrogramZoomView.prototype.getPlayheadClickTolerance = function() {
  return this._playheadClickTolerance;
};
SpectrogramZoomView.prototype.playheadPosChanged = function(time) {
  if (this._playedWaveformShape) {
    this._playedSegment.endTime = time;
    this._unplayedSegment.startTime = time;
    this._drawWaveformLayer();
  }
};
SpectrogramZoomView.prototype.updatePlayheadTime = function(time) {
  this._playheadLayer.updatePlayheadTime(time);
};
SpectrogramZoomView.prototype._syncPlayhead = function(time) {
  this._playheadLayer.updatePlayheadTime(time);
  if (this._autoScroll) {
    const pixelIndex = this.timeToPixels(time);
    const endThreshold = this._frameOffset + this._width - this._autoScrollOffset;
    if (pixelIndex >= endThreshold || pixelIndex < this._frameOffset) {
      this._frameOffset = pixelIndex - this._autoScrollOffset;
      if (this._frameOffset < 0) {
        this._frameOffset = 0;
      }
      this.updateWaveform(this._frameOffset);
    }
  }
};
SpectrogramZoomView.prototype._getScale = function(duration) {
  return duration * this._data.sample_rate / this._width;
};
function isAutoScale(options) {
  return objectHasProperty(options, "scale") && options.scale === "auto" || objectHasProperty(options, "seconds") && options.seconds === "auto";
}
SpectrogramZoomView.prototype.setZoom = function(options) {
  let scale;
  if (isAutoScale(options)) {
    const seconds = this._peaks.player.getDuration();
    if (!isValidTime(seconds)) {
      return false;
    }
    this._zoomLevelAuto = true;
    this._zoomLevelSeconds = null;
    scale = this._getScale(seconds);
  } else {
    if (objectHasProperty(options, "scale")) {
      this._zoomLevelSeconds = null;
      scale = options.scale;
    } else if (objectHasProperty(options, "seconds")) {
      if (!isValidTime(options.seconds)) {
        return false;
      }
      this._zoomLevelSeconds = options.seconds;
      scale = this._getScale(options.seconds);
    }
    this._zoomLevelAuto = false;
  }
  if (scale < this._originalWaveformData.scale) {
    this._peaks._logger("peaks.zoomview.setZoom(): zoom level must be at least " + this._originalWaveformData.scale);
    scale = this._originalWaveformData.scale;
  }
  const currentTime = this._peaks.player.getCurrentTime();
  let apexTime;
  let playheadOffsetPixels = this.getPlayheadOffset();
  if (playheadOffsetPixels >= 0 && playheadOffsetPixels < this._width) {
    apexTime = currentTime;
  } else {
    playheadOffsetPixels = Math.floor(this._width / 2);
    apexTime = this.pixelOffsetToTime(playheadOffsetPixels);
  }
  const prevScale = this._scale;
  this._resampleData({ scale });
  const apexPixel = this.timeToPixels(apexTime);
  this._frameOffset = apexPixel - playheadOffsetPixels;
  this.updateWaveform(this._frameOffset);
  this._playheadLayer.zoomLevelChanged();
  this._playheadLayer.updatePlayheadTime(currentTime);
  this._peaks.emit("zoom.update", {
    currentZoom: scale,
    previousZoom: prevScale
  });
  return true;
};
SpectrogramZoomView.prototype._resampleData = function(options) {
  const scale = options.scale;
  if (this._enableWaveformCache) {
    if (!this._waveformData.has(scale)) {
      let sourceWaveform = this._originalWaveformData;
      for (let i = 0; i < this._waveformScales.length; i++) {
        if (this._waveformScales[i] < scale) {
          sourceWaveform = this._waveformData.get(this._waveformScales[i]);
        } else {
          break;
        }
      }
      this._waveformData.set(scale, sourceWaveform.resample(options));
      this._waveformScales.push(scale);
      this._waveformScales.sort(function(a, b) {
        return a - b;
      });
    }
    this._data = this._waveformData.get(scale);
  } else {
    this._data = this._originalWaveformData.resample(options);
  }
  this._scale = this._data.scale;
  this._pixelLength = this._data.length;
};
SpectrogramZoomView.prototype.getStartTime = function() {
  return this.pixelOffsetToTime(0);
};
SpectrogramZoomView.prototype.getEndTime = function() {
  return this.pixelOffsetToTime(this._width);
};
SpectrogramZoomView.prototype.setStartTime = function(time) {
  if (time < 0) {
    time = 0;
  }
  if (this._zoomLevelAuto) {
    time = 0;
  }
  this.updateWaveform(this.timeToPixels(time));
};
SpectrogramZoomView.prototype.timeToPixels = function(time) {
  return Math.floor(time * this._data.sample_rate / this._data.scale);
};
SpectrogramZoomView.prototype.pixelsToTime = function(pixels) {
  return pixels * this._data.scale / this._data.sample_rate;
};
SpectrogramZoomView.prototype.pixelOffsetToTime = function(offset) {
  const pixels = this._frameOffset + offset;
  return pixels * this._data.scale / this._data.sample_rate;
};
SpectrogramZoomView.prototype.timeToPixelOffset = function(time) {
  return Math.floor(time * this._data.sample_rate / this._data.scale) - this._frameOffset;
};
SpectrogramZoomView.prototype.getFrameOffset = function() {
  return this._frameOffset;
};
SpectrogramZoomView.prototype.getWidth = function() {
  return this._width;
};
SpectrogramZoomView.prototype.getHeight = function() {
  return this._height;
};
SpectrogramZoomView.prototype.getPixelLength = function() {
  return this._pixelLength;
};
SpectrogramZoomView.prototype._getDuration = function() {
  return this._peaks.player.getDuration();
};
SpectrogramZoomView.prototype.setAmplitudeScale = function(scale) {
  if (!isNumber(scale) || !isFinite(scale)) {
    throw new Error("view.setAmplitudeScale(): Scale must be a valid number");
  }
  this._amplitudeScale = scale;
  this._drawWaveformLayer();
  if (this._segmentsLayer) {
    this._segmentsLayer.draw();
  }
};
SpectrogramZoomView.prototype.getAmplitudeScale = function() {
  return this._amplitudeScale;
};
SpectrogramZoomView.prototype.getWaveformData = function() {
  return this._data;
};
SpectrogramZoomView.prototype.getSpectrogramData = function() {
  return this._spectrogramData;
};
SpectrogramZoomView.prototype._createSpectrogram = function() {
  this._spectrogramLayer = new Konva.Layer({ listening: false });
  if (!this._spectrogramImage) {
    this._spectrogramImage = new SpectrogramImage({
      opacity: this._spectrogramOpacity,
      view: this
    });
  }
  this._stage.add(this._spectrogramLayer);
};
SpectrogramZoomView.prototype._createWaveformShapes = function() {
  if (!this._waveformShape) {
    this._waveformShape = new WaveformShape({
      color: this._waveformColor,
      view: this
    });
    this._waveformShape.addToLayer(this._waveformLayer);
  }
  if (this._playedWaveformColor && !this._playedWaveformShape) {
    const time = this._peaks.player.getCurrentTime();
    this._playedSegment = {
      startTime: 0,
      endTime: time
    };
    this._unplayedSegment = {
      startTime: time,
      endTime: this._getDuration()
    };
    this._waveformShape.setSegment(this._unplayedSegment);
    this._playedWaveformShape = new WaveformShape({
      color: this._playedWaveformColor,
      view: this,
      segment: this._playedSegment
    });
    this._playedWaveformShape.addToLayer(this._waveformLayer);
  }
};
SpectrogramZoomView.prototype._destroyPlayedWaveformShape = function() {
  this._waveformShape.setSegment(null);
  this._playedWaveformShape.destroy();
  this._playedWaveformShape = null;
  this._playedSegment = null;
  this._unplayedSegment = null;
};
SpectrogramZoomView.prototype._createWaveform = function() {
  this._waveformLayer = new Konva.Layer({ listening: false });
  this._createWaveformShapes();
  this._stage.add(this._waveformLayer);
  this._peaks.emit("zoomview.displaying", 0, this.getEndTime());
};
SpectrogramZoomView.prototype._createAxisLabels = function() {
  this._axisLayer = new Konva.Layer({ listening: false });
  this._axis = new WaveformAxis(this, this._viewOptions);
  this._axis.addToLayer(this._axisLayer);
  this._stage.add(this._axisLayer);
};
SpectrogramZoomView.prototype.scrollWaveform = function(options) {
  let scrollAmount;
  if (objectHasProperty(options, "pixels")) {
    scrollAmount = Math.floor(options.pixels);
  } else if (objectHasProperty(options, "seconds")) {
    scrollAmount = this.timeToPixels(options.seconds);
  } else {
    throw new TypeError("view.scrollWaveform(): Missing umber of pixels or seconds");
  }
  this.updateWaveform(this._frameOffset + scrollAmount);
};
SpectrogramZoomView.prototype.updateWaveform = function(frameOffset) {
  let upperLimit;
  if (this._pixelLength < this._width) {
    frameOffset = 0;
    upperLimit = this._width;
  } else {
    upperLimit = this._pixelLength - this._width;
  }
  frameOffset = clamp(frameOffset, 0, upperLimit);
  this._frameOffset = frameOffset;
  this._spectrogramImage.update(frameOffset);
  this._spectrogramLayer.draw();
  const playheadPixel = this._playheadLayer.getPlayheadPixel();
  this._playheadLayer.updatePlayheadTime(this.pixelsToTime(playheadPixel));
  this._drawWaveformLayer();
  this._axisLayer.draw();
  const frameStartTime = this.getStartTime();
  const frameEndTime = this.getEndTime();
  if (this._pointsLayer) {
    this._pointsLayer.updatePoints(frameStartTime, frameEndTime);
  }
  if (this._segmentsLayer) {
    this._segmentsLayer.updateSegments(frameStartTime, frameEndTime);
  }
  this._peaks.emit("zoomview.displaying", frameStartTime, frameEndTime);
};
SpectrogramZoomView.prototype._drawWaveformLayer = function() {
  this._waveformLayer.draw();
};
SpectrogramZoomView.prototype.setWaveformColor = function(color) {
  this._waveformColor = color;
  this._waveformShape.setWaveformColor(color);
};
SpectrogramZoomView.prototype.setSpectrogramOpacity = function(opacity) {
  this._spectrogramOpacity = opacity;
  this._spectrogramImage.setOpacity(opacity);
};
SpectrogramZoomView.prototype.setPlayedWaveformColor = function(color) {
  this._playedWaveformColor = color;
  if (color) {
    if (!this._playedWaveformShape) {
      this._createWaveformShapes();
    }
    this._playedWaveformShape.setWaveformColor(color);
  } else {
    if (this._playedWaveformShape) {
      this._destroyPlayedWaveformShape();
    }
  }
};
SpectrogramZoomView.prototype.showPlayheadTime = function(show) {
  this._playheadLayer.showPlayheadTime(show);
};
SpectrogramZoomView.prototype.setTimeLabelPrecision = function(precision) {
  this._timeLabelPrecision = precision;
  this._playheadLayer.updatePlayheadText();
};
SpectrogramZoomView.prototype.formatTime = function(time) {
  return this._formatPlayheadTime(time);
};
SpectrogramZoomView.prototype.showAxisLabels = function(show) {
  this._axis.showAxisLabels(show);
  this._axisLayer.draw();
};
SpectrogramZoomView.prototype.enableAutoScroll = function(enable, options) {
  this._autoScroll = enable;
  if (objectHasProperty(options, "offset")) {
    this._autoScrollOffset = options.offset;
  }
};
SpectrogramZoomView.prototype.enableMarkerEditing = function(enable) {
  if (this._segmentsLayer) {
    this._segmentsLayer.enableEditing(enable);
  }
  if (this._pointsLayer) {
    this._pointsLayer.enableEditing(enable);
  }
};
SpectrogramZoomView.prototype.getMinSegmentDragWidth = function() {
  return this._insertSegmentShape ? 0 : this._minSegmentDragWidth;
};
SpectrogramZoomView.prototype.setMinSegmentDragWidth = function(width) {
  this._minSegmentDragWidth = width;
};
SpectrogramZoomView.prototype.fitToContainer = function() {
  if (this._container.clientWidth === 0 && this._container.clientHeight === 0) {
    return;
  }
  let updateWaveform = false;
  if (this._container.clientWidth !== this._width) {
    this._width = this._container.clientWidth;
    this._stage.width(this._width);
    let resample = false;
    let resampleOptions;
    if (this._zoomLevelAuto) {
      resample = true;
      resampleOptions = { width: this._width };
    } else if (this._zoomLevelSeconds !== null) {
      resample = true;
      resampleOptions = { scale: this._getScale(this._zoomLevelSeconds) };
    }
    if (resample) {
      try {
        this._resampleData(resampleOptions);
        updateWaveform = true;
      } catch (error) {
      }
    }
  }
  this._height = this._container.clientHeight;
  this._stage.height(this._height);
  this._waveformShape.fitToView();
  this._playheadLayer.fitToView();
  if (this._segmentsLayer) {
    this._segmentsLayer.fitToView();
  }
  if (this._pointsLayer) {
    this._pointsLayer.fitToView();
  }
  if (updateWaveform) {
    this.updateWaveform(this._frameOffset);
  }
  this._stage.draw();
};
SpectrogramZoomView.prototype.getStage = function() {
  return this._stage;
};
SpectrogramZoomView.prototype.getSegmentsLayer = function() {
  return this._segmentsLayer;
};
SpectrogramZoomView.prototype.getViewOptions = function() {
  return this._viewOptions;
};
SpectrogramZoomView.prototype.destroy = function() {
  if (this._resizeTimeoutId) {
    clearTimeout(this._resizeTimeoutId);
    this._resizeTimeoutId = null;
  }
  this._peaks.off("player.timeupdate", this._onTimeUpdate);
  this._peaks.off("player.playing", this._onPlaying);
  this._peaks.off("player.pause", this._onPause);
  this._peaks.off("window_resize", this._onWindowResize);
  this._peaks.off("keyboard.left", this._onKeyboardLeft);
  this._peaks.off("keyboard.right", this._onKeyboardRight);
  this._peaks.off("keyboard.shift_left", this._onKeyboardShiftLeft);
  this._peaks.off("keyboard.shift_right", this._onKeyboardShiftRight);
  this._mouseDragHandler.destroy();
  this._playheadLayer.destroy();
  if (this._segmentsLayer) {
    this._segmentsLayer.destroy();
  }
  if (this._pointsLayer) {
    this._pointsLayer.destroy();
  }
  if (this._stage) {
    this._stage.destroy();
    this._stage = null;
  }
};
function Scrollbar(waveformData, container, peaks) {
  this._waveformData = waveformData;
  this._container = container;
  this._peaks = peaks;
  this._options = peaks.options.scrollbar;
  this._zoomview = peaks.views.getView("zoomview");
  this._dragBoundFunc = this._dragBoundFunc.bind(this);
  this._onScrollboxDragStart = this._onScrollboxDragStart.bind(this);
  this._onScrollboxDragMove = this._onScrollboxDragMove.bind(this);
  this._onScrollboxDragEnd = this._onScrollboxDragEnd.bind(this);
  this._onZoomviewDisplaying = this._onZoomviewDisplaying.bind(this);
  this._onScrollbarClick = this._onScrollbarClick.bind(this);
  this._onWindowResize = this._onWindowResize.bind(this);
  peaks.on("zoomview.displaying", this._onZoomviewDisplaying);
  peaks.on("window_resize", this._onWindowResize);
  this._width = container.clientWidth;
  this._height = container.clientHeight;
  this._stage = new Konva.Stage({
    container,
    width: this._width,
    height: this._height
  });
  this._layer = new Konva.Layer();
  this._stage.on("click", this._onScrollbarClick);
  this._stage.add(this._layer);
  this._color = this._options.color;
  this._scrollboxX = 0;
  this._minScrollboxWidth = this._options.minWidth;
  this._offsetY = 0;
  this._scrollbox = new Konva.Group({
    draggable: true,
    dragBoundFunc: this._dragBoundFunc
  });
  this._scrollboxRect = new Rect({
    x: this._scrollboxX,
    y: this._offsetY,
    width: 0,
    height: this._height,
    fill: this._color
  });
  this._scrollbox.add(this._scrollboxRect);
  this._setScrollboxWidth();
  this._scrollbox.on("dragstart", this._onScrollboxDragStart);
  this._scrollbox.on("dragmove", this._onScrollboxDragMove);
  this._scrollbox.on("dragend", this._onScrollboxDragEnd);
  this._layer.add(this._scrollbox);
  this._layer.draw();
}
Scrollbar.prototype.setZoomview = function(zoomview) {
  this._zoomview = zoomview;
  this._updateScrollbarWidthAndPosition();
};
Scrollbar.prototype._setScrollboxWidth = function() {
  if (this._zoomview) {
    this._scrollboxWidth = Math.floor(
      this._width * this._zoomview.pixelsToTime(this._zoomview.getWidth()) / this._peaks.player.getDuration()
    );
    if (this._scrollboxWidth < this._minScrollboxWidth) {
      this._scrollboxWidth = this._minScrollboxWidth;
    }
  } else {
    this._scrollboxWidth = this._width;
  }
  this._scrollboxRect.width(this._scrollboxWidth);
};
Scrollbar.prototype._getScrollbarRange = function() {
  return this._width - this._scrollboxWidth;
};
Scrollbar.prototype._dragBoundFunc = function(pos) {
  return {
    x: pos.x,
    y: 0
  };
};
Scrollbar.prototype._onScrollboxDragStart = function() {
  this._dragging = true;
};
Scrollbar.prototype._onScrollboxDragEnd = function() {
  this._dragging = false;
};
Scrollbar.prototype._onScrollboxDragMove = function() {
  const range = this._getScrollbarRange();
  const x = clamp(this._scrollbox.x(), 0, range);
  this._scrollbox.x(x);
  if (x !== this._scrollboxX) {
    this._scrollboxX = x;
    if (this._zoomview) {
      this._updateWaveform(x);
    }
  }
};
Scrollbar.prototype._onZoomviewDisplaying = function() {
  if (!this._dragging) {
    this._updateScrollbarWidthAndPosition();
  }
};
Scrollbar.prototype._updateScrollbarWidthAndPosition = function() {
  this._setScrollboxWidth();
  if (this._zoomview) {
    const startTime = this._zoomview.getStartTime();
    const zoomviewRange = this._zoomview.getPixelLength() - this._zoomview.getWidth();
    const scrollBoxPos = Math.floor(
      this._zoomview.timeToPixels(startTime) * this._getScrollbarRange() / zoomviewRange
    );
    this._scrollbox.x(scrollBoxPos);
    this._layer.draw();
  }
};
Scrollbar.prototype._onScrollbarClick = function(event) {
  if (event.target === this._stage) {
    if (this._zoomview) {
      let x = Math.floor(event.evt.layerX - this._scrollboxWidth / 2);
      if (x < 0) {
        x = 0;
      }
      this._updateWaveform(x);
    }
  }
};
Scrollbar.prototype._updateWaveform = function(x) {
  const offset = Math.floor(
    (this._zoomview.getPixelLength() - this._zoomview.getWidth()) * x / this._getScrollbarRange()
  );
  this._zoomview.updateWaveform(offset);
};
Scrollbar.prototype._onWindowResize = function() {
  this._width = this._container.clientWidth;
  this._stage.width(this._width);
  this._updateScrollbarWidthAndPosition();
};
Scrollbar.prototype.fitToContainer = function() {
  if (this._container.clientWidth === 0 && this._container.clientHeight === 0) {
    return;
  }
  if (this._container.clientWidth !== this._width) {
    this._width = this._container.clientWidth;
    this._stage.width(this._width);
  }
  this._height = this._container.clientHeight;
  this._stage.height(this._height);
  this._stage.draw();
};
Scrollbar.prototype.destroy = function() {
  this._peaks.off("window_resize", this._onWindowResize);
  this._layer.destroy();
  this._stage.destroy();
  this._stage = null;
};
function ViewController(peaks) {
  this._peaks = peaks;
  this._overview = null;
  this._spectrogramOverview = null;
  this._zoomview = null;
  this._spectrogramZoomview = null;
  this._scrollbar = null;
}
ViewController.prototype.createOverview = function(container) {
  if (this._overview) {
    return this._overview;
  }
  const waveformData = this._peaks.getWaveformData();
  this._overview = new WaveformOverview(
    waveformData,
    container,
    this._peaks
  );
  if (this._zoomview) {
    this._overview.showHighlight(
      this._zoomview.getStartTime(),
      this._zoomview.getEndTime()
    );
  }
  return this._overview;
};
ViewController.prototype.createSpectrogramOverview = function(container) {
  if (this._spectrogramOverview) {
    return this._spectrogramOverview;
  }
  const waveformData = this._peaks.getWaveformData();
  const spectrogramData = this._peaks.getSpectrogramData();
  this._spectrogramOverview = new SpectrogramOverview(
    waveformData,
    spectrogramData,
    container,
    this._peaks
  );
  if (this._zoomview) {
    this._spectrogramOverview.showHighlight(
      this._zoomview.getStartTime(),
      this._zoomview.getEndTime()
    );
  }
  return this._spectrogramOverview;
};
ViewController.prototype.createZoomview = function(container) {
  if (this._zoomview) {
    return this._zoomview;
  }
  const waveformData = this._peaks.getWaveformData();
  this._zoomview = new WaveformZoomView(
    waveformData,
    container,
    this._peaks
  );
  if (this._scrollbar) {
    this._scrollbar.setZoomview(this._zoomview);
  }
  return this._zoomview;
};
ViewController.prototype.createSpectrogramZoomview = function(container) {
  if (this._spectrogramZoomview) {
    return this._spectrogramZoomview;
  }
  const waveformData = this._peaks.getWaveformData();
  const spectrogramData = this._peaks.getSpectrogramData();
  this._spectrogramZoomview = new SpectrogramZoomView(
    waveformData,
    spectrogramData,
    container,
    this._peaks
  );
  if (this._scrollbar) {
    this._scrollbar.setZoomview(this._spectrogramZoomview);
  }
  return this._spectrogramZoomview;
};
ViewController.prototype.createScrollbar = function(container) {
  const waveformData = this._peaks.getWaveformData();
  this._scrollbar = new Scrollbar(
    waveformData,
    container,
    this._peaks
  );
  return this._scrollbar;
};
ViewController.prototype.destroyOverview = function() {
  if (!this._overview) {
    return;
  }
  if (!this._zoomview) {
    return;
  }
  this._overview.destroy();
  this._overview = null;
};
ViewController.prototype.destroySpectrogramOverview = function() {
  if (!this._spectrogramOverview) {
    return;
  }
  if (!this._zoomview) {
    return;
  }
  this._spectrogramOverview.destroy();
  this._spectrogramOverview = null;
};
ViewController.prototype.destroyZoomview = function() {
  if (!this._zoomview) {
    return;
  }
  if (!this._overview) {
    return;
  }
  this._zoomview.destroy();
  this._zoomview = null;
  this._overview.removeHighlightRect();
};
ViewController.prototype.destroySpectrogramZoomview = function() {
  if (!this._spectrogramZoomview) {
    return;
  }
  if (!this._spectrogramOverview) {
    return;
  }
  this._spectrogramZoomview.destroy();
  this._spectrogramZoomview = null;
  this._overview.removeHighlightRect();
};
ViewController.prototype.destroy = function() {
  if (this._overview) {
    this._overview.destroy();
    this._overview = null;
  }
  if (this._spectrogramOverview) {
    this._spectrogramOverview.destroy();
    this._spectrogramOverview = null;
  }
  if (this._zoomview) {
    this._zoomview.destroy();
    this._zoomview = null;
  }
  if (this._spectrogramZoomview) {
    this._spectrogramZoomview.destroy();
    this._spectrogramZoomview = null;
  }
  if (this._scrollbar) {
    this._scrollbar.destroy();
    this._scrollbar = null;
  }
};
ViewController.prototype.getView = function(name) {
  if (isNullOrUndefined(name)) {
    if (this._overview && this._zoomview) {
      return null;
    } else if (this._overview) {
      return this._overview;
    } else if (this._spectrogramOverview) {
      return this._spectrogramOverview;
    } else if (this._zoomview) {
      return this._zoomview;
    } else if (this._spectrogramZoomview) {
      return this._spectrogramZoomview;
    } else {
      return null;
    }
  } else {
    switch (name) {
      case "overview":
        return this._overview;
      case "spectrogramOverview":
        return this._spectrogramOverview;
      case "zoomview":
        return this._zoomview;
      case "spectrogramZoomview":
        return this._spectrogramZoomview;
      default:
        return null;
    }
  }
};
function ZoomController(peaks, zoomLevels) {
  this._peaks = peaks;
  this._zoomLevels = zoomLevels;
  this._zoomLevelIndex = 0;
}
ZoomController.prototype.setZoomLevels = function(zoomLevels) {
  this._zoomLevels = zoomLevels;
  this.setZoom(0, true);
};
ZoomController.prototype.zoomIn = function() {
  this.setZoom(this._zoomLevelIndex - 1);
};
ZoomController.prototype.zoomOut = function() {
  this.setZoom(this._zoomLevelIndex + 1);
};
ZoomController.prototype.setZoom = function(zoomLevelIndex, forceUpdate) {
  if (zoomLevelIndex >= this._zoomLevels.length) {
    zoomLevelIndex = this._zoomLevels.length - 1;
  }
  if (zoomLevelIndex < 0) {
    zoomLevelIndex = 0;
  }
  if (!forceUpdate && zoomLevelIndex === this._zoomLevelIndex) {
    return;
  }
  this._zoomLevelIndex = zoomLevelIndex;
  const zoomview = this._peaks.views.getView("zoomview");
  if (zoomview !== null) {
    zoomview.setZoom({ scale: this._zoomLevels[zoomLevelIndex] });
  }
  const spectrogramZoomview = this._peaks.views.getView("spectrogramZoomview");
  if (spectrogramZoomview !== null) {
    spectrogramZoomview.setZoom({ scale: this._zoomLevels[zoomLevelIndex] });
  }
};
ZoomController.prototype.getZoom = function() {
  return this._zoomLevelIndex;
};
ZoomController.prototype.getZoomLevel = function() {
  return this._zoomLevels[this._zoomLevelIndex];
};
const isXhr2 = "withCredentials" in new XMLHttpRequest();
function WaveformBuilder(peaks) {
  this._peaks = peaks;
}
WaveformBuilder.prototype.init = function(options, callback) {
  if (options.dataUri && (options.webAudio || options.audioContext) || options.waveformData && (options.webAudio || options.audioContext) || options.dataUri && options.waveformData) {
    callback(new TypeError("Peaks.init(): You may only pass one source (webAudio, dataUri, or waveformData) to render waveform data."));
    return;
  }
  if (options.audioContext) {
    this._peaks._logger("Peaks.init(): The audioContext option is deprecated, please pass a webAudio object instead");
    options.webAudio = {
      audioContext: options.audioContext
    };
  }
  if (options.dataUri) {
    return this._getRemoteWaveformData(options, callback);
  } else if (options.waveformData) {
    return this._buildWaveformFromLocalData(options, callback);
  } else if (options.webAudio) {
    if (options.webAudio.audioBuffer) {
      return this._buildWaveformDataFromAudioBuffer(options, callback);
    } else {
      return this._buildWaveformDataUsingWebAudio(options, callback);
    }
  } else {
    callback(new Error("Peaks.init(): You must pass an audioContext, or dataUri, or waveformData to render waveform data"));
  }
};
WaveformBuilder.prototype._getRemoteWaveformData = function(options, callback) {
  const self = this;
  let dataUri = null;
  let requestType = null;
  let url;
  if (isObject(options.dataUri)) {
    dataUri = options.dataUri;
  } else {
    callback(new TypeError("Peaks.init(): The dataUri option must be an object"));
    return;
  }
  ["ArrayBuffer", "JSON"].some(function(connector) {
    if (window[connector]) {
      requestType = connector.toLowerCase();
      url = dataUri[requestType];
      return Boolean(url);
    }
  });
  if (!url) {
    callback(new Error("Peaks.init(): Unable to determine a compatible dataUri format for this browser"));
    return;
  }
  self._xhr = self._createXHR(
    url,
    requestType,
    options.withCredentials,
    function(event) {
      if (this.readyState !== 4) {
        return;
      }
      if (this.status !== 200) {
        callback(
          new Error("Unable to fetch remote data. HTTP status " + this.status)
        );
        return;
      }
      self._xhr = null;
      const waveformData = WaveformData.create(event.target.response);
      if (waveformData.channels !== 1 && waveformData.channels !== 2) {
        callback(new Error("Peaks.init(): Only mono or stereo waveforms are currently supported"));
        return;
      } else if (waveformData.bits !== 8) {
        callback(new Error("Peaks.init(): 16-bit waveform data is not supported"));
        return;
      }
      callback(null, waveformData);
    },
    function() {
      callback(new Error("XHR failed"));
    },
    function() {
      callback(new Error("XHR aborted"));
    }
  );
  self._xhr.send();
};
WaveformBuilder.prototype._buildWaveformFromLocalData = function(options, callback) {
  let waveformData = null;
  let data = null;
  if (isObject(options.waveformData)) {
    waveformData = options.waveformData;
  } else {
    callback(new Error("Peaks.init(): The waveformData option must be an object"));
    return;
  }
  if (isObject(waveformData.json)) {
    data = waveformData.json;
  } else if (isArrayBuffer(waveformData.arraybuffer)) {
    data = waveformData.arraybuffer;
  }
  if (!data) {
    callback(new Error("Peaks.init(): Unable to determine a compatible waveformData format"));
    return;
  }
  try {
    const createdWaveformData = WaveformData.create(data);
    if (createdWaveformData.channels !== 1 && createdWaveformData.channels !== 2) {
      callback(new Error("Peaks.init(): Only mono or stereo waveforms are currently supported"));
      return;
    } else if (createdWaveformData.bits !== 8) {
      callback(new Error("Peaks.init(): 16-bit waveform data is not supported"));
      return;
    }
    callback(null, createdWaveformData);
  } catch (err) {
    callback(err);
  }
};
WaveformBuilder.prototype._buildWaveformDataUsingWebAudio = function(options, callback) {
  const self = this;
  const audioContext = window.AudioContext || window.webkitAudioContext;
  if (!(options.webAudio.audioContext instanceof audioContext)) {
    callback(new TypeError("Peaks.init(): The webAudio.audioContext option must be a valid AudioContext"));
    return;
  }
  const webAudioOptions = options.webAudio;
  if (webAudioOptions.scale !== options.zoomLevels[0]) {
    webAudioOptions.scale = options.zoomLevels[0];
  }
  const mediaSourceUrl = self._peaks.options.mediaElement.currentSrc;
  if (mediaSourceUrl) {
    self._requestAudioAndBuildWaveformData(
      mediaSourceUrl,
      webAudioOptions,
      options.withCredentials,
      callback
    );
  } else {
    self._peaks.once("player.canplay", function() {
      self._requestAudioAndBuildWaveformData(
        self._peaks.options.mediaElement.currentSrc,
        webAudioOptions,
        options.withCredentials,
        callback
      );
    });
  }
};
WaveformBuilder.prototype._buildWaveformDataFromAudioBuffer = function(options, callback) {
  const webAudioOptions = options.webAudio;
  if (webAudioOptions.scale !== options.zoomLevels[0]) {
    webAudioOptions.scale = options.zoomLevels[0];
  }
  const webAudioBuilderOptions = {
    audio_buffer: webAudioOptions.audioBuffer,
    split_channels: webAudioOptions.multiChannel,
    scale: webAudioOptions.scale,
    disable_worker: true
  };
  WaveformData.createFromAudio(webAudioBuilderOptions, callback);
};
WaveformBuilder.prototype._requestAudioAndBuildWaveformData = function(url, webAudio, withCredentials, callback) {
  const self = this;
  if (!url) {
    self._peaks._logger("Peaks.init(): The mediaElement src is invalid");
    return;
  }
  self._xhr = self._createXHR(
    url,
    "arraybuffer",
    withCredentials,
    function(event) {
      if (this.readyState !== 4) {
        return;
      }
      if (this.status !== 200) {
        callback(
          new Error("Unable to fetch remote data. HTTP status " + this.status)
        );
        return;
      }
      self._xhr = null;
      const webAudioBuilderOptions = {
        audio_context: webAudio.audioContext,
        array_buffer: event.target.response,
        split_channels: webAudio.multiChannel,
        scale: webAudio.scale
      };
      WaveformData.createFromAudio(webAudioBuilderOptions, callback);
    },
    function() {
      callback(new Error("XHR failed"));
    },
    function() {
      callback(new Error("XHR aborted"));
    }
  );
  self._xhr.send();
};
WaveformBuilder.prototype.abort = function() {
  if (this._xhr) {
    this._xhr.abort();
  }
};
WaveformBuilder.prototype._createXHR = function(url, requestType, withCredentials, onLoad, onError, onAbort) {
  const xhr = new XMLHttpRequest();
  xhr.open("GET", url, true);
  if (isXhr2) {
    try {
      xhr.responseType = requestType;
    } catch (e) {
    }
  }
  xhr.onload = onLoad;
  xhr.onerror = onError;
  if (isXhr2 && withCredentials) {
    xhr.withCredentials = true;
  }
  xhr.addEventListener("abort", onAbort);
  return xhr;
};
function Peaks() {
  EventEmitter.call(this);
  this.options = {
    zoomLevels: [512, 1024, 2048, 4096],
    waveformCache: true,
    mediaElement: null,
    mediaUrl: null,
    dataUri: null,
    withCredentials: false,
    waveformData: null,
    spectrogramData: { image: "", crop: {} },
    webAudio: null,
    nudgeIncrement: 1,
    pointMarkerColor: "#39cccc",
    createSegmentMarker,
    createSegmentLabel,
    createPointMarker,
    logger: console.error.bind(console)
  };
  return this;
}
Peaks.prototype = Object.create(EventEmitter.prototype);
const defaultViewOptions = {
  playheadColor: "#111111",
  playheadTextColor: "#aaaaaa",
  axisGridlineColor: "#cccccc",
  showAxisLabels: true,
  axisLabelColor: "#aaaaaa",
  fontFamily: "sans-serif",
  fontSize: 11,
  fontStyle: "normal",
  timeLabelPrecision: 2,
  enablePoints: true,
  enableSegments: true
};
const defaultZoomviewOptions = {
  playheadClickTolerance: 3,
  waveformColor: "rgba(0, 225, 128, 1)",
  wheelMode: "none",
  autoScroll: true,
  autoScrollOffset: 100
};
const defaultSpectrogramZoomviewOptions = {
  playheadClickTolerance: 3,
  waveformColor: "rgba(0, 225, 128, 1)",
  wheelMode: "none",
  autoScroll: true,
  autoScrollOffset: 100,
  spectrogramOpacity: 1
};
const defaultOverviewOptions = {
  waveformColor: "rgba(0, 0, 0, 0.2)",
  highlightColor: "#aaaaaa",
  highlightStrokeColor: "transparent",
  highlightOpacity: 0.3,
  highlightOffset: 11,
  highlightCornerRadius: 2
};
const defaultSpectrogramOverviewOptions = {
  waveformColor: "rgba(0, 0, 0, 0.2)",
  highlightColor: "#aaaaaa",
  highlightStrokeColor: "transparent",
  highlightOpacity: 0.3,
  highlightOffset: 11,
  highlightCornerRadius: 2,
  spectrogramOpacity: 1
};
const defaultSegmentOptions = {
  overlay: false,
  markers: true,
  startMarkerColor: "#aaaaaa",
  endMarkerColor: "#aaaaaa",
  waveformColor: "#0074d9",
  overlayColor: "#ff0000",
  overlayOpacity: 0.3,
  overlayBorderColor: "#ff0000",
  overlayBorderWidth: 2,
  overlayCornerRadius: 5,
  overlayOffset: 25,
  overlayLabelAlign: "left",
  overlayLabelVerticalAlign: "top",
  overlayLabelPadding: 8,
  overlayLabelColor: "#000000",
  overlayFontFamily: "sans-serif",
  overlayFontSize: 12,
  overlayFontStyle: "normal"
};
const defaultScrollbarOptions = {
  color: "#888888",
  minWidth: 50
};
function getOverviewOptions(opts) {
  const overviewOptions = {};
  if (opts.overview && opts.overview.showPlayheadTime) {
    overviewOptions.showPlayheadTime = opts.overview.showPlayheadTime;
  }
  const optNames = [
    "container",
    "waveformColor",
    "playedWaveformColor",
    "playheadColor",
    "playheadTextColor",
    "formatPlayheadTime",
    "timeLabelPrecision",
    "axisGridlineColor",
    "showAxisLabels",
    "axisLabelColor",
    "formatAxisTime",
    "fontFamily",
    "fontSize",
    "fontStyle",
    "highlightColor",
    "highlightStrokeColor",
    "highlightOpacity",
    "highlightCornerRadius",
    "highlightOffset",
    "enablePoints",
    "enableSegments"
  ];
  optNames.forEach(function(optName) {
    if (opts.overview && objectHasProperty(opts.overview, optName)) {
      overviewOptions[optName] = opts.overview[optName];
    } else if (objectHasProperty(opts, optName)) {
      overviewOptions[optName] = opts[optName];
    } else if (!objectHasProperty(overviewOptions, optName)) {
      if (objectHasProperty(defaultOverviewOptions, optName)) {
        overviewOptions[optName] = defaultOverviewOptions[optName];
      } else if (objectHasProperty(defaultViewOptions, optName)) {
        overviewOptions[optName] = defaultViewOptions[optName];
      }
    }
  });
  return overviewOptions;
}
function getSpectrogramOverviewOptions(opts) {
  const spectrogramOverviewOptions = {};
  if (opts.spectrogramOverview && opts.spectrogramOverview.showPlayheadTime) {
    spectrogramOverviewOptions.showPlayheadTime = opts.spectrogramOverview.showPlayheadTime;
  }
  const optNames = [
    "container",
    "waveformColor",
    "playedWaveformColor",
    "playheadColor",
    "playheadTextColor",
    "formatPlayheadTime",
    "timeLabelPrecision",
    "axisGridlineColor",
    "showAxisLabels",
    "axisLabelColor",
    "formatAxisTime",
    "fontFamily",
    "fontSize",
    "fontStyle",
    "highlightColor",
    "highlightStrokeColor",
    "highlightOpacity",
    "highlightCornerRadius",
    "highlightOffset",
    "enablePoints",
    "enableSegments",
    "spectrogramOpacity"
  ];
  optNames.forEach(function(optName) {
    if (opts.spectrogramOverview && objectHasProperty(opts.spectrogramOverview, optName)) {
      spectrogramOverviewOptions[optName] = opts.spectrogramOverview[optName];
    } else if (objectHasProperty(opts, optName)) {
      spectrogramOverviewOptions[optName] = opts[optName];
    } else if (!objectHasProperty(spectrogramOverviewOptions, optName)) {
      if (objectHasProperty(defaultSpectrogramOverviewOptions, optName)) {
        spectrogramOverviewOptions[optName] = defaultSpectrogramOverviewOptions[optName];
      } else if (objectHasProperty(defaultViewOptions, optName)) {
        spectrogramOverviewOptions[optName] = defaultViewOptions[optName];
      }
    }
  });
  return spectrogramOverviewOptions;
}
function getZoomviewOptions(opts) {
  const zoomviewOptions = {};
  if (opts.showPlayheadTime) {
    zoomviewOptions.showPlayheadTime = opts.showPlayheadTime;
  } else if (opts.zoomview && opts.zoomview.showPlayheadTime) {
    zoomviewOptions.showPlayheadTime = opts.zoomview.showPlayheadTime;
  }
  const optNames = [
    "container",
    "waveformColor",
    "playedWaveformColor",
    "playheadColor",
    "playheadTextColor",
    "formatPlayheadTime",
    "playheadClickTolerance",
    "timeLabelPrecision",
    "axisGridlineColor",
    "showAxisLabels",
    "axisLabelColor",
    "formatAxisTime",
    "fontFamily",
    "fontSize",
    "fontStyle",
    "wheelMode",
    "autoScroll",
    "autoScrollOffset",
    "enablePoints",
    "enableSegments"
  ];
  optNames.forEach(function(optName) {
    if (opts.zoomview && objectHasProperty(opts.zoomview, optName)) {
      zoomviewOptions[optName] = opts.zoomview[optName];
    } else if (objectHasProperty(opts, optName)) {
      zoomviewOptions[optName] = opts[optName];
    } else if (!objectHasProperty(zoomviewOptions, optName)) {
      if (objectHasProperty(defaultZoomviewOptions, optName)) {
        zoomviewOptions[optName] = defaultZoomviewOptions[optName];
      } else if (objectHasProperty(defaultViewOptions, optName)) {
        zoomviewOptions[optName] = defaultViewOptions[optName];
      }
    }
  });
  return zoomviewOptions;
}
function getSpectrogramZoomviewOptions(opts) {
  const spectrogramZoomviewOptions = {};
  if (opts.showPlayheadTime) {
    spectrogramZoomviewOptions.showPlayheadTime = opts.showPlayheadTime;
  } else if (opts.spectrogramZoomview && opts.spectrogramZoomview.showPlayheadTime) {
    spectrogramZoomviewOptions.showPlayheadTime = opts.spectrogramZoomview.showPlayheadTime;
  }
  const optNames = [
    "container",
    "waveformColor",
    "playedWaveformColor",
    "playheadColor",
    "playheadTextColor",
    "formatPlayheadTime",
    "playheadClickTolerance",
    "timeLabelPrecision",
    "axisGridlineColor",
    "showAxisLabels",
    "axisLabelColor",
    "formatAxisTime",
    "fontFamily",
    "fontSize",
    "fontStyle",
    "wheelMode",
    "autoScroll",
    "autoScrollOffset",
    "enablePoints",
    "enableSegments",
    "spectrogramOpacity"
  ];
  optNames.forEach(function(optName) {
    if (opts.spectrogramZoomview && objectHasProperty(opts.spectrogramZoomview, optName)) {
      spectrogramZoomviewOptions[optName] = opts.spectrogramZoomview[optName];
    } else if (objectHasProperty(opts, optName)) {
      spectrogramZoomviewOptions[optName] = opts[optName];
    } else if (!objectHasProperty(spectrogramZoomviewOptions, optName)) {
      if (objectHasProperty(defaultSpectrogramZoomviewOptions, optName)) {
        spectrogramZoomviewOptions[optName] = defaultSpectrogramZoomviewOptions[optName];
      } else if (objectHasProperty(defaultViewOptions, optName)) {
        spectrogramZoomviewOptions[optName] = defaultViewOptions[optName];
      }
    }
  });
  return spectrogramZoomviewOptions;
}
function getScrollbarOptions(opts) {
  if (!objectHasProperty(opts, "scrollbar")) {
    return null;
  }
  const scrollbarOptions = {};
  const optNames = [
    "container",
    "color",
    "minWidth"
  ];
  optNames.forEach(function(optName) {
    if (objectHasProperty(opts.scrollbar, optName)) {
      scrollbarOptions[optName] = opts.scrollbar[optName];
    } else {
      scrollbarOptions[optName] = defaultScrollbarOptions[optName];
    }
  });
  return scrollbarOptions;
}
function extendOptions(to, from) {
  for (const key in from) {
    if (objectHasProperty(from, key) && objectHasProperty(to, key)) {
      to[key] = from[key];
    }
  }
  return to;
}
function addSegmentOptions(options, opts) {
  options.segmentOptions = {};
  extend(options.segmentOptions, defaultSegmentOptions);
  if (opts.segmentOptions) {
    extendOptions(options.segmentOptions, opts.segmentOptions);
  }
  options.zoomview.segmentOptions = {};
  extend(options.zoomview.segmentOptions, options.segmentOptions);
  if (opts.zoomview && opts.zoomview.segmentOptions) {
    extendOptions(options.zoomview.segmentOptions, opts.zoomview.segmentOptions);
  }
  options.overview.segmentOptions = {};
  extend(options.overview.segmentOptions, options.segmentOptions);
  if (opts.overview && opts.overview.segmentOptions) {
    extendOptions(options.overview.segmentOptions, opts.overview.segmentOptions);
  }
  options.spectrogramOverview.segmentOptions = {};
  extend(options.spectrogramOverview.segmentOptions, options.segmentOptions);
  if (opts.spectrogramOverview && opts.spectrogramOverview.segmentOptions) {
    extendOptions(options.spectrogramOverview.segmentOptions, opts.spectrogramOverview.segmentOptions);
  }
  options.spectrogramZoomview.segmentOptions = {};
  extend(options.spectrogramZoomview.segmentOptions, options.segmentOptions);
  if (opts.spectrogramZoomview && opts.spectrogramZoomview.segmentOptions) {
    extendOptions(options.spectrogramZoomview.segmentOptions, opts.spectrogramZoomview.segmentOptions);
  }
}
function checkContainerElements(options) {
  let zoomviewContainer = options.zoomview.container;
  let overviewContainer = options.overview.container;
  if (!zoomviewContainer) {
    zoomviewContainer = options.spectrogramZoomview.container;
  }
  if (!overviewContainer) {
    overviewContainer = options.spectrogramOverview.container;
  }
  if (!isHTMLElement(zoomviewContainer) && !isHTMLElement(overviewContainer)) {
    return new TypeError("Peaks.init(): The zoomview and/or overview container options must be valid HTML elements");
  }
  if (zoomviewContainer && (zoomviewContainer.clientWidth <= 0 || zoomviewContainer.clientHeight <= 0)) {
    return new Error("Peaks.init(): Please ensure that the zoomview container is visible and has non-zero width and height");
  }
  if (overviewContainer && (overviewContainer.clientWidth <= 0 || overviewContainer.clientHeight <= 0)) {
    return new Error("Peaks.init(): Please ensure that the overview container is visible and has non-zero width and height");
  }
}
Peaks.init = function(opts, callback) {
  const instance = new Peaks();
  let err = instance._setOptions(opts);
  if (err) {
    callback(err);
    return;
  }
  err = checkContainerElements(instance.options);
  if (err) {
    callback(err);
    return;
  }
  let scrollbarContainer = null;
  if (instance.options.scrollbar) {
    scrollbarContainer = instance.options.scrollbar.container;
    if (!isHTMLElement(scrollbarContainer)) {
      callback(new TypeError("Peaks.init(): The scrollbar container option must be a valid HTML element"));
      return;
    }
    if (scrollbarContainer.clientWidth <= 0) {
      callback(new TypeError("Peaks.init(): Please ensure that the scrollbar container is visible and has non-zero width"));
      return;
    }
  }
  if (opts.keyboard) {
    instance._keyboardHandler = new KeyboardHandler(instance);
  }
  const player = opts.player ? opts.player : new MediaElementPlayer(instance.options.mediaElement);
  instance.player = new Player(instance, player);
  instance.segments = new WaveformSegments(instance);
  instance.points = new WaveformPoints(instance);
  instance.zoom = new ZoomController(instance, instance.options.zoomLevels);
  instance.views = new ViewController(instance);
  instance._waveformBuilder = new WaveformBuilder(instance);
  instance.player.init(instance).then(function() {
    instance._waveformBuilder.init(instance.options, function(err2, waveformData) {
      if (err2) {
        if (callback) {
          callback(err2);
        }
        return;
      }
      err2 = checkContainerElements(instance.options);
      if (err2) {
        if (callback) {
          callback(err2);
        }
        return;
      }
      instance._waveformBuilder = null;
      instance._waveformData = waveformData;
      instance._spectrogramData = instance.options.spectrogramData;
      const zoomviewContainer = instance.options.zoomview.container;
      const spectrogramZoomviewContainer = instance.options.spectrogramZoomview.container;
      const overviewContainer = instance.options.overview.container;
      const spectrogramOverviewContainer = instance.options.spectrogramOverview.container;
      if (overviewContainer) {
        instance.views.createOverview(overviewContainer);
      }
      if (spectrogramOverviewContainer) {
        instance.views.createSpectrogramOverview(spectrogramOverviewContainer);
      }
      if (zoomviewContainer) {
        instance.views.createZoomview(zoomviewContainer);
      }
      if (spectrogramZoomviewContainer) {
        instance.views.createSpectrogramZoomview(spectrogramZoomviewContainer);
      }
      if (scrollbarContainer) {
        instance.views.createScrollbar(scrollbarContainer);
      }
      instance._addWindowResizeHandler();
      if (opts.segments) {
        instance.segments.add(opts.segments);
      }
      if (opts.points) {
        instance.points.add(opts.points);
      }
      if (opts.emitCueEvents) {
        instance._cueEmitter = new CueEmitter(instance);
      }
      setTimeout(function() {
        instance.emit("peaks.ready");
      }, 0);
      callback(null, instance);
    });
  }).catch(function(err2) {
    if (callback) {
      callback(err2);
    }
  });
  return instance;
};
Peaks.prototype._setOptions = function(opts) {
  if (!isObject(opts)) {
    return new TypeError("Peaks.init(): The options parameter should be an object");
  }
  if (!opts.player) {
    if (!opts.mediaElement) {
      return new Error("Peaks.init(): Missing mediaElement option");
    }
    if (!(opts.mediaElement instanceof HTMLMediaElement)) {
      return new TypeError("Peaks.init(): The mediaElement option should be an HTMLMediaElement");
    }
  }
  if (opts.logger && !isFunction(opts.logger)) {
    return new TypeError("Peaks.init(): The logger option should be a function");
  }
  if (opts.segments && !Array.isArray(opts.segments)) {
    return new TypeError("Peaks.init(): options.segments must be an array of segment objects");
  }
  if (opts.points && !Array.isArray(opts.points)) {
    return new TypeError("Peaks.init(): options.points must be an array of point objects");
  }
  extendOptions(this.options, opts);
  this.options.overview = getOverviewOptions(opts);
  this.options.spectrogramOverview = getSpectrogramOverviewOptions(opts);
  this.options.zoomview = getZoomviewOptions(opts);
  this.options.spectrogramZoomview = getSpectrogramZoomviewOptions(opts);
  this.options.scrollbar = getScrollbarOptions(opts);
  addSegmentOptions(this.options, opts);
  if (!Array.isArray(this.options.zoomLevels)) {
    return new TypeError("Peaks.init(): The zoomLevels option should be an array");
  } else if (this.options.zoomLevels.length === 0) {
    return new Error("Peaks.init(): The zoomLevels array must not be empty");
  } else {
    if (!isInAscendingOrder(this.options.zoomLevels)) {
      return new Error("Peaks.init(): The zoomLevels array must be sorted in ascending order");
    }
  }
  this._logger = this.options.logger;
};
Peaks.prototype.setSource = function(options, callback) {
  const self = this;
  self.player._setSource(options).then(function() {
    if (!options.zoomLevels) {
      options.zoomLevels = self.options.zoomLevels;
    }
    self._waveformBuilder = new WaveformBuilder(self);
    self._waveformBuilder.init(options, function(err, waveformData) {
      if (err) {
        callback(err);
        return;
      }
      self._waveformBuilder = null;
      self._waveformData = waveformData;
      ["overview", "zoomview"].forEach(function(viewName) {
        const view = self.views.getView(viewName);
        if (view) {
          view.setWaveformData(waveformData);
        }
      });
      ["spectrogramOverview", "spectrogramZoomview"].forEach(function(viewName) {
        const view = self.views.getView(viewName);
        if (view) {
          view.setWaveformData(waveformData);
          view.setSpectrogramData(options.spectrogramData);
        }
      });
      self.zoom.setZoomLevels(options.zoomLevels);
      callback();
    });
  }).catch(function(err) {
    callback(err);
  });
};
Peaks.prototype.getWaveformData = function() {
  return this._waveformData;
};
Peaks.prototype.getSpectrogramData = function() {
  return this._spectrogramData;
};
Peaks.prototype._addWindowResizeHandler = function() {
  this._onResize = this._onResize.bind(this);
  window.addEventListener("resize", this._onResize);
};
Peaks.prototype._onResize = function() {
  this.emit("window_resize");
};
Peaks.prototype._removeWindowResizeHandler = function() {
  window.removeEventListener("resize", this._onResize);
};
Peaks.prototype.destroy = function() {
  this._removeWindowResizeHandler();
  if (this._waveformBuilder) {
    this._waveformBuilder.abort();
  }
  if (this._keyboardHandler) {
    this._keyboardHandler.destroy();
  }
  if (this.views) {
    this.views.destroy();
  }
  if (this.player) {
    this.player.destroy();
  }
  if (this._cueEmitter) {
    this._cueEmitter.destroy();
  }
};
export { Peaks as default };
