import { client, handleError } from './client'

const debug = process.env.NODE_ENV !== 'production'

const Auth = {
    async login(data, resolve, reject) {
        client.post('token/', data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    },

};

export {
    Auth
}
