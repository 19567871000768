<template>
  <div v-if="data">
    <h2>Annotations</h2>

    <n-space vertical justify="center" align="center" style="margin-top: 50px">
      <n-input-group>
        <n-tag :bordered="false" size="large" round
          >&nbsp;Filter category</n-tag
        >
        <n-form label-placement="left" style="width: 400px">
          <n-select
            :default-value="selectedCategories"
            :options="categoriesOptions"
            size="medium"
            :consistent-menu-width="false"
            multiple
            placement="bottom-start"
            @update:value="onChangeCategory"
          />
        </n-form>
      </n-input-group>
      <n-input-group>
        <n-tag :bordered="false" size="large" round
          >&nbsp;Filter keywords</n-tag
        >
        <n-form label-placement="left" style="width: 400px">
          <n-select
            :default-value="selectedKeywords"
            :options="keywordsOptions"
            :consistent-menu-width="false"
            size="medium"
            multiple
            @update:value="onChangeKeyword"
            filterable
            placement="bottom-start"
          />
        </n-form>
      </n-input-group>
    </n-space>
    <AnnotationListView
      :annotations="data"
      :withEdit="false"
      @on-view-clicked="onAnnotationViewClicked"
      style="margin-top: 50px"
    />
  </div>
</template>

<script setup>
import { ref, reactive, computed, onMounted, watch } from 'vue';
import { useRouter, useRoute, onBeforeRouteUpdate } from 'vue-router';

import {
  NForm,
  NSpace,
  NSelect,
  NInputGroup,
  NGrid,
  NGi,
  NTag,
  NButton,
  NRadioGroup,
  NRadioButton,
  NImage,
  NEllipsis,
  NH2,
  NH5,
} from 'naive-ui';

import AnnotationListView from '@/views/AnnotationListView.vue';
import { Annotation } from '@/api/archive';
import { Category, Keyword } from '@/api/classification';

const debug = process.env.NODE_ENV !== 'production';

const props = defineProps({
  query: {
    type: Object,
  },
});

const loading = ref(false);
const data = ref(null);

const router = useRouter();
const route = useRoute();

const categories = ref([]);

const selectedCategories = computed(() => {
  if (
    props.query !== undefined &&
    props.query.category !== undefined &&
    props.query.category !== ''
  ) {
    return props.query.category.split(',');
  }
});

const keywords = ref([]);

const selectedKeywords = computed(() => {
  if (
    props.query !== undefined &&
    props.query.keyword !== undefined &&
    props.query.keyword !== ''
  ) {
    return props.query.keyword.split(',');
  }
});

onBeforeRouteUpdate((to, from) => {
  fetchData(to.query);
});

async function updateRoute(q) {
  let query = { ...route.query, ...q };
  await router.push({ query: query });
}

const onChangeCategory = async (value) => {
  updateRoute({ category: value.join(',') });
};

const onChangeKeyword = async (value) => {
  updateRoute({ keyword: value.join(',') });
};

const categoriesOptions = computed(() => {
  if (categories.value === null) {
    return [];
  }
  return categories.value.map((c) => ({
    label: c.name,
    value: c.value,
  }));
});

const keywordsOptions = computed(() => {
  if (keywords.value === null) {
    return [];
  }
  return keywords.value.map((k) => ({
    label: k.name,
    value: k.slug,
  }));
});

async function fetchData(query) {
  loading.value = true;
  data.value = await Annotation.filter(query);
  categories.value = await Category.all();
  keywords.value = await Keyword.all();
  loading.value = false;
}

onMounted(async () => {
  await fetchData({ ...route.query });
});

function onAnnotationViewClicked(annotation) {
  router.push({
    name: 'play',
    query: {
      uuid: annotation.recording.uuid,
      signal: annotation.recording.signal,
      t: annotation.start,
    },
  });
}
</script>
