import { client, handleError } from './client'

const debug = process.env.NODE_ENV !== 'production'

/*
We excpect something like this
{
  "count": 2,
  "next": "http://localhost:3000/api/v1/thumbs/tableau/?limit=1&offset=1",
  "previous": null,
  "results": [
    {
       "url": "http://localhost:3000/api/v1/thumbs/tableau/db4bdee3-a4aa-455c-bc07-1e9335a8da42/",
       "uuid": "db4bdee3-a4aa-455c-bc07-1e9335a8da42",
       "files": [
           "/media/thumbnail_tableau/tableau-0000-20120915-000000-20120930-160040-large.png",
           "/media/thumbnail_tableau/tableau-0000-20120915-000000-20120930-160040-medium.png",
           "/media/thumbnail_tableau/tableau-0000-20120915-000000-20120930-160040-small.png"
       ],
       "start_date": {
           "year": "2012",
           "month": "09",
           "day": "15"
       },
       "end_date": {
           "year": "2012",
           "month": "09",
           "day": "30"
       }
      "data": {
          "file": "thumbnail_tableau/tableau-0000-20120915-000000-20120930-160040-large.png",
          "rows": 48,
          "paths": [
              "2012/0000001-20120915-00-00-00.00-L.jpg",
              "2012/0000002-20120915-00-00-01.00-R.jpg",
              "2012/0000003-20120915-00-30-00.00-L.jpg",
              "2012/0000004-20120915-00-30-01.00-R.jpg",
              ...,
            ],
        },
        "width": 13024,
        "height": 13104,
        "columns": 32
    }
  ]
}
*/
const Tableau = {
  async list(params) {
    try {
      const response = await client.get('thumbs/tableau/include_details/', {
        params: params
      });
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },
};

const Thumbnail = {
  async fromFilename(params) {
    try {
      const response = await client.get('thumbs/thumbs/by_filename/', {
        params: params  // params = { filename: ... }
      });
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },
};

export {
  Tableau,
  Thumbnail,
}
