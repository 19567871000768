<template>
  <div class="wrapper">
    <n-card>
      <n-tabs
        class="card-tabs"
        default-value="content"
        size="small"
        animated
        style="margin: 0 -4px"
        pane-style="padding-left: 4px; padding-right: 4px; padding-top: 14px; box-sizing: border-box;"
      >
        <n-tab-pane name="content" tab="Content">
          <n-form>
            <n-form-item label="Annotation" label-placement="top">
              <n-input v-model:value="content" type="textarea" />
            </n-form-item>
          </n-form>
          <n-form inline label-placement="top">
            <n-form-item label="Start">
              <n-input-number v-model:value="editor.start" :readonly="true" />
            </n-form-item>
            <n-form-item label="End">
              <n-input-number v-model:value="editor.end" :readonly="true" />
            </n-form-item>
            <n-form-item label="Duration">
              <n-input-number
                :value="editor.end - editor.start"
                :readonly="true"
              />
            </n-form-item>
          </n-form>
        </n-tab-pane>
        <n-tab-pane name="classification" tab="Classification">
          <n-form label-placement="left">
            <n-form-item label="Category">
              <n-select
                v-model:value="selectedCategories"
                :options="categoriesOptions"
                :consistent-menu-width="false"
                multiple
                placement="bottom-start"
              />
            </n-form-item>
          </n-form>

          <n-form label-placement="left">
            <n-form-item label="Keyword">
              <n-select
                v-model:value="selectedKeywords"
                :options="keywordsOptions"
                :consistent-menu-width="false"
                multiple
                filterable
                tag
                :loading="keywordsLoading"
                placement="bottom-start"
              />
            </n-form-item>
          </n-form>
        </n-tab-pane>
        <template #suffix>
          <n-button-group>
            <n-button type="primary" @click="onSave">Save</n-button>
            <n-popconfirm @positive-click="onDelete" :show-icon="false">
              <template #activator>
                <n-button type="error">
                  <template #icon>
                    <n-icon>
                      <delete-16-regular />
                    </n-icon>
                  </template>
                </n-button>
              </template>
              Delete?
            </n-popconfirm>
            <n-button type="secondary" @click="onCancel">Cancel</n-button>
          </n-button-group>
        </template>
      </n-tabs>
    </n-card>
  </div>
</template>
<script setup>
import { ref, reactive, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';

import {
  NCard,
  NTabs,
  NTabPane,
  NPopconfirm,
  NSpace,
  NForm,
  NFormItem,
  NInputGroup,
  NTimePicker,
  NInput,
  NInputNumber,
  NSelect,
  NDynamicTags,
  NAutoComplete,
  NButton,
  NButtonGroup,
  NTag,
  NIcon,
  NGrid,
  NGi,
} from 'naive-ui';

import { Delete16Regular } from '@vicons/fluent';

import { Annotation } from '@/api/archive';
import { Category, Keyword } from '@/api/classification';

const emit = defineEmits(['onSave', 'onCancel', 'onDelete']);

const content = ref(null);
const categories = ref([]);
const selectedCategories = ref([]);
const keywords = ref([]);
const selectedKeywords = ref([]);
const keywordsLoading = ref(false);

const store = useStore();

const editor = store.getters['annotation/editor'];

watch(() => editor, setData, { deep: true });

async function setData() {
  content.value = editor.content;
  if (editor.categories) {
    // v-model corresponds to the options 'value' property
    selectedCategories.value = editor.categories.map((c) => c.value);
  }
  if (editor.keywords) {
    // v-model corresponds to the options 'value' property
    selectedKeywords.value = editor.keywords.map((k) => k.name);
  }
}

onMounted(async () => {
  setData();
  categories.value = await Category.all();
  keywords.value = await Keyword.all();
});

const categoriesOptions = computed(() => {
  if (categories.value === null) {
    return [];
  }
  return categories.value.map((c) => ({
    label: c.name,
    value: c.value,
  }));
});

const keywordsOptions = computed(() => {
  if (keywords.value === null) {
    return [];
  }
  return keywords.value.map((k) => ({
    label: k.name,
    value: k.name, // value is not the slug as it can be a 'new' keyword that has no slug yet
  }));
});

async function onSave() {
  await store.dispatch('annotation/saveAnnotation', {
    content: content.value,
    categories: selectedCategories.value,
    keywords: selectedKeywords.value,
  });
  emit('onSave');
}

async function onDelete() {
  await store.dispatch('annotation/deleteAnnotation', {});
  emit('onDelete');
}
async function onCancel() {
  emit('onCancel');
}
</script>

<style lang="scss" scoped>
.wrapper {
  min-height: 25px;
  margin: 10px;
  text-align: left;
}
</style>
