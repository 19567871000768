<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="540pt"
       height="106.56pt"
  >
    <title>{{title}}</title>
    <g id="layer1" transform="matrix(0.72 0 0 0.72 839.986344 -131.0444352)" fill="none">
      <g id="g5970" transform="matrix(2.0922972 0 0 2.0922972 6641.3571 3129.071)" fill="none">
        <path id="path4374" transform="translate(-3426.5179, -1389.6021)" fill="none" stroke="#ffffff"
              stroke-width="1.16058433" stroke-linecap="round" stroke-linejoin="round" d="M0 0L0 7.8571"/>
        <path id="path4376" transform="translate(-3424.9108, -1389.6021)" fill="none" stroke="#ffffff"
              stroke-width="1.16058433" stroke-linecap="round" stroke-linejoin="round" d="M0 0L0 7.8571"/>
        <path id="path4378" transform="translate(-3423.0804, -1389.6021)" fill="none" stroke="#ffffff"
              stroke-width="1.16058433" stroke-linecap="round" stroke-linejoin="round" d="M0 0L0 7.8571"/>
        <path id="path4380" transform="translate(-3421.4733, -1389.6021)" fill="none" stroke="#ffffff"
              stroke-width="1.16058433" stroke-linecap="round" stroke-linejoin="round" d="M0 0L0 7.8571"/>
        <path id="path4382" transform="translate(-3419.7322, -1389.6021)" fill="none" stroke="#ffffff"
              stroke-width="1.16058433" stroke-linecap="round" stroke-linejoin="round" d="M0 0L0 7.8571"/>
        <path id="path4384" transform="translate(-3418.1251, -1389.6021)" fill="none" stroke="#ffffff"
              stroke-width="1.16058433" stroke-linecap="round" stroke-linejoin="round" d="M0 0L0 7.8571"/>
        <path id="path4386" transform="translate(-3416.2947, -1389.6021)" fill="none" stroke="#ffffff"
              stroke-width="1.16058433" stroke-linecap="round" stroke-linejoin="round" d="M0 0L0 7.8571"/>
        <path id="path4388" transform="translate(-3414.6876, -1389.6021)" fill="none" stroke="#ffffff"
              stroke-width="1.16058433" stroke-linecap="round" stroke-linejoin="round" d="M0 0L0 7.8571"/>
        <path id="path4390" transform="translate(-3412.8572, -1391.8342)" fill="none" stroke="#ffffff"
              stroke-width="1.16058433" stroke-linecap="round" stroke-linejoin="round" d="M0 0L0 7.8571"/>
        <path id="path4392" transform="translate(-3411.2501, -1391.8342)" fill="none" stroke="#ffffff"
              stroke-width="1.16058433" stroke-linecap="round" stroke-linejoin="round" d="M0 0L0 7.8571"/>
        <path id="path4394" transform="translate(-3409.4197, -1391.8342)" fill="none" stroke="#ffffff"
              stroke-width="1.16058433" stroke-linecap="round" stroke-linejoin="round" d="M0 0L0 7.8571"/>
        <path id="path4396" transform="translate(-3407.8126, -1391.8342)" fill="none" stroke="#ffffff"
              stroke-width="1.16058433" stroke-linecap="round" stroke-linejoin="round" d="M0 0L0 7.8571"/>
        <path id="path4398" transform="translate(-3406.0715, -1391.8342)" fill="none" stroke="#ffffff"
              stroke-width="1.16058433" stroke-linecap="round" stroke-linejoin="round" d="M0 0L0 7.8571"/>
        <path id="path4400" transform="translate(-3404.4644, -1391.8342)" fill="none" stroke="#ffffff"
              stroke-width="1.16058433" stroke-linecap="round" stroke-linejoin="round" d="M0 0L0 7.8571"/>
        <path id="path4402" transform="translate(-3402.634, -1391.8342)" fill="none" stroke="#ffffff"
              stroke-width="1.16058433" stroke-linecap="round" stroke-linejoin="round" d="M0 0L0 7.8571"/>
        <path id="path4404" transform="translate(-3401.0269, -1391.8342)" fill="none" stroke="#ffffff"
              stroke-width="1.16058433" stroke-linecap="round" stroke-linejoin="round" d="M0 0L0 7.8571"/>
        <path id="path4406" transform="translate(-3399.4643, -1392.7271)" fill="none" stroke="#ffffff"
              stroke-width="1.16058433" stroke-linecap="round" stroke-linejoin="round" d="M0 0L0 7.8571"/>
        <path id="path4408" transform="translate(-3397.8572, -1392.7271)" fill="none" stroke="#ffffff"
              stroke-width="1.16058433" stroke-linecap="round" stroke-linejoin="round" d="M0 0L0 7.8571"/>
        <path id="path4410" transform="translate(-3396.0268, -1392.7271)" fill="none" stroke="#ffffff"
              stroke-width="1.16058433" stroke-linecap="round" stroke-linejoin="round" d="M0 0L0 7.8571"/>
        <path id="path4412" transform="translate(-3394.4197, -1392.7271)" fill="none" stroke="#ffffff"
              stroke-width="1.16058433" stroke-linecap="round" stroke-linejoin="round" d="M0 0L0 7.8571"/>
        <path id="path4414" transform="translate(-3392.6786, -1392.7271)" fill="none" stroke="#ffffff"
              stroke-width="1.16058433" stroke-linecap="round" stroke-linejoin="round" d="M0 0L0 7.8571"/>
        <path id="path4416" transform="translate(-3391.0715, -1392.7271)" fill="none" stroke="#ffffff"
              stroke-width="1.16058433" stroke-linecap="round" stroke-linejoin="round" d="M0 0L0 7.8571"/>
        <path id="path4418" transform="translate(-3389.2411, -1392.7271)" fill="none" stroke="#ffffff"
              stroke-width="1.16058433" stroke-linecap="round" stroke-linejoin="round" d="M0 0L0 7.8571"/>
        <path id="path4420" transform="translate(-3387.634, -1392.7271)" fill="none" stroke="#ffffff"
              stroke-width="1.16058433" stroke-linecap="round" stroke-linejoin="round" d="M0 0L0 7.8571"/>
        <path id="path4422" transform="translate(-3385.8929, -1394.3342)" fill="none" stroke="#ffffff"
              stroke-width="1.16058433" stroke-linecap="round" stroke-linejoin="round" d="M0 0L0 7.8571"/>
        <path id="path4424" transform="translate(-3384.2858, -1394.3342)" fill="none" stroke="#ffffff"
              stroke-width="1.16058433" stroke-linecap="round" stroke-linejoin="round" d="M0 0L0 7.8571"/>
        <path id="path4426" transform="translate(-3382.4554, -1394.3342)" fill="none" stroke="#ffffff"
              stroke-width="1.16058433" stroke-linecap="round" stroke-linejoin="round" d="M0 0L0 7.8571"/>
        <path id="path4428" transform="translate(-3380.8483, -1394.3342)" fill="none" stroke="#ffffff"
              stroke-width="1.16058433" stroke-linecap="round" stroke-linejoin="round" d="M0 0L0 7.8571"/>
        <path id="path4430" transform="translate(-3379.1072, -1394.7133)" fill="none" stroke="#ffffff"
              stroke-width="1.1866163" stroke-linecap="round" stroke-linejoin="round" d="M0 0L0 8.2135"/>
        <path id="path4432" transform="translate(-3377.634, -1395.1565)" fill="none" stroke="#ffffff"
              stroke-width="1.23977876" stroke-linecap="round" stroke-linejoin="round" d="M0 0L0 8.9659"/>
        <path id="path4358" transform="translate(-3731.7857, -1408.5307)" :fill="color" stroke="#000000"
              stroke-opacity="0" stroke-width="0" stroke-linecap="square" stroke-linejoin="bevel"
              d="M0 35C0 35 5.9375 32.1391 8.3928 30C11.8413 26.9957 13.4433 22.2048 16.9643 19.2857C18.6247 17.9091 22.6785 16.25 22.6785 16.25C22.6785 16.25 22.2078 13.4724 22.8571 11.7857C24.1994 8.2988 28.2841 8.1951 30.7143 5.3572C31.9653 3.8964 32.8571 0 32.8571 0C46.6584 4.2732 57.1088 9.3669 47.8571 19.2857C47.8571 19.2857 57.8741 19.523 62.8571 19.1072C72.1023 18.3358 81.129 15.7755 90.3571 14.8215C106.483 13.1544 122.773 13.6705 138.929 12.3215C153.379 11.1149 167.69 8.5051 182.143 7.3215C199.367 5.9109 216.648 4.6404 233.929 4.8215C245.981 4.9478 259.463 6.6417 271.515 6.6902C283.204 6.7372 292.078 4.7307 303.75 5.3572C315.808 6.0044 328.747 9.6364 340.714 11.25C345.457 11.8895 355 12.6786 355 12.6786C355 12.6786 347.335 16.3253 343.214 17.1429C336.322 18.5105 329.099 16.7758 322.143 17.7679C316.39 18.5883 310.824 20.4421 305.268 22.1429C302.369 23.03 296.696 25.1786 296.696 25.1786C296.696 25.1786 304.565 23.3191 308.571 22.8572C312.625 22.39 316.745 22.8294 320.803 22.4107C323.266 22.1567 325.654 21.1312 328.125 20.9821C333.627 20.6502 340.004 21.7386 344.643 21.9643C345.178 21.9903 358.036 18.125 358.036 18.125C358.036 18.125 358.768 20.9411 358.303 22.2322C356.644 26.8485 352.207 30.0961 348.214 32.9464C338.512 39.8732 326.957 43.9758 315.625 47.6786C301.316 52.3543 286.34 54.9004 271.428 56.9643C243.652 60.8087 215.536 63.2253 187.5 62.6786C169.663 62.3308 151.644 61.0813 134.286 56.9643C128.348 55.5559 123.053 52.0551 117.143 50.5357C97.9183 45.594 77.2347 47.6415 58.2143 41.9643C50.9162 39.786 37.5 32.3215 37.5 32.3215C15.4493 44.969 7.4177 38.3293 0 35Z"/>
        <path id="path4360" transform="translate(-3508.75, -1385.495)" fill="none" stroke="#ffffff"
              stroke-width="1.16058433" stroke-linecap="round" stroke-linejoin="round"
              d="M0 30.3571C46.7242 28.4224 85.8005 26.7723 107.5 0"/>
        <path id="path4362" transform="translate(-3511.7857, -1385.1378)" fill="none" stroke="#ffffff"
              stroke-width="1.16058433" stroke-linecap="round" stroke-linejoin="round"
              d="M0 32.8571C56.012 29.8906 101.958 25.254 120.893 0"/>
        <path id="path4364" transform="translate(-3512.3214, -1386.745)" fill="none" stroke="#ffffff"
              stroke-width="1.16058433" stroke-linecap="round" stroke-linejoin="round"
              d="M0 38.0357C41.4562 35.6821 106.374 35.8977 131.607 0"/>
        <path id="path4366" transform="translate(-3499.1072, -1384.0664)" fill="none" stroke="#ffffff"
              stroke-width="1.16058433" stroke-linecap="round" stroke-linejoin="round"
              d="M0 24.6428C37.183 23.515 67.1192 23.0154 85.5357 0"/>
        <path id="path4368" transform="translate(-3491.9643, -1383.5307)" fill="none" stroke="#ffffff"
              stroke-width="1.16058433" stroke-linecap="round" stroke-linejoin="round"
              d="M0 19.8214C10.9631 18.7529 53.5545 22.2073 68.3929 0"/>
        <path id="path4370" transform="translate(-3483.5714, -1383.3521)" fill="none" stroke="#ffffff"
              stroke-width="1.16058433" stroke-linecap="round" stroke-linejoin="round"
              d="M0 15.8929C10.4241 15.2348 42.0136 17.0756 52.3214 0"/>
        <path id="path4372" transform="translate(-3478.63552515011, -1375.495)" :fill="color" stroke="#ffffff"
              stroke-width="1.16058433" stroke-linecap="round" stroke-linejoin="round"
              d="M15.2426 0.3572C15.2426 0.3572 2.99173 5.5166 0.599825 11.0715C-1.27477 15.425 1.78933 20.4764 2.38553 25.1786C2.86103 28.9287 0.351525 34.9122 3.81413 36.4286C8.70103 38.5687 13.7332 31.4728 16.8498 27.1429C19.8308 23.0014 19.5686 17.2949 21.3141 12.5C22.3541 9.6431 22.5476 6.0509 24.8855 4.1072C25.7295 3.4055 27.413 4.2491 28.0998 3.3929C28.8075 2.5107 28.0998 0 28.0998 0"/>
        <path id="path4438" transform="translate(-3436.8751144, -1388.8878)" sodipodi:type="arc" sodipodi:rx="3.0357144"
              sodipodi:ry="1.25" sodipodi:cx="3.0357144" sodipodi:cy="1.25" sodipodi:start="0" sodipodi:end="0"
              d="M6.07143 1.25C6.07143 0.559644 4.71229 -1.06841e-17 3.03571 -2.22045e-16C1.35914 -4.33405e-16 6.15965e-16 0.559644 0 1.25C-6.15965e-16 1.94036 1.35914 2.5 3.03571 2.5C4.71229 2.5 6.07143 1.94036 6.07143 1.25L3.03571 1.25Z"
              fill="#000000" stroke="#000000" stroke-opacity="0" stroke-width="0" stroke-linecap="square"
              stroke-linejoin="bevel"/>
      </g>
    </g>
  </svg>

</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Whale',
    },
    color: {
      type: String,
      default: '#bbffbb',
    }
  },
};
</script>