// stereometry download cart

import { Thumbnail } from "@/api/thumbs";

const state = {
  thumbnails: [],
}

const getters = {
  thumbnails: (state) => state.thumbnails,
}

const actions = {
  addThumbnail: ({ commit }, thumbnail) => {
    commit("pushThumbnail", thumbnail);
  },
  removeThumbnail: ({ commit }, thumbnail) => {
    commit("removeThumbnail", thumbnail);
  },
  removeAllThumbnails: ({ commit }) => {
    commit("removeAllThumbnails");
  },
}

const mutations = {
  pushThumbnail: (state, id) => {
    if (!state.thumbnails.includes(id)) {
      state.thumbnails.push(id);
    }
  },
  removeThumbnail: (state, id) => {
    state.thumbnails = state.thumbnails.filter((item) => item !== id);
  },
  removeAllThumbnails: (state) => {
    state.thumbnails = [];
  },

}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
