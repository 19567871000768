<template>
  <main>
    <nav class="inline">
      Zoom&nbsp;
      <select v-model="ui.zoom.selected.value" @change="onZoomSelect">
        <option
          v-for="option in ui.zoom.options"
          :key="option.value"
          :value="option.value"
        >
          {{ option.label }}
        </option>
      </select>
    </nav>
    <nav>
      <div class="times">
        <router-link
          v-for="(recording, index) in recordings"
          :to="{ query: $route.query, hash: `#${hashForDate(recording.date)}` }"
          v-on:click="scrollToId(hashForDate(recording.date))"
          :key="index"
          class="times__time"
        >
          {{ timeDisplay(recording.date, 'hh') }}
        </router-link>
      </div>
    </nav>
    <div class="recordings">
      <div
        v-for="(recording, index) in recordings"
        class="recordings__item"
        :id="hashForDate(recording.date)"
        :key="index"
      >
        <div>{{ timeDisplay(recording.date, 'long') }}</div>
        <router-link
          :to="{
            name: 'play',
            query: {
              uuid: recording.uuid,
            },
          }"
        >
          <img
            v-bind:src="recording.hydro_spectrogram_large"
            :style="ui.zoom.selected.value"
          />
        </router-link>
      </div>
    </div>
  </main>
</template>

<script setup>
import { reactive, ref, onMounted, nextTick } from 'vue';
import { DateTime } from 'luxon';
import { useRoute, useRouter } from 'vue-router';

import { Recording } from '@/api/archive.js';

const debug = process.env.NODE_ENV !== 'production';

const props = {
  location: {
    type: String,
  },
  year: {
    type: Number,
  },
  month: {
    type: Number,
  },
  day: {
    type: Number,
  },
};

const router = useRouter();
const route = useRoute();
const loading = ref(false);
const recordings = ref([]);
const query = reactive({
  location: props.location,
  year: props.year,
  month: props.month,
  day: props.day,
});

const ui = ref({
  zoom: {
    selected: {
      value: {
        width: '100vw',
        height: '60%',
      },
      label: 'Stretch window',
    },
    options: [
      {
        value: {
          width: 'inherit',
          height: 'inherit',
        },
        label: 'Actual size',
      },
      {
        value: {
          width: '100vw',
          height: 'inherit',
        },
        label: 'Fit',
      },
      {
        value: {
          width: '50vw',
          height: 'inherit',
        },
        label: 'Fit 2',
      },
      {
        value: {
          width: '25vw',
          height: '60%',
        },
        label: 'Stretch 4',
      },
      {
        value: {
          width: '100vw',
          height: '60%',
        },
        label: 'Stretch window',
      },
      {
        value: {
          width: '200vw',
          height: '60%',
        },
        label: '2 x window',
      },
    ],
  },
});

const dateToISODate = (year, month = 1, day = 1) => {
  return DateTime.fromObject({
    year: year,
    month: month,
    day: day,
  }).toISODate();
};

onMounted(async () => {
  const isoDate = dateToISODate(query.year, query.month, query.day);
  Recording.filter({
    date: isoDate,
    location: query.location,
  }).then(async (response) => {
    recordings.value = response;
    await nextTick(() => {
      if (route.hash) {
        setTimeout(() => {
          scrollToId(route.hash.replace('#', ''));
        }, 500); // hack to wait a bit until images are loaded
      }
    });
  });
});

const scrollToId = (id) => {
  const el = document.getElementById(id);
  if (el !== null) {
    el.scrollIntoView({ inline: 'start', behavior: 'auto' });
  } else {
    console.warn('el not found for id', id);
  }
};

const hashForDate = (date) => {
  const dt = DateTime.fromISO(date).toUTC();
  return dt.toLocaleString(DateTime.TIME_24_SIMPLE).replace(':', '');
};

const nextRecording = async (direction) => {
  if (debug) {
    console.warn('not implemented');
  }
};

const timeDisplay = (date, fmt) => {
  const dt = DateTime.fromISO(date).toUTC();
  let f;
  switch (fmt) {
    case 'hh':
      f = DateTime.TIME_24_SIMPLE;
      break;
    case 'long':
      f = DateTime.DATETIME_FULL;
      break;
    default:
      f = DateTime.DATETIME_SHORT;
  }
  return dt.toLocaleString(f);
};
</script>

<style lang="scss" scoped>
@import '@/style/_variables.scss';

.recordings {
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  &__item {
    display: inline-block;
    text-align: left;
    float: none;
    margin-left: 2px;
  }
}

nav {
  .times {
    display: grid;
    grid-template-columns: repeat(24, auto);
    &__time {
      padding: 0;
    }
  }
}
</style>
