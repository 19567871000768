import { client, handleError } from './client'

const debug = process.env.NODE_ENV !== 'production'

const Annotation = {
  async forAudio(uuid, params) {
    try {
      const response = await client.get(`archive/audio/${uuid}/annotation/`, {
        params: params
      });
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },
  async filter(params) {
    try {
      const response = await client.get('archive/annotation/', {
        params: params
      });
      return response.data.results;
    } catch (error) {
      handleError(error);
    }
  },
  async detail(uuid, params) {
    try {
      const response = await client.get(`archive/annotation/${uuid}/`, {
        params: params
      });
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },
  async detailFromUrl(url, params) {
    try {
      const response = await client.get(`${url}`, {
        params: params
      });
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },
  async save(data, uuid=null) {
    try {
      let response = null;
      if (uuid !== null) {
        response = await client.put(`archive/annotation/${uuid}/`, data);
      } else {
        response = await client.post(`archive/annotation/`, data);
      }
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },
  async delete(uuid, params = {}) {
    try {
        const response = await client.delete(`archive/annotation/${uuid}/`, {
          params: params
        });
        return response.data;
    } catch (error) {
      handleError(error);
    }
  },
}

const Recording = {
  // list
  async filter(params) {
    try {
      const response = await client.get('archive/recording/', {
        params: params
      });
      return response.data.results;
    } catch (error) {
      handleError(error);
    }
  },
  // list
  async annotation(params) {
    try {
      const response = await client.get('archive/recording/annotation/', {
        params: params
      });
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },
  // detail
  async detail(uuid, params) {
    try {
      const response = await client.get(`archive/recording/${uuid}/`, {
        params: params
      });
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },
  async detailFomUrl(url) {
    try {
      const response = await client.get(`${url}`);
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },
  // actions
  async dates(params) {
    try {
      const response = await client.get('archive/recording/dates/', {
        params: params
      });
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },
  async spectrogram(params) {
    try {
      const response = await client.get('archive/recording/spectrogram/', {
        params: params
      });
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },
  async signals(params) {
    try {
      const response = await client.get('archive/recording/signals/', {
        params: params
      });
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },
};

export {
  Annotation, Recording
}
