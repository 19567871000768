import { Auth } from '@/api/auth';

import { client } from '@/api/client'

const debug = process.env.NODE_ENV !== 'production';

const state = {
    token: null,
}
const getters = {
    authenticated: (state, getters) => {
        return state.token  !== null;
    },
    token: (state, getters) => {
        return state.token;
    },
}
const actions = {
    login(context, data) {
        return new Promise((resolve, reject) => {
            Auth.login(
                data,
                (token) => {
                    const access_token =  `Bearer ${token.access}`;
                    // commit to store
                    context.commit('authenticate', access_token);
                    resolve(token);
                },
                (error) => {
                    if (debug) { console.log('error', error); }
                    reject(error);
                }
            )
        })
    },
    logout(context) {
        context.commit('logout');
    },
}
const mutations = {
    authenticate(state, token) {
        state.token = token;
    },
    logout(state) {
        state.token = null;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

