<template>
  <n-space vertical justify="center" align="center">
    <h4>Filter</h4>
    <n-input-group>
      <n-tag :bordered="false" size="large">&nbsp;Location</n-tag>
      <n-radio-group
        v-model:value="query.location"
        name="location_radio_button_group"
        size="medium"
        @update:value="changeLocation"
      >
        <n-radio-button
          v-for="option in locationOptions"
          :key="option.value"
          :value="option.value"
        >
          {{ option.label }}
        </n-radio-button>
      </n-radio-group>
    </n-input-group>
    <n-input-group>
      <n-tag :bordered="false">&nbsp;Signal</n-tag>
      <n-radio-group
        v-model:value="query.signal"
        name="signal_radio_button_group"
        size="small"
        @update:value="changeSignal"
      >
        <n-radio-button
          v-for="option in signalOptions"
          :key="option.value"
          :value="option.value"
        >
          {{ option.label }}
        </n-radio-button>
      </n-radio-group>
    </n-input-group>
    <n-input-group>
      <n-tag :bordered="false">&nbsp;Year</n-tag>
      <n-radio-group
        v-model:value="query.year"
        name="year_radio_button_group"
        size="small"
        @update:value="changeYear"
      >
        <n-radio-button
          v-for="option in yearOptions"
          :key="option.value"
          :value="option.value"
        >
          {{ option.label }}
        </n-radio-button>
      </n-radio-group>
    </n-input-group>
  </n-space>
  <div v-for="y in years" :key="y" class="year">
    <div class="year__title">{{ y }}</div>
    <n-grid x-gap="12" cols="1 1000:3">
      <template v-for="m in [...Array(12).keys()].reverse()" :key="m">
        <n-gi>
          <router-link
            v-if="getMonth(y, m)"
            :to="{
              name: 'month',
              query: {
                location: query.location,
                signal: query.signal,
                year: y,
                month: m + 1,
              },
            }"
          >
            <span>{{ getMonthName(m) }}</span>
            <img :src="getMonth(y, m).spectrogram" class="spectrogram" />
          </router-link>
          <div v-else class="nodata" :style="noDataStyle"></div>
        </n-gi>
      </template>
    </n-grid>
  </div>
</template>

<script setup>
import { ref, reactive, computed, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { Info } from 'luxon';

import {
  NSpace,
  NInputGroup,
  NGrid,
  NGi,
  NTag,
  NButton,
  NRadioGroup,
  NRadioButton,
  NImage,
  NEllipsis,
  NH2,
  NH5,
  useThemeVars,
} from 'naive-ui';

import { Recording } from '@/api/archive';

const debug = process.env.NODE_ENV !== 'production';

const props = defineProps({
  location: {
    type: String,
  },
  signal: {
    type: String,
  },
  year: {
    type: String,
  },
});

const route = useRoute();
const router = useRouter();
const loading = ref(false);
const dates = ref([]);
const signals = ref([]);
const query = reactive({
  location: props.location,
  signal: props.signal,
  year: props.year,
});

const theme = useThemeVars();

// computed
const years = computed(() => {
  return dates.value
    .map((date) => date.year)
    .filter((year) => (query.year ? year == query.year : true))
    .reverse();
});

const locationOptions = computed(() => {
  const options = [
    {
      value: 'HEL',
      label: 'Heligoland',
    },
    {
      value: 'SPI',
      label: 'Spitzbergen',
    },
  ];
  return options;
});
const signalOptions = computed(() => {
  const options = signals.value.map((s) => {
    return {
      label: s.name,
      value: s.value,
    };
  });
  return options;
});

const yearOptions = computed(() => {
  const years = dates.value.map((date) => date.year).reverse();
  const options = years.map((year) => {
    return {
      label: year,
      value: year.toString(),
    };
  });
  options.unshift({
    label: 'All',
    value: null,
  });
  return options;
});

// methods
const updateRoute = () => {
  router.push({ query: query });
};

const changeYear = async (value) => {
  query.year = value;
  updateRoute();
};

const changeLocation = async (value) => {
  query.location = value;
  query.year = null;
  updateRoute();
};

const changeSignal = async (value) => {
  query.signal = value;
  query.year = null;
  updateRoute();
};

watch(
  () => props.location,
  async () => {
    if (debug) {
      console.log('location changed to:', props.location);
    }
    await fetch();
  }
);

watch(
  () => props.signal,
  async () => {
    if (debug) {
      console.log('signal changed to:', props.signal);
    }
    await fetch();
  }
);

async function fetch() {
  loading.value = true;
  signals.value = await Recording.signals();
  if (debug) {
    console.log('fetch data for:', query.location, query.signal);
  }
  dates.value = await Recording.dates({
    location: query.location,
    signal: query.signal,
  });
  loading.value = false;
}

onMounted(async () => {
  fetch();
});

const getMonthsForYear = (year) => {
  return (
    (dates.value.find((date) => date.year === year) || {})['months'] || null
  );
};

const getMonth = (year, month) => {
  const months = getMonthsForYear(year);
  if (months !== null) {
    return months.find((m) => m.month === month + 1);
  }
};
const getMonthName = (month) => {
  return Info.months()[Number(month)];
};

const noDataStyle = computed(() => {
  return {
    background: theme.value.bodyColorAlt,
  };
});
</script>

<style lang="scss" scoped>
.year {
  &__title {
    font-weight: bold;
  }
  margin-top: 30px;
  margin-bottom: 30px;

  .spectrogram {
    width: 100%;
  }
  .nodata {
    width: 100%;
    height: 100%;
  }
}
</style>
