<template>
  <div v-if="data">
    <h4>You are logged in as {{ data.first_name }} {{ data.last_name }}</h4>
    <h2>Annotations</h2>
    <AnnotationListView
      :annotations="data.annotations"
      @on-view-clicked="onAnnotationViewClicked"
      :withSpectrogram="true"
      :withEdit="false"
    />
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';

import { Member } from '@/api/member';
import AnnotationListView from '@/views/AnnotationListView.vue';

const loading = ref(false);
const data = ref(null);

const router = useRouter();

async function fetchData() {
  loading.value = true;
  data.value = await Member.detail();
  loading.value = false;
}

onMounted(async () => {
  await fetchData();
});

function onAnnotationViewClicked(annotation) {
  router.push({
    name: 'play',
    query: {
      uuid: annotation.recording.uuid,
      signal: annotation.recording.signal,
      t: annotation.start,
    },
  });
}
</script>
