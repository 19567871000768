<template>
  <Loading v-if="isLoading" />
  <n-pagination
    v-if="tableau"
    v-model:page="query.page"
    v-model:page-count="pageCount"
    @update:page="onUpdatePage"
    simple
  />
  <div v-if="tableau.results" class="container">
    <template v-for="t in tableau.results" :key="t.uuid">
      <div style="justify-self: start">
        {{ t.start_date.year }}-{{ t.start_date.month }}-{{ t.start_date.day }}
      </div>
    </template>
    <template v-for="t in tableau.results" :key="t.uuid">
      <div :style="tableauStyle(t)" ref="tableauRefs">
        <a
          v-for="(item, i) in t.data.paths"
          :key="i"
          @click="showThumbnail(item)"
        ></a>
      </div>
    </template>
    <template v-for="t in tableau.results" :key="t.uuid">
      <div style="justify-self: end">
        {{ t.end_date.year }}-{{ t.end_date.month }}-{{ t.end_date.day }}
      </div>
    </template>
  </div>

  <StereometryCart />

  <n-modal v-model:show="showModal">
    <div class="thumbnail__wrapper">
      <div class="thumbnail">
        <img v-if="thumbnail.file" :src="thumbnail.file" />
        <br />
        <div class="thumbnail__caption">
          <n-space justify="space-between">
            {{ dateDisplay }} {{ thumbnail.orientation }}
            <n-button
              circle
              tertiary
              type="primary"
              size="small"
              style="color: white"
            >
              <template #icon>
                <n-icon @click.prevent="downloadThumbnail()">
                  <arrow-download-20-regular />
                </n-icon>
              </template>
            </n-button>
          </n-space>
        </div>
      </div>
    </div>
  </n-modal>
</template>

<style lang="scss" scoped>
.container {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 10px;
}

.thumbnail__wrapper {
  background: transparent;
  text-align: center;
  .thumbnail {
    display: inline-block;
    background: black;
    color: white;
    padding: 10px;
    img {
      width: 800px;
    }
    &__caption {
      margin-top: 10px;
      text-transform: capitalize;
    }
  }
}
</style>

<script setup>
import { ref, reactive, computed, watch, onMounted, onUnmounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { useStore } from 'vuex';

import axios from 'axios';

import { NPagination, NModal, NSpace, NButton, NIcon } from 'naive-ui';

import { ArrowDownload20Regular } from '@vicons/fluent';

import { DateTime } from 'luxon';

import Loading from '@/views/Loading.vue';

import StereometryCart from '@/components/stereometry/StereometryCart.vue';

import { Tableau, Thumbnail } from '@/api/thumbs';

const store = useStore();

const tableauRefs = ref([]);

const width = ref(null);

// thumnail modal
const showModal = ref(false);

const props = defineProps({
  page: {
    type: Number,
  },
});

const query = reactive({
  page: props.page,
});

const router = useRouter();

const loading = ref(false);
const tableau = ref({});
const thumbnail = ref({});

const isLoading = computed(() => {
  return loading.value === true;
});

onMounted(async () => {
  await fetch();

  width.value = tableauRefs.value[0].clientWidth;
  window.addEventListener('resize', onResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', onResize);
});

async function fetch() {
  loading.value = true;
  const limit = 2;
  tableau.value = await Tableau.list({
    limit: limit,
    offset: (query.page - 1) * limit,
  });
  loading.value = false;
}

const pageCount = computed(() => {
  if (tableau !== null) {
    return tableau.value.count / 2;
  }
});
watch(
  () => props.page,
  async () => {
    await fetch();
  }
);

const dateDisplay = computed(() => {
  if (!thumbnail.value.date) {
    return '';
  }
  const dt = DateTime.fromISO(thumbnail.value.date);
  return dt.toFormat('LLL dd yyyy T');
});

async function fetchThumbnail(item) {
  // loading.value = true;
  thumbnail.value = await Thumbnail.fromFilename({ filename: item });
  // loading.value = false;
}
async function showThumbnail(item) {
  await fetchThumbnail(item);
  showModal.value = true;
}

function downloadThumbnail() {
  store.dispatch('stereometry/addThumbnail', thumbnail.value.file);
  showModal.value = false;
}

function onResize(event) {
  width.value = tableauRefs.value[0].clientWidth;
}

function updateRoute() {
  router.push({ query: query });
}

function onUpdatePage(value) {
  query.page = parseInt(value);
  updateRoute();
}

function tableauStyle(tableau) {
  if (!width.value) {
    return {};
  }
  const columns = 32;
  const rows = 48;
  const aspectRatio = (columns * 407) / (rows * 273); // TODO: take width / height from api data
  const height = width.value / aspectRatio;

  const w = width.value / columns;
  const h = height / rows;

  let file = tableau.files[0]; // large
  if (width.value > 800) {
    file = tableau.files[1]; // medium
  } else {
    file = tableau.files[2]; // small
  }

  return {
    display: 'grid',
    'grid-template-columns': `repeat(${columns}, ${w}px)`,
    'grid-template-rows': `repeat(${rows}, ${h}px)`,
    'background-image': `url("${file}")`,
    'background-size': `100% 100%`,
    'background-repeat': 'no-repeat',
  };
}
</script>
