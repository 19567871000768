import { client, handleError } from './client'

const debug = process.env.NODE_ENV !== 'production'


const question = {
    list: async (params) => {
        try {
            const request = await client.get('faq/question/', {
                params: params
            });
            return request.data.results;
        } catch (error) {
            handleError(error);
        }
    },
}

export default question;
