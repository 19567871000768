import { Annotation } from '@/api/archive';

const state = {
  annotations: [],
  currentAnnotation: null,
  segments: [], // LIFO
  visibleSegmentUuid: null,
  editor: null, /* {
    uuid: null,
    audio: null,
    content: null,
    categories: null,
    keywords: null,
    start: null,
    end: null,
    },
    */
}
const getters = {
  annotations: (state, getters) => {
    return state.annotations;
  },
  currentAnnotation: (state, getters) => {
    if (state.segments.length == 0) {
      return null;
    }
    const uuid = state.segments.at(-1).uuid;
    const filtered = state.annotations.filter((a) => a.uuid == uuid);
    if (filtered.length > 0) {
      return filtered[0];
    } else {
      return null;
    }
  },
  editor: (state, getters) => {
    return state.editor;
  },
}
const actions = {
  async fetchAnnotations({commit, state}, audioUuid) {
    const annotations = await Annotation.forAudio(audioUuid);
    commit('setAnnotations', { annotations: annotations });
  },
  async saveAnnotation({commit, state}, { content, categories, keywords } ) {
    const data = {
      content: content,
      categories: categories,
      keywords: keywords,
      start: state.editor.start,
      end: state.editor.end,
      audio: state.editor.audio,
    };
    let uuid = state.editor.uuid;
    if (uuid === 'NEW_ITEM') {
      uuid = null;
    }
    return await Annotation.save(data, uuid);
  },
  async deleteAnnotation({commit, state}) {
    return await Annotation.delete(state.editor.uuid);
  },
}
const mutations = {
  setAnnotations(state, { annotations }) {
    state.annotations = annotations;
  },
  resetSegments(state, value) {
    state.segments = [];
  },
  addSegment(state, value) {
    state.segments.push(value);
  },
  removeSegment(state, value) {
    state.segments = state.segments.filter(
      (s) => s.uuid !== value.uuid
    );
  },
  // editor
  onSegmentDragEnd(state, ev) {
    state.editor.start = ev.segment.startTime;
    state.editor.end = ev.segment.endTime;
  },
  resetEditor(state) {
    state.editor = null;
  },
  setEditor(state, { uuid=null, audio=null, content='', categories=null, keywords=null, start=null, end=null }) {
    if (state.editor === null) {
      state.editor = {};
    }
    state.editor.uuid = uuid;
    state.editor.audio = audio;
    state.editor.content = content;
    state.editor.categories = categories;
    state.editor.keywords = keywords;
    state.editor.start = start;
    state.editor.end = end;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
