const PAUSED = 0;
const PLAYING = 1;

const ZOOM_LEVEL_MIN_SECONDS = 10;
const ZOOM_LEVEL_MAX_SECONDS = 60 * 60;
const ZOOM_LEVEL_INITIAL_SECONDS = 10 * 60;

const state = {
  audioState: PAUSED,
  zoomLevel: ZOOM_LEVEL_INITIAL_SECONDS,
  offsetSeconds: 0,
  seek: null,
}
const getters = {
  isPlaying: (state, getters) => {
    return state.audioState === PLAYING
  },
  zoomLevel: (state, getters) => {
    return state.zoomLevel;
  },
  offsetSeconds: (state, getters) => {
    return state.offsetSeconds;
  },
  doSeek: (state, getters) => {
    return state.seek;
  },
}
const actions = {
  playPause(context) {
    if (context.state.audioState === PLAYING) {
      context.commit('setAudioState', PAUSED);
    } else if (context.state.audioState === PAUSED) {
      context.commit('setAudioState', PLAYING);
    }
  },
  play(context) {
    context.commit('setAudioState', PLAYING);
  },
  pause(context) {
    context.commit('setAudioState', PAUSED);
  },
}
const mutations = {
  setAudioState(state, audioState) {
    state.audioState = audioState;
  },
  zoom(state, direction) {
    state.zoomLevel -= direction;
    state.zoomLevel = Math.min(Math.max(state.zoomLevel, ZOOM_LEVEL_MIN_SECONDS), ZOOM_LEVEL_MAX_SECONDS);
  },
  setOffsetSeconds(state, seconds) {
    state.offsetSeconds = seconds;
  },
  doSeek(state, seconds) {
    state.seek = seconds;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
