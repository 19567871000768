import { client, handleError } from './client'

const debug = process.env.NODE_ENV !== 'production'


const Member = {
    // detail
    async detail(uuid, params) {
        try {
            const request = await client.get(`member/member/`, {
                params: params
            });
            return request.data.results[0];
        } catch (error) {
            handleError(error);
        }
    },
};

export {
    Member
}
