import { createRouter, createWebHistory } from "vue-router";

import store from "@/store/index";

import Error404NotFound from "@/views/Error404NotFound.vue";
import Home from "@/views/Home.vue";
import YearView from "@/views/YearView.vue";
import MonthView from "@/views/MonthView.vue";
import DayView from "@/views/DayView.vue";
import Player from "@/components/Player.vue";
import HourView from "@/views/HourView.vue";
import FaqView from "@/views/FaqView.vue";
import AboutView from "@/views/AboutView.vue";
import ProfileView from "@/views/ProfileView.vue";
import LoginView from "@/views/LoginView.vue";
import AnnotationView from "@/views/AnnotationView.vue";
import StereometryView from "@/views/StereometryView.vue";

import { Auth } from "@/api/auth";

const debug = process.env.NODE_ENV !== 'production'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      component: YearView,
      props: route => (
        {
          location: route.query.location || 'SPI',
          signal: route.query.signal || 'hydro',
        }
      )
    },
    {
      path: '/login',
      name: 'login',
      component: LoginView,
    },
    {
      path: '/profile',
      name: 'profile',
      component: ProfileView,
      meta: { requiresAuth: true }
    },
    {
      path: '/y',
      name: 'year',
      component: YearView,
      props: route => (
        {
          location: route.query.location || 'SPI',
          signal: route.query.signal || 'hydro',
          year: route.query.year || null,
        }
      )
    },
    {
      path: '/annotation',
      name: 'annotation',
      component: AnnotationView,
      props: route => (
        {
          query: route.query,
        }
      )
    },
    {
      path: '/m',
      name: 'month',
      component: MonthView,
      props: route => (
        {
          year: Number(route.query.year),
          month: Number(route.query.month),
          location: route.query.location || 'SPI',
          signal: route.query.signal || 'hydro',
        }
      )
    },
    {
      path: '/d',
      name: 'day',
      component: DayView,
      props: route => (
        {
          year: Number(route.query.year),
          month: Number(route.query.month),
          day: Number(route.query.day),
          location: route.query.location || 'SPI',
          signal: route.query.signal || 'hydro',
        }
      )
    },
    {
      path: '/p',
      name: 'play',
      component: Player,
      props: route => (
        {
          uuid: route.query.uuid,
          signal: route.query.signal,
          t: Number(route.query.t) || 0,
        }
      ),
    },
    {
      path: '/i/:year/:month/:hour',
      name: 'hour',
      component: HourView,
      props: true,
    },
    {
      path: '/stereometry',
      name: 'stereometry',
      component: StereometryView,
      props: route => (
        {
          page: Number(route.query.page) || 1,
        }
      ),
    },
    {
      path: '/faq',
      name: 'faq',
      component: FaqView,
      props: false,
    },
    {
      path: '/about',
      name: 'about',
      component: AboutView,
      props: false,
    },
    {
      path: '/:pathMatch(.*)?',
      name: 'NotFound',
      component: Error404NotFound
    }
  ],

  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
  }
});

router.beforeEach((to, from) => {
  const loggedIn = store.getters['auth/authenticated'];
  if (to.meta.requiresAuth && !loggedIn) {
    console.log('login, then redirec to', to.fullPath);
    return {
      name: 'login',
      query: { redirect: to.path, ...to.query },
    }
  }
});

export { router }
