<template>
  <a :href="href" :style="style">
    <slot></slot>
  </a>
</template>

<script setup>
import { reactive } from 'vue';
import { useThemeVars } from 'naive-ui';

const props = defineProps({
  href: {
    type: String,
  },
  color: {
    type: String,
  },
});

const theme = useThemeVars();

const style = reactive({
  color: props.color || theme.value.textColorBase,
  textDecoration: 'underline',
});
</script>
