import axios from 'axios';
import store from '@/store';

const debug = process.env.NODE_ENV !== 'production'

const client = axios.create({
    baseURL: document.settings.api_url,
    withCredentials: true,
    xsrfHeaderName: 'X-CSRFToken',
    xsrfCookieName: 'csrftoken',
    timeout: 30000,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
    },
})

client.interceptors.request.use(
    config => {
        config.headers['Authorization'] = store.getters['auth/token'];
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);


// throttle feature
const throttle = false;
const delay_ms = 2000;
const wait = (ms) => {
    return new Promise(resolve => {
        setTimeout(resolve, ms)
    })
}
if (throttle == true) {
    client.interceptors.response.use(async function (response) {
        await wait(delay_ms)
        return response;
    }, function (error) {
        console.error(error)
        return Promise.reject(error);
    });
}    

// error handling
function handleError(error) {
    if (debug) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
        } else if (error.request) {
            // `error.request` is an instance of XMLHttpRequest 
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
        }
        console.log(error.config);
    }
    throw(error);
}

export { client, handleError}
