<template>
  <div class="wrapper">
    <div v-if="annotation">
      <n-space justify="center" style="padding: 10px 0">
        <n-ellipsis expand-trigger="click" line-clamp="2" :tooltip="false">
          {{ annotation.author }}: {{ annotation.content }}
        </n-ellipsis>
        <n-tag
          v-for="keyword in annotation.keywords"
          :bordered="false"
          type="info"
          size="small"
        >
          <router-link
            :to="{
              name: 'annotation',
              query: { keyword: keyword.slug },
            }"
          >
            #{{ keyword.name }}
          </router-link>
        </n-tag>

        <n-tag
          v-for="category in annotation.categories"
          :bordered="false"
          type="success"
          size="small"
        >
          <router-link
            :to="{
              name: 'annotation',
              query: { category: category.value },
            }"
          >
            {{ category.name }}
          </router-link>
        </n-tag>
      </n-space>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { NEllipsis, NTag, NSpace } from 'naive-ui';
const debug = process.env.NODE_ENV !== 'production';

const store = useStore();

const annotation = computed(() => {
  return store.getters['annotation/currentAnnotation'];
});
</script>

<style lang="scss" scoped>
.wrapper {
  min-height: 50px;
  margin: 10px;
}
</style>
