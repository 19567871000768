<template>
  <n-modal v-model:show="showModal" :mask-closable="false">
    <n-card
      style="width: 600px"
      title="Hello there!"
      :bordered="false"
      size="huge"
      role="dialog"
      aria-modal="true"
    >
      <n-space vertical>
        <n-alert v-if="error.message" title="Login failed" type="error">
          {{ error.message }}
        </n-alert>

        <n-form>
          <n-form-item label="E-Mail" :feedback="error.emailMessage">
            <n-input
              v-model:value="email"
              type="text"
              :status="error.emailStatus"
              placeholder="Email address"
            />
          </n-form-item>
          <n-form-item label="Password" :feedback="error.passwordMessage">
            <n-input
              type="password"
              v-model:value="password"
              show-password-on="mousedown"
              :status="error.passwordStatus"
              placeholder="Password"
            >
              <template #password-visible-icon>
                <n-icon :size="16" :component="Eye16Regular" />
              </template>
              <template #password-invisible-icon>
                <n-icon :size="16" :component="EyeOff16Regular" />
              </template>
            </n-input>
          </n-form-item>
          <n-space justify="center">
            <n-button size="large" @click="handleLogin"
              >&nbsp;&nbsp;&nbsp;Login&nbsp;&nbsp;&nbsp;</n-button
            >
          </n-space>
        </n-form>
      </n-space>

      <template #footer>Forgot password? Please write us an E-Mail.</template>
    </n-card>
  </n-modal>
</template>

<script setup>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import {
  NAlert,
  NForm,
  NFormItem,
  NModal,
  NCard,
  NSpace,
  NButton,
  NInput,
  NIcon,
} from 'naive-ui';
import { Eye16Regular, EyeOff16Regular } from '@vicons/fluent';

const debug = process.env.NODE_ENV !== 'production';

const store = useStore();
const router = useRouter();
const route = useRoute();

const email = ref(null);
const password = ref(null);

const showModal = ref(true);

const error = ref({
  emailStatus: null,
  emailMessage: null,
  passwordStatus: null,
  passwordMessage: null,
  message: null,
});

async function handleLogin() {
  store
    .dispatch('auth/login', {
      email: email.value,
      password: password.value,
    })
    .then((res) => {
      showModal.value = false;
      if (route.query.redirect !== undefined) {
        if (debug) {
          console.log('redirect to', route.query.redirect);
        }
        const path = route.query.redirect;
        delete route.query['redirect'];
        router.push({
          path: path,
          query: route.query,
        });
      } else {
        router.push({ name: 'profile' });
      }
    })
    .catch((e) => {
      console.log('error', e);
      const data = e.response.data;
      console.log('data', data);
      console.log('error', error.value);
      if (data.email !== undefined) {
        error.value.emailStatus = 'error';
        error.value.emailMessage = data.email[0];
      }
      if (data.password !== undefined) {
        error.value.passwordStatus = 'error';
        error.value.passwordMessage = data.password[0];
      }

      if (data.detail !== undefined) {
        error.value.message = data.detail;
        return;
      }
    });
}
</script>
