<template>
  <div v-if="recording">
    <router-link
      :to="{
        name: 'play',
        query: {
          uuid: recording.uuid,
          signal: signal,
        },
      }"
    >
      <div class="item" :style="annotatedStyle"></div>
    </router-link>
  </div>
  <div v-else class="item"></div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  item: Object,
  hasAnnotation: Boolean,
  signal: String,
});

const recording = computed(() => {
  return props.item.recording;
});

const annotatedStyle = computed(() => {
  if (props.hasAnnotation === true) {
    return {
      border: '1px solid white',
    };
  } else {
    return;
  }
});
</script>

<style lang="scss" scoped>
.item {
  max-width: 100%;
  height: 100%;
}
a:hover {
  > div {
    border: 1px solid white;
  }
}
</style>
